import { Button, Header } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Link } from 'react-router-dom';

export interface JobsMobileHeaderProps {
  archive: boolean;
}

const JobsMobileHeader: React.FunctionComponent<JobsMobileHeaderProps> =
  function ({ archive }) {
    return archive ? (
      <Header.Base>
        <Link to={URLS.home}>
          <Button.Icon
            id="jobs-mobile-back"
            icon="chevron-left"
            className="-ml-4"
          />
        </Link>
      </Header.Base>
    ) : (
      <Header.Main linkComponent={Link} />
    );
  };

export default JobsMobileHeader;
