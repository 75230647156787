import { VAT_NUMBER_REGEX } from './constants';

export default function useVatNumberValidation() {
  return {
    validate: {
      value: (vatNumber?: string | number | boolean) => {
        if (typeof vatNumber !== 'string') return false;
        return !vatNumber || !!vatNumber.match(VAT_NUMBER_REGEX);
      },
      message: 'Please enter a valid VAT number',
    },
  };
}
