import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export interface SetupInfoHook {
  onClick: () => void;
}

export default function useSetupInfo(): SetupInfoHook {
  const { company, isLoading } = useCurrentCompany();

  const { push } = useHistory();

  const onClick = useCallback(() => push(URLS.setupSubscription), [push]);

  useEffect(() => {
    if (isLoading || !company) return;
    const businessConfig = company?.business_config;
    if (businessConfig) return;
    push(URLS.setupBusinessConfig);
  }, [isLoading, company, push]);

  return {
    onClick,
  };
}
