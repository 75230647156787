import { AddBankDetails, Description, Icon, Status } from './components';

const JobFinanceApplicationProgressCard: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col">
      <Icon />
      <Description />
      <Status />
      <AddBankDetails />
    </div>
  );
};

export default JobFinanceApplicationProgressCard;
