import { Text } from '@kanda-libs/ks-design-library';
import useJobFinanceApplicationProgressCardStatus from './useJobFinanceApplicationProgressCardStatus';

const JobFinanceApplicationProgressCardStatus: React.FunctionComponent =
  function ({}) {
    const { status, subStatus, isLoading } =
      useJobFinanceApplicationProgressCardStatus();
    if (!status) return null;
    return (
      status && (
        <div className="flex flex-col p-3 rounded-xl bg-blue-100 mt-4">
          <Text
            text={status}
            isLoading={isLoading}
            className="text-12-18-em text-blue-200 mb-1"
          />
          <Text
            text={subStatus}
            isLoading={isLoading}
            className="text-12-18 text-blue-200"
          />
        </div>
      )
    );
  };

export default JobFinanceApplicationProgressCardStatus;
