import { Form } from '@kanda-libs/ks-component-ts';
import { BreakPoints, Loader } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import { Layout } from '../components';
import SetupAccountBusinessForm from './BusinessForm';
import { HEADER } from './constants';
import SetupAccountSubmitButton from './SubmitButton';
import useSetupAccountForm from './useSetupAccountForm';

const SetupAccount: React.FunctionComponent = function () {
  const { form, onSubmit, isSubmitting, schemaError } = useSetupAccountForm();
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      id="setup-account"
      className="flex flex-col"
    >
      <Layout
        mobileFooter={<SetupAccountSubmitButton isSubmitting={isSubmitting} />}
      >
        <Helmet title="Setup Business" />
        <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">{HEADER}</p>
        <SetupAccountBusinessForm schemaError={schemaError} hideBox>
          <BreakPoints
            desktop={<SetupAccountSubmitButton isSubmitting={isSubmitting} />}
          />
        </SetupAccountBusinessForm>
      </Layout>
      <Loader isLoading={isSubmitting} />
    </Form>
  );
};

export default SetupAccount;
