import { type StringIndexedObject } from '@kanda-libs/ks-design-library';

export type AddressManualFieldNames =
  | 'buildingName'
  | 'buildingNumber'
  | 'line1'
  | 'line2'
  | 'city'
  | 'county'
  | 'country'
  | 'monthsAtAddress';

export const ADDRESS_FIELD_MANUAL_PROPS: Record<
  AddressManualFieldNames,
  StringIndexedObject
> = {
  buildingName: {
    placeholder: 'Building name',
  },
  buildingNumber: {
    placeholder: 'Building number',
  },
  line1: {
    placeholder: 'Address line 1 or street name',
  },
  line2: {
    placeholder: 'Address line 2',
  },
  city: {
    placeholder: 'City',
  },
  county: {
    placeholder: 'County',
  },
  country: {
    placeholder: 'Country',
  },
  monthsAtAddress: {},
};
