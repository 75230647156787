import { BreakPoints } from '@kanda-libs/ks-design-library';
import SetupStepIndicator from '../StepIndicator';
import SetupLayoutDesktop from './Desktop';
import SetupLayoutMobile from './Mobile';

interface SetupLayoutProps {
  children: React.ReactNode;
  mobileFooter?: React.ReactNode;
  showIndicator?: boolean;
}

const Wrapper: React.FunctionComponent<SetupLayoutProps> = function ({
  children,
  showIndicator = true,
}) {
  return (
    <div className="flex flex-col flex-1 w-full max-w-216 mx-auto">
      {showIndicator && <SetupStepIndicator />}
      {children}
    </div>
  );
};

const SetupLayout: React.FunctionComponent<SetupLayoutProps> = function ({
  children,
  mobileFooter,
  showIndicator = true,
}) {
  return (
    <BreakPoints
      mobile={
        <SetupLayoutMobile footer={mobileFooter}>
          <Wrapper showIndicator={showIndicator}>{children}</Wrapper>
        </SetupLayoutMobile>
      }
      desktop={
        <SetupLayoutDesktop>
          <Wrapper showIndicator={showIndicator}>{children}</Wrapper>
        </SetupLayoutDesktop>
      }
    />
  );
};

export default SetupLayout;
