import { Text } from '@kanda-libs/ks-design-library';
import InfoCard from 'components/InfoCard';
import JobQuoteTotals from '../JobQuoteTotals';
import JobQuoteDetailsLineItems from './JobQuoteLineItems';
import useJobQuoteDetails from './useJobQuoteDetails';

const JobQuoteDetails: React.FunctionComponent = function ({}) {
  const { title, description, workType, notes, isLoading } =
    useJobQuoteDetails();
  return (
    <InfoCard title="Quote Details" isLoading={isLoading}>
      <div className="flex flex-col md:px-4 lg:px-16 md:py-8 gap-y-4">
        <div className="flex flex-col">
          <Text
            text="Title"
            isLoading={isLoading}
            className="text-14-22-em text-neutral-700 mb-1"
          />
          <Text
            text={title}
            isLoading={isLoading}
            className="text-14-22 text-neutral-900"
          />
        </div>
        {description && (
          <div className="flex flex-col">
            <Text
              text="Description"
              isLoading={isLoading}
              className="text-14-22-em text-neutral-700 mb-1"
            />
            <Text
              text={description}
              isLoading={isLoading}
              className="text-14-22 text-neutral-900"
            />
          </div>
        )}
        <div className="flex flex-col">
          <Text
            text="Line Items"
            isLoading={isLoading}
            className="text-14-22-em text-neutral-700 mb-1"
          />
          <JobQuoteDetailsLineItems />
        </div>
        <div className="md:ml-auto">
          <JobQuoteTotals />
        </div>
        {workType && (
          <div className="flex flex-col">
            <Text
              text="Work Type"
              isLoading={isLoading}
              className="text-14-22-em text-neutral-700 mb-2"
            />
            <Text
              text={workType}
              isLoading={isLoading}
              className="text-14-22 text-neutral-900"
            />
          </div>
        )}
        {notes && (
          <div className="flex flex-col">
            <Text
              text="Notes"
              isLoading={isLoading}
              className="text-14-22-em text-neutral-700 mb-2"
            />
            <div className="flex flex-col border border-neutral-200 rounded-lg p-3 mt-1">
              {notes.map((note, index) => (
                <>
                  {index !== 0 && (
                    <div className="my-3 border-b border-neutral-200" />
                  )}
                  <Text
                    text={note}
                    isLoading={isLoading}
                    className="text-14-22 text-neutral-900"
                  />
                </>
              ))}
            </div>
          </div>
        )}
      </div>
    </InfoCard>
  );
};

export default JobQuoteDetails;
