import useQuery from 'hooks/useQuery';
import useSearchWords from 'hooks/useSearchWords';
import { useMemo } from 'react';
import type { QueryType } from 'store/slices/query';

export interface JobTitleLineClampHook {
  textClassName: string;
  searchWords: string[];
}

export default function useJobTitleLineClamp(
  className: string,
  lines: 2 | 3,
  queryType: QueryType,
): JobTitleLineClampHook {
  const textClassName = useMemo(
    () =>
      [
        'w-full max-w-full',
        className,
        lines === 2 ? 'line-clamp-2' : 'line-clamp-3',
      ].join(' '),
    [className, lines],
  );
  const { fetchedQuery: query } = useQuery(queryType);
  const { searchWords } = useSearchWords(query);

  return {
    textClassName,
    searchWords,
  };
}
