export const PHONE_REGEX = /^(0|\+44)?[1,2,3,7,8][0-9]{9}$/;

export const PHONE_NUMBER_VALIDATION = {
  required: {
    value: true,
    message: 'Phone number is required',
  },
  pattern: {
    value: PHONE_REGEX,
    message: 'Phone number must be a valid UK phone number',
  },
};

export const PHONE_NUMBER_PROPS = {
  autoCapitalize: 'off',
  autoCorrect: 'off',
  autoComplete: 'tel',
  label: 'Phone',
  placeholder: 'Phone number',
};
