import JobActivity from 'pages/Jobs/components/JobActivity';
import JobCombinedStatus from 'pages/Jobs/components/JobCombinedStatus';
import JobNameType from 'pages/Jobs/components/JobNameType';
import JobPrice from 'pages/Jobs/components/JobPrice';
import JobTitleLineClamp from 'pages/Jobs/components/JobTitleLineClamp';
import type { FormattedJob } from 'pages/Jobs/helpers';
import useJobCard from './useJobCard';

export interface JobCardProps {
  job: FormattedJob;
  isLoading?: boolean;
  archive?: boolean;
}

const JobCard: React.FunctionComponent<JobCardProps> = function ({
  job,
  isLoading = false,
  archive = false,
}) {
  const { Wrapper, wrapperProps, classNames } = useJobCard(job, isLoading);
  return (
    <Wrapper {...wrapperProps} className={classNames.wrapper}>
      <div className={classNames.card}>
        <div className="flex flex-row justify-between gap-x-2 w-full">
          <JobNameType
            firstName={job.firstName}
            lastName={job.lastName}
            type={job.type}
            isLoading={isLoading}
          />
          <JobActivity
            updatedAt={job.updatedAt}
            status={job.status}
            financeStatus={job.financeStatus}
            textClassName="text-12-18"
            isLoading={isLoading}
          />
        </div>
        <div>
          <JobTitleLineClamp
            text={job.title}
            lines={3}
            className="text-14-22 text-neutral-700"
            isLoading={isLoading}
          />
        </div>
        <div className="flex flex-row justify-between gap-x-2 w-full">
          <JobPrice price={job.price} isLoading={isLoading} />
          <JobCombinedStatus
            financeStatus={job.financeStatus}
            status={job.status}
            archived={archive}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default JobCard;
