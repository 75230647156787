import { Widget } from '@kanda-libs/ks-component-ts';
import { type StringIndexedObject } from '@kanda-libs/ks-design-library';
import {
  services,
  useLoadData,
  type Service,
} from '@kanda-libs/ks-frontend-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
// import { getFieldWidgetTag } from '../../../helpers';
import { useAddressFieldsContext } from '../../context';
import { getFieldWidgetTag } from '../../helpers';

export interface AddressFieldsPostcodeWidgetHook {
  setPostcodeName: (id: string) => void;
  widgetName: keyof typeof Widget;
  onPostcodeSearch: (newPostcode: string) => void;
  extraProps: StringIndexedObject;
  showLabels?: boolean;
  label?: string;
}

export default function usePostcodeWidget(): AddressFieldsPostcodeWidgetHook {
  const {
    widgetPrefix,
    setPostcodeName,
    postcodeCallback,
    setPostcodeIsValidating,
    showLabels,
    labels,
  } = useAddressFieldsContext();

  const [postCode, setPostCode] = useState<string>('');

  const onPostcodeSearch = useCallback(
    (newPostcode: string) => setPostCode(newPostcode),
    [],
  );

  const { data, error, isValidating, mutate } = useLoadData(
    (postCode && services.address.find) as unknown as Service<
      StringIndexedObject,
      StringIndexedObject,
      StringIndexedObject
    >,
    {
      shouldRetryOnError: false,
      useParamsAsKey: true,
    },
    {
      params: { postCode },
    },
  );

  const widgetName = useMemo(
    () => getFieldWidgetTag('postcode', widgetPrefix),
    [widgetPrefix],
  );

  const extraProps = useMemo(
    () => ({
      data,
      error,
      isValidating,
    }),
    [data, error, isValidating],
  );

  useEffect(() => {
    if (data && postcodeCallback) postcodeCallback(data);
  }, [data, postcodeCallback]);

  useEffect(() => {
    if (postCode) mutate();
  }, [postCode, mutate]);

  useEffect(
    () => setPostcodeIsValidating(isValidating),
    [isValidating, setPostcodeIsValidating],
  );

  return {
    setPostcodeName,
    widgetName,
    onPostcodeSearch,
    extraProps,
    showLabels,
    label: labels?.postcode,
  };
}
