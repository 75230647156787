import { BreakPoints } from '@kanda-libs/ks-design-library';

import type { JobItem } from '@kanda-libs/ks-frontend-services';
import { Provider } from './Context';
import Desktop from './Desktop';
import Mobile from './Mobile';

interface ViewComponentProps {
  /**
   * Name of field
   */
  item?: JobItem;
  /**
   * Is loading
   */
  isLoading?: boolean;
  /**
   * Variant
   */
  variant?: 'job-details' | 'customer-job-view' | 'job-summary';
  /**
   * Variant
   */
  forceMobile?: boolean;
}

const ViewComponent = ({ forceMobile, ...rest }: ViewComponentProps) => (
  <Provider {...rest}>
    <BreakPoints
      mobile={<Mobile />}
      desktop={forceMobile ? <Mobile /> : <Desktop />}
    />
  </Provider>
);

ViewComponent.displayName = 'LineItem-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  item: undefined,
  variant: 'job-details',
};

export default ViewComponent;
