import type { FinanceStatus, Job } from '@kanda-libs/ks-frontend-services';

export type JobCurrentStatus =
  | 'sent'
  | 'applied'
  | 'approved'
  | 'marked_completed'
  | 'finished'
  | 'declined';

export type ProgressBarToShow =
  | 'standard'
  | 'declined_no_finance'
  | 'declined_finance'
  | 'cancelled_finance';

export const getProgressBarToShow = (
  status: Job['status'],
  financeStatus?: FinanceStatus,
): ProgressBarToShow | undefined => {
  if (!status || status === 'draft') return undefined;
  if (status === 'declined') return 'declined_no_finance';
  if (!financeStatus) return undefined;
  if (financeStatus === 'finance_not_approved') return 'declined_finance';
  if (financeStatus === 'finance_cancelled') return 'cancelled_finance';
  return 'standard';
};
