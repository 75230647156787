import {
  FinanceStatusTagV2,
  SkeletonLoader,
  type FinanceStatusTagV2Status,
  type SkeletonLoaderProps,
  type TagProps,
} from '@kanda-libs/ks-design-library';
import { BASE_SKELETON_PROPS } from './constants';

export interface JobFinanceStatusProps {
  financeStatus: FinanceStatusTagV2Status;
  archived?: boolean;
  size?: TagProps['size'];
  isLoading?: boolean;
  timestamp?: Date;
  skeletonProps?: SkeletonLoaderProps;
}

const JobFinanceStatus: React.FunctionComponent<JobFinanceStatusProps> =
  function ({
    financeStatus,
    archived = false,
    isLoading = false,
    size = 21,
    timestamp,
    skeletonProps,
  }) {
    if (archived) return null;
    return (
      <SkeletonLoader
        {...(skeletonProps || BASE_SKELETON_PROPS)}
        isLoading={isLoading}
        afterLoading={
          <FinanceStatusTagV2
            financeStatus={financeStatus}
            timestamp={timestamp ? timestamp.getTime() : undefined}
            size={size}
            variant="solid"
          />
        }
      />
    );
  };

export default JobFinanceStatus;
