// - not_submitted
// - accepted_sign_document
// - accepted_deposit_required
// - accepted
// - declined
// - referred
// - action_lender
// - action_customer
// - pending
// - finished
// - paid_out
// - cancelled

import type { Job, JobCreditState } from '@kanda-libs/ks-frontend-services';

export const getStatus = (
  job: Job | undefined,
  status: JobCreditState['current_status'] | undefined,
  xstatus: string | undefined,
): string | undefined => {
  if (
    job?.finance_status === 'sat_note_signed' ||
    job?.finance_status === 'sat_note_lender_review'
  ) {
    if (xstatus?.includes('AG_LIVE')) return 'Status: Kanda will pay you soon';
    return 'Status: Satisfaction note in review';
  }
  if (
    job?.finance_status !== 'applied_for_finance' &&
    job?.finance_status !== 'financed'
  )
    return undefined;
  if (status === 'referred')
    return 'Status: Lender is reviewing the application';
  if (status === 'action_lender')
    return 'Status: Lender is reviewing the application';
  if (status === 'action_customer')
    return 'Status: Customer needs to send documents';
  if (status === 'accepted_sign_document')
    return 'Status: Customer needs to sign documents';
  const payment = [...(job?.payments || [])]
    ?.sort(
      (p1, p2) =>
        new Date(p2?.metadata?.created_at || 0).getTime() -
        new Date(p1?.metadata?.created_at || 0).getTime(),
    )
    ?.filter((p) => p?.payment_option?.payment_method === 'card')?.[0];
  if (!payment) return undefined;
  if (status === 'accepted_deposit_required')
    return 'Status: Customer needs to pay deposit';
  return undefined;
};

export const getPayoutDate = (date?: Date): string => {
  if (!date) return '';
  const now = new Date(date);
  const day = now.getDay();
  if (day <= 2) {
    const payout = new Date(now.setDate(now.getDate() + 3));
    return payout.toDateString();
  }
  const payout = new Date(now.setDate(now.getDate() + 5));
  return payout.toDateString();
};
