import { Button, Text } from '@kanda-libs/ks-design-library';
import CompanyDetailsFormLimitedCard from './Card';
import CompanyDetailsFormLimitedFields from './Fields';
import useCompanyDetailsFormLimited from './useCompanyDetailsFormLimited';

interface CompanyDetailsFormLimitedProps {
  isLoading: boolean;
  schemaError: boolean;
}

const CompanyDetailsFormLimited: React.FunctionComponent<CompanyDetailsFormLimitedProps> =
  function ({ isLoading, schemaError }) {
    const { showCard, ...rest } = useCompanyDetailsFormLimited(isLoading);
    return (
      <>
        <Text
          text="Limited Company Details"
          className="text-14-22-em text-neutral-900"
          isLoading={isLoading}
        />
        {showCard ? (
          <CompanyDetailsFormLimitedCard {...rest} isLoading={isLoading} />
        ) : (
          <>
            <CompanyDetailsFormLimitedFields schemaError={schemaError} />
            <Button.Text
              submit
              id="my-account-company-information-company-details-submit"
              label="Update Company Details"
              size={40}
              className="ml-auto"
            />
          </>
        )}
      </>
    );
  };

export default CompanyDetailsFormLimited;
