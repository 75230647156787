import { Table } from '@kanda-libs/ks-component-ts';
import useJobsTable from './useJobsTable';

const JobsTable: React.FunctionComponent = function ({}) {
  const { tableProps } = useJobsTable();
  return (
    <div className="-mx-8">
      <Table {...tableProps} />
    </div>
  );
};

export default JobsTable;
