import { type TagProps } from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  highlighter: 'text-green-300 bg-transparent',
  container:
    'flex flex-col gap-y-1 text-16-20 text-neutral-900 text-left w-full max-w-full',
  text: 'text-16-20-em',
  tag: 'md:mr-2',
};

export const HIGHLIGHT_PROPS = {
  highlightClassName: CLASS_NAMES.highlighter,
  autoEscape: true,
};

export const EXAMPLE_TAG_PROPS: TagProps = {
  label: 'Example',
  size: 21,
  color: 'lavender',
  variant: 'solid',
  className: CLASS_NAMES.tag,
};

export const DRAFT_TAG_PROPS: TagProps = {
  label: 'Draft',
  size: 21,
  color: 'grey',
  variant: 'solid',
  className: CLASS_NAMES.tag,
};

export const LEAD_TAG_PROPS: TagProps = {
  label: 'Lead',
  size: 21,
  color: 'green',
  variant: 'solid',
  className: CLASS_NAMES.tag,
};
