import type { Company } from '@kanda-libs/ks-frontend-services';
import type { TradingNameFormValues } from './useTradingNameForm';

export const getTradingNameFormBody = (
  company: Company,
  formValues: TradingNameFormValues,
): Company | undefined => ({
  ...company,
  contact_info: {
    ...company?.contact_info,
    trading_name: formValues.trading_name,
  },
});
