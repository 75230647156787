import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import type { BusinessConfigFormValues } from '../useBusinessConfigForm';

export interface SetupBusinessConfigSubmitButtonHook {
  show: boolean;
  disabled: boolean;
}

export default function useSetupBusinessConfigSubmitButton(): SetupBusinessConfigSubmitButtonHook {
  const [tradeText] = useWatch<
    BusinessConfigFormValues,
    ['company.business_config.trade_text']
  >({
    name: ['company.business_config.trade_text'],
  });

  const show = useMemo(
    () => Boolean(tradeText && tradeText !== ''),
    [tradeText],
  );

  return { show, disabled: false };
}
