import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useFinanceOptionsContext } from 'components/FinanceOptions/context';
import { useMemo } from 'react';
import { filterRatesByTotalAndDeposit } from 'utils';

export interface RateHook {
  available: boolean;
  pending: boolean;
  hideUnavailable: boolean;
  showDivider: boolean;
}

export default function useRate(rates: FinanceRate[]): RateHook {
  const {
    total,
    deposit,
    lenderConfigs,
    isExample,
    hideUnavailable,
    showDivider,
    ignoreConfigs,
  } = useFinanceOptionsContext();

  const pending = useMemo(() => {
    if (ignoreConfigs) return false;
    if (isExample) return false;
    if (!lenderConfigs) return true;
    const rateLenders = rates
      .map((rate) => rate.provider)
      .filter((provider, idx, arr) => arr.indexOf(provider) === idx);
    const configs = rateLenders.map((lender) => lenderConfigs[lender]);
    return configs.some((config) => !config);
  }, [rates, ignoreConfigs, isExample, lenderConfigs]);

  const validRates = useMemo(
    () => filterRatesByTotalAndDeposit(rates, total, deposit),
    [rates, total, deposit],
  );

  const available = useMemo(() => validRates.length > 0, [validRates]);

  return {
    available,
    pending,
    hideUnavailable,
    showDivider,
  };
}
