import type { Company } from '@kanda-libs/ks-frontend-services';
import type { CompanyDetailsFieldValues } from './useCompanyDetailsForm';

export const getCompanyBody = (
  company: Company,
  formValues: CompanyDetailsFieldValues,
): Company | undefined => {
  const limited = company.company_type === 'limited_company';
  if (limited) {
    const limitedInfo = formValues.company.limited_company_info;
    if (!limitedInfo) return undefined;
    return {
      ...company,
      limited_company_info: {
        ...company.limited_company_info,
        ...limitedInfo,
      },
    };
  }
  const soleInfo = formValues.company.sole_trader_info;
  if (!soleInfo) return undefined;
  return {
    ...company,
    sole_trader_info: {
      ...company.sole_trader_info,
      ...soleInfo,
    },
  };
};
