import type { FinanceStatus, Job } from '@kanda-libs/ks-frontend-services';

export const getSuffix = (
  status?: Job['status'],
  financeStatus?: FinanceStatus,
): string => {
  if (!status) return 'Updated';
  if (status === 'draft') return 'Drafted';
  if (status === 'declined') return 'Declined';
  if (status === 'sent') return 'Sent';
  if (status === 'finished') return 'Finished';
  switch (financeStatus) {
    case 'not_applied':
      return 'Sent';
    case 'applied_for_finance':
      return 'Applied';
    case 'finance_not_approved':
      return 'Not Approved';
    case 'financed':
      return 'Approved';
    case 'finance_cancelled':
      return 'Cancelled';
    case 'sat_note_delayed':
    case 'sat_note_lender_review':
    case 'sat_note_sent':
    case 'sat_note_signed':
    case 'sat_note_viewed':
    case 'payout_pending':
      return 'Finalising';
    case 'paid':
      return 'Paid';
    case 'under_review':
      return 'In review';
    default:
      return 'Updated';
  }
};
