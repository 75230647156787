import { Layout } from '@kanda-libs/ks-design-library';
import { CompanyBanner } from 'pages/MyAccount/components';
import JobsBanner from '../components/Banner';
import JobsMobileHeader from './Header';
import useJobsMobile from './useJobsMobile';

const JobsMobile: React.FunctionComponent = function () {
  const { Content, archive } = useJobsMobile();
  return (
    <Layout
      stickyFooter
      noBorder
      stickyHeader
      scrollTop
      header={<JobsMobileHeader archive={archive} />}
    >
      <CompanyBanner homeBanner />
      <JobsBanner />
      {Content && <Content />}
    </Layout>
  );
};

export default JobsMobile;
