import { Button } from '@kanda-libs/ks-design-library';
import InfoCard from 'components/InfoCard';
import JobFinanceApplicationProgressCard from './Card';
import useJobFinanceApplicationProgress from './useJobFinanceApplicationProgress';

const JobFinanceApplicationProgress: React.FunctionComponent = function ({}) {
  const { isLoading, showStatus } = useJobFinanceApplicationProgress();
  if (!showStatus) return null;
  return (
    <InfoCard
      title="Finance Application"
      headerAppend={
        <a
          href="https://clt1489884.bmeurl.co/10B8FC47"
          target="_blank"
          rel="noreferrer"
          className="ml-auto flex h-5"
        >
          <Button.InlineLink
            variant="turquoise"
            id="payouts-explainer"
            label="Payouts Explained"
            className="-mt-1"
          />
        </a>
      }
      isLoading={isLoading}
    >
      <JobFinanceApplicationProgressCard />
    </InfoCard>
  );
};

export default JobFinanceApplicationProgress;
