import { Text } from '@kanda-libs/ks-design-library';
import useProgressIndicator from './useProgressIndicator';

export type ProgressColor = 'green' | 'red';

export interface ProgressStep {
  active: boolean;
  text: string;
}

export interface ProgressIndicatorProps {
  steps: ProgressStep[];
  color?: ProgressColor;
  isLoading?: boolean;
}

const ProgressIndicator: React.FunctionComponent<ProgressIndicatorProps> =
  function ({ steps, color = 'green', isLoading = false }) {
    const { style, classNames, headers } = useProgressIndicator(
      steps,
      color,
      isLoading,
    );
    return (
      <div className={classNames.main}>
        <div className={classNames.container}>
          <div className={classNames.wrapper}>
            {headers.map((header) => (
              <Text
                key={header.text}
                text={header.text}
                className={header.className}
                isLoading={isLoading}
                skeletonProps={{ width: '100px' }}
              />
            ))}
          </div>
          <div className={classNames.barsWrapper}>
            <div className={classNames.bar} />
            <div className={classNames.progress} style={style} />
          </div>
        </div>
      </div>
    );
  };

export default ProgressIndicator;
