import iwocaBadgeImage from 'assets/iwocaPay-payment-method-badge.png';

const IwocaBadge: React.FunctionComponent = function ({}) {
  return (
    <div className="w-30 min-w-30 -mt-4">
      <img src={iwocaBadgeImage} alt="iwocaPay" />
    </div>
  );
};

export default IwocaBadge;
