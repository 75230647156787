import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMyAccountCompanyInformationContext } from '../../../context';

export default function useSoleTraderInfo(isLoading: boolean) {
  const { company } = useMyAccountCompanyInformationContext();

  const { setValue } = useFormContext();

  const tradingName = useMemo(
    () => company?.sole_trader_info?.trading_name,
    [company],
  );

  const tradingAddress = useMemo(
    () => company?.sole_trader_info?.trading_address,
    [company],
  );

  const niNumber = useMemo(
    () => company?.sole_trader_info?.national_insurance_number,
    [company],
  );

  useEffect(() => {
    if (isLoading || !tradingName) return;
    setValue('company.sole_trader_info.trading_name', tradingName);
    if (tradingAddress) {
      setValue('company.sole_trader_info.trading_address', tradingAddress);
    }
    if (niNumber) {
      setValue('company.sole_trader_info.national_insurance_number', niNumber);
    }
  }, [isLoading, tradingName, tradingAddress, niNumber, setValue]);
}
