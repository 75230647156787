import { Layout } from '@kanda-libs/ks-design-library';
import ContactBanner from 'components/ContactBanner';
import JobMarkAsFinishedButton from '../components/JobMarkAsFinishedButton';
import JobSendReminderButton from '../components/JobSendReminderButton';
import { Content, Header } from './components';

const JobMobile: React.FunctionComponent = function () {
  return (
    <Layout
      stickyFooter
      footer={
        <>
          <JobMarkAsFinishedButton />
          <JobSendReminderButton />
        </>
      }
      header={<Header />}
      noBorder
    >
      <Content />
      <ContactBanner />
    </Layout>
  );
};

export default JobMobile;
