import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  actions,
  Company,
  type Document,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { useMyAccountCompanyInformationContext } from '../../context';
import { formatLogoBody } from './helpers';

export interface LogoFormValues {
  company: {
    company_logo: Document | undefined;
  };
}

export interface LogoFormHook {
  hasLogo: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  form: UseFormReturn<LogoFormValues>;
  onSubmit: (formValues: StringIndexedObject<LogoFormValues>) => void;
}

export default function useLogoForm(): LogoFormHook {
  const { company, id, isSubmitting, isLoading } =
    useMyAccountCompanyInformationContext();

  const dispatch = useAppDispatch();

  const { showSuccess } = useToast();

  const onError = useApiError(
    'Error updating company logo at this time - please try again later',
  );

  const companyLogo = useMemo(() => {
    if (isLoading) return undefined;
    return company?.company_logo;
  }, [isLoading, company]);

  const defaultValues = useMemo(
    () => ({
      company: {
        company_logo: companyLogo,
      },
    }),
    [companyLogo],
  );

  const form = useForm<LogoFormValues>({
    defaultValues,
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<LogoFormValues>) => {
      if (!company || !id) return;
      const fv = formValues as unknown as LogoFormValues;
      const body = formatLogoBody(company, fv);
      dispatch(
        actions.putCompany({
          body,
          params: { id },
          onError,
          onSuccess: (response: Company) => {
            showSuccess('Company Updated!');
            form.setValue('company.company_logo', response.company_logo);
          },
        }),
      );
    },
    [company, id, dispatch, onError, showSuccess, form],
  );

  const values = form.watch();
  const logoId = values?.company?.company_logo?.id;

  const hasLogo = useMemo(() => Boolean(logoId), [logoId]);

  useEffect(() => {
    if (!companyLogo) return;
    form.reset({
      company: {
        company_logo: companyLogo,
      },
    });
  }, [form, companyLogo]);

  return {
    form,
    onSubmit,
    hasLogo,
    isLoading,
    isSubmitting,
  };
}
