import { Button } from '@kanda-libs/ks-design-library';
import useSetupBusinessConfigSubmitButton from './useSetupBusinessConfigSubmitButton';

export interface SetupAccountSubmitButtonProps {
  isSubmitting: boolean;
}

const SetupBusinessConfigSubmitButton: React.FunctionComponent<SetupAccountSubmitButtonProps> =
  function ({ isSubmitting }) {
    const { disabled, show } = useSetupBusinessConfigSubmitButton();
    if (!show) return null;
    return (
      <Button.Text
        id="setup-business-config-submit"
        submit
        className="w-full mt-6"
        label="Submit"
        icon="arrow-right"
        variant="gradient"
        disabled={isSubmitting || disabled}
      />
    );
  };

export default SetupBusinessConfigSubmitButton;
