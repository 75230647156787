import type { JobCustomerData } from './helpers';

export const SEARCH_OPTIONS = {
  keys: [
    { name: 'id', weight: 1 },
    { name: 'firstName', weight: 1 },
    { name: 'lastName', weight: 1 },
    { name: 'title', weight: 1 },
    { name: 'email', weight: 1 },
    { name: 'phone', weight: 1 },
    { name: 'address', weight: 1 },
    { name: 'status', weight: 1 },
    { name: 'financeStatus', weight: 1 },
    { name: 'type', weight: 1 },
    { name: 'price', weight: 1 },
  ],
  includeMatches: true,
  matchAllOnEmptyQuery: true,
  minMatchCharLength: 3,
  threshold: 0.4,
};

export const NO_CUSTOMER: JobCustomerData = {
  firstName: 'No Name Added',
  lastName: '',
  email: 'No Email Added',
  phone: 'No Phone Added',
  address: '',
};
