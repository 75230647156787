import type {
  FinanceStatus,
  Job,
  JobCreditState,
} from '@kanda-libs/ks-frontend-services';
import { HEADINGS } from './constants';

export const getDescription = (
  job: Job | undefined,
  status: FinanceStatus | 'ag_live',
  creditStatus: JobCreditState['current_status'],
) => {
  const keys = Object.keys(HEADINGS);
  if (!keys.includes(status))
    return {
      heading: '',
      subHeading: '',
    };
  const headings = HEADINGS[status];

  const payment = [...(job?.payments || [])]
    ?.sort(
      (p1, p2) =>
        new Date(p2?.metadata?.created_at || 0).getTime() -
        new Date(p1?.metadata?.created_at || 0).getTime(),
    )
    ?.filter((p) => p?.payment_option?.payment_method === 'card')?.[0];
  if (!payment) return headings;

  if (
    creditStatus === 'accepted_sign_document' ||
    creditStatus === 'accepted_deposit_required'
  )
    return HEADINGS.applied_for_finance;

  return headings;
};
