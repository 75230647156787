import {
  SkeletonLoader,
  type SkeletonLoaderProps,
} from '@kanda-libs/ks-design-library';
import { useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import type { QueryType } from 'store/slices/query';
import useJobTitleLineClamp from './useJobTitleLineClamp';

export interface JobTitleLineClampProps {
  text: string;
  className?: string;
  lines?: 2 | 3;
  queryType?: QueryType;
  isLoading?: boolean;
  skeletonProps?: SkeletonLoaderProps;
}

export const HIGHLIGHT_PROPS = {
  highlightClassName: 'text-green-300 bg-transparent',
  autoEscape: true,
};

export const BASE_SKELETON_PROPS: SkeletonLoaderProps = {
  width: '100%',
  height: 20,
  count: 2,
  className: 'mb-1',
};

const JobTitleLineClamp: React.FunctionComponent<JobTitleLineClampProps> =
  function ({
    text,
    className = 'text-14-22-em',
    lines = 2,
    queryType = 'jobs',
    isLoading = false,
    skeletonProps = {},
  }) {
    const { textClassName, searchWords } = useJobTitleLineClamp(
      className,
      lines,
      queryType,
    );

    const finalSkeletonProps = useMemo(
      () => ({
        ...BASE_SKELETON_PROPS,
        ...skeletonProps,
      }),
      [skeletonProps],
    );

    return (
      <SkeletonLoader
        {...finalSkeletonProps}
        isLoading={isLoading}
        afterLoading={
          <Highlighter
            {...HIGHLIGHT_PROPS}
            searchWords={searchWords}
            textToHighlight={text || ''}
            className={textClassName}
          />
        }
      />
    );
  };

export default JobTitleLineClamp;
