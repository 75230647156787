import { Button, Header } from '@kanda-libs/ks-design-library';
import JobSlideUpModal from '../JobSlideUpModal';
import useJobMobileHeader from './useJobMobileHeader';

const JobMobileHeader: React.FunctionComponent = function ({}) {
  const { openSlideUp, onClick } = useJobMobileHeader();
  return (
    <Header.Base
      options={[
        <Button.Icon
          id="job-details-mobile-header-options"
          key="more"
          icon="more"
          onClick={openSlideUp}
        />,
      ]}
    >
      <Button.Icon
        id="job-details-mobile-header-back"
        onClick={onClick}
        icon="chevron-left"
        className="-ml-3"
      />
      <JobSlideUpModal />
    </Header.Base>
  );
};

export default JobMobileHeader;
