import { Button, Icon, Text } from '@kanda-libs/ks-design-library';

export interface CompanyDetailsFormLimitedCardProps {
  name: string;
  number: string;
  address: string;
  isLoading: boolean;
  onSearchCompanyClick: () => void;
}

const CompanyDetailsFormLimitedCard: React.FunctionComponent<CompanyDetailsFormLimitedCardProps> =
  function ({ name, number, address, isLoading, onSearchCompanyClick }) {
    return (
      <div className="flex flex-col mb-2">
        <Button.InlineLink
          id="company-info-change-company"
          label="Choose different company"
          variant="turquoise"
          onClick={onSearchCompanyClick}
          className="ml-auto mb-1 cursor-pointer"
        />
        <div className="flex flex-col w-full border rounded-lg border border-neutral-300 shadow-card py-5 px-4">
          <div className="flex flex-row gap-x-3 mb-2">
            <Icon
              size={20}
              stroke="neutral-700"
              icon="office"
              className="min-w-5 min-h-5"
              isLoading={isLoading}
            />
            <Text
              text={name}
              className="text-14-22-em text-neutral-900"
              isLoading={isLoading}
            />
          </div>
          <Text
            text={number}
            className="text-14-22 text-neutral-700"
            isLoading={isLoading}
          />
          <Text
            text={address}
            className="text-14-22 text-neutral-700"
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  };

export default CompanyDetailsFormLimitedCard;
