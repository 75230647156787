import { useIsDesktop } from '@kanda-libs/ks-design-library';
import type { FinanceStatus, Job } from '@kanda-libs/ks-frontend-services';
import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';

export interface JobStatusBarHook {
  price: number;
  status: Job['status'];
  financeStatus: FinanceStatus | undefined;
  example: boolean;
  archive: boolean;
  isLoading: boolean;
  isDesktop: boolean;
}

export default function useJobStatusBar(): JobStatusBarHook {
  const { job, example, archived, isLoading } = useJobContext();
  const isDesktop = useIsDesktop();

  const price = useMemo(
    () => job?.total?.amount_vat_inclusive?.amount || 0,
    [job],
  );

  const status = useMemo(() => job?.status, [job]);

  const financeStatus = useMemo(() => job?.finance_status, [job]);

  return {
    price,
    status,
    financeStatus,
    example,
    archive: archived,
    isLoading,
    isDesktop,
  };
}
