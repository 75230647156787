import { Button, Popover } from '@kanda-libs/ks-design-library';
import { JobMenu } from 'components';
import JobMarkAsFinishedButton from 'pages/Job/components/JobMarkAsFinishedButton';
import JobSendReminderButton from 'pages/Job/components/JobSendReminderButton';
import useJobActionsButtons from './useJobActionsButtons';

const JobActionButtons: React.FunctionComponent = function ({}) {
  const { id, status, downloadPdf } = useJobActionsButtons();
  return (
    <div className="flex flex-row gap-x-6 -mr-4">
      <JobMarkAsFinishedButton />
      <JobSendReminderButton />
      <Popover.Standard
        id="lead-details-header-more"
        className="mr-4 z-50"
        button={<Button.Icon id="lead-details-header-more" icon="more" />}
        yMargin="-mt-8"
        xMargin="mr-6"
        right
      >
        {({ handleClose }) => (
          <JobMenu
            jobId={id}
            status={status}
            handleClose={handleClose}
            downloadPdf={downloadPdf}
          />
        )}
      </Popover.Standard>
    </div>
  );
};

export default JobActionButtons;
