import { SkeletonLoader, Tag } from '@kanda-libs/ks-design-library';
import type { FormattedJobType } from 'pages/Jobs/helpers';
import { type FunctionComponent } from 'react';
import Highlighter from 'react-highlight-words';
import { type QueryType } from 'store/slices/query';
import {
  CLASS_NAMES,
  DRAFT_TAG_PROPS,
  EXAMPLE_TAG_PROPS,
  HIGHLIGHT_PROPS,
  LEAD_TAG_PROPS,
} from './constants';
import useJobNameType from './useJobNameType';

export interface JobNameTypeProps {
  firstName: string;
  lastName: string;
  type: FormattedJobType[];
  queryType?: QueryType;
  className?: string;
  owner?: boolean;
  current?: boolean;
  hideTag?: boolean;
  isLoading?: boolean;
}

const JobNameType: FunctionComponent<JobNameTypeProps> = function ({
  firstName,
  lastName,
  type = ['sent'],
  queryType = 'jobs',
  className = CLASS_NAMES.text,
  hideTag = false,
  isLoading = false,
}) {
  const { name, searchWords, showTags, showExample, showDraft, showLead } =
    useJobNameType(firstName, lastName, type, queryType, hideTag);

  return (
    <div className={CLASS_NAMES.container}>
      <SkeletonLoader
        isLoading={isLoading}
        afterLoading={
          <Highlighter
            {...HIGHLIGHT_PROPS}
            searchWords={searchWords}
            textToHighlight={name || ''}
            className={`${className}  max-w-full overflow-hidden overflow-ellipsis`}
          />
        }
      />
      <SkeletonLoader
        isLoading={isLoading}
        width={100}
        height={20}
        afterLoading={
          showTags ? (
            <div className="flex flex-row gap-x-1">
              {showExample && <Tag {...EXAMPLE_TAG_PROPS} />}
              {!showExample && showDraft && <Tag {...DRAFT_TAG_PROPS} />}
              {!showExample && showLead && <Tag {...LEAD_TAG_PROPS} />}
            </div>
          ) : null
        }
      />
    </div>
  );
};

export default JobNameType;
