export const PROMPT_DEPOSIT = 'Prompt your customer to pay their deposit';

export const PROMPT_SIGN = 'Prompt your customer to sign their documents';

export const NO_ACTION = 'No action is required from you';

export const SAT_NOTE =
  'The sat note has now been signed and Kanda will call the customer within the next 24 hours. Afterwards the lender will complete a welcome call and you will be paid. This can take 2-4 working days. If there is a problem we will get in touch with you directly.';

export const AG_LIVE =
  'As soon as Kanda recieve payment from the lender, we will pay you. This is typically within 3 working days. You should be paid by $DATE';
