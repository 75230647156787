import { BANNED_CIDS } from 'config';
import useIsSubscribed from 'hooks/useIsSubscribed';
import { useJobsContext } from 'pages/Jobs/context';
import { useMemo } from 'react';
import { checkCompanySetupInProgress } from './helpers';

export interface JobsBannerHook {
  showBanner: boolean;
  isLoading: boolean;
  showOptions: boolean;
  setupInProgress: boolean;
  hasActiveFilters: boolean;
  showSubscriptionBanner: boolean;
  archive: boolean;
  showSearch: boolean;
  hideButton: boolean;
}

export default function useJobsBanner(): JobsBannerHook {
  const {
    leads,
    isLoading,
    noJobs,
    company,
    hasActiveFilters,
    archive,
    showOptions,
  } = useJobsContext();

  const setupInProgress = useMemo(
    () => checkCompanySetupInProgress(company),
    [company],
  );

  const { isSubscribed } = useIsSubscribed();

  const showSubscriptionBanner = useMemo(
    () => !isLoading && !setupInProgress && !archive && !isSubscribed,
    [isLoading, setupInProgress, archive, isSubscribed],
  );

  const showSearch = useMemo(() => {
    if (isLoading) return true;
    return !noJobs;
  }, [isLoading, noJobs]);

  const showBanner = useMemo(() => {
    if (isLoading) return true;
    if (noJobs) return false;
    if (leads.length === 0 && !noJobs) return true;
    return true;
  }, [isLoading, noJobs, leads]);

  const hideButton = useMemo(() => {
    if (!company) return true;
    const { cid } = company;
    if (!cid) return true;
    return BANNED_CIDS.includes(cid);
  }, [company]);

  return {
    showBanner,
    isLoading,
    showOptions,
    setupInProgress,
    hasActiveFilters,
    showSubscriptionBanner,
    archive,
    showSearch,
    hideButton,
  };
}
