import { Layout } from '@kanda-libs/ks-design-library';

import Header from '../../components/Header';
import { CLASS_NAMES } from '../../MyAccount-constants';
import { Content } from '../components';

import { CompanyBanner } from '../../components';

const ViewComponent = ({}) => (
  <Layout noBorder header={<Header />} headerBg="neutral-100" bg="neutral-100">
    <CompanyBanner />
    <div className={CLASS_NAMES.container}>
      <div className={CLASS_NAMES.content}>
        <Content />
      </div>
    </div>
  </Layout>
);

ViewComponent.displayName = 'CompanyInformation-Mobile-view';

export default ViewComponent;
