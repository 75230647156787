import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';
import {
  AG_LIVE,
  NO_ACTION,
  PROMPT_DEPOSIT,
  PROMPT_SIGN,
  SAT_NOTE,
} from './constants';
import { getPayoutDate, getStatus } from './helpers';

export interface JobFinanceApplicationProgressCardStatusHook {
  status: string | undefined;
  subStatus: string;
  isLoading: boolean;
}

export default function useJobFinanceApplicationProgressCardStatus(): JobFinanceApplicationProgressCardStatusHook {
  const { isLoading, job, checkJob } = useJobContext();

  const financeStatus = useMemo(() => {
    if (!job || !checkJob) return undefined;
    if (!checkJob?.has_finance_application) return undefined;
    return checkJob?.current_status;
  }, [job, checkJob]);

  const xstatus = useMemo(() => checkJob?.xstatus, [checkJob]);

  const status = useMemo(
    () => getStatus(job, financeStatus, xstatus),
    [job, financeStatus, xstatus],
  );

  const payoutDate = useMemo(() => {
    if (!xstatus || !xstatus.includes('AG_LIVE')) return '';
    return getPayoutDate(job?.metadata?.updated_at);
  }, [job, xstatus]);

  const subStatus = useMemo(() => {
    if (financeStatus === 'accepted_deposit_required') return PROMPT_DEPOSIT;
    if (financeStatus === 'accepted_sign_document') return PROMPT_SIGN;
    if (
      job?.finance_status === 'sat_note_signed' ||
      job?.finance_status === 'sat_note_lender_review'
    ) {
      if (!xstatus || !xstatus.includes('AG_LIVE')) return SAT_NOTE;
      return AG_LIVE.replace('$DATE', payoutDate);
    }
    return NO_ACTION;
  }, [financeStatus, job, xstatus, payoutDate]);

  return {
    status,
    subStatus,
    isLoading,
  };
}
