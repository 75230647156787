import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';

export interface JobFinanceApplicationProgressCardAddBankDetailsHook {
  showButton: boolean;
  isLoading: boolean;
}

export default function useJobFinanceApplicationProgressCardAddBankDetails(): JobFinanceApplicationProgressCardAddBankDetailsHook {
  const { isLoading, job, company } = useJobContext();

  const financeStatus = useMemo(
    () => job?.finance_status || 'not_applied',
    [job],
  );

  const showButton = useMemo(
    () =>
      ['payout_pending', 'financed'].includes(financeStatus) &&
      !company?.bank_account,
    [financeStatus, company],
  );

  return {
    showButton,
    isLoading,
  };
}
