import { Field, Form, FormTheme, Widget } from '@kanda-libs/ks-component-ts';
import { Button, Text } from '@kanda-libs/ks-design-library';
import AddressFields from 'components/Address';
import { PHONE_NUMBER_PROPS, PHONE_NUMBER_VALIDATION } from './constants';
import useContactForm from './useContactForm';

const ContactForm: React.FunctionComponent = function ({}) {
  const { form, onSubmit, emailValidation, isLoading, isSubmitting } =
    useContactForm();
  return (
    <FormTheme variant="streamline">
      <Form
        id="my-account-company-information-contact-form"
        form={form}
        onSubmit={onSubmit}
        className="flex flex-col w-full"
      >
        <Text
          text="Company Contact Details"
          className="text-14-22-em text-neutral-900 mb-4"
          isLoading={isLoading}
        />
        <Widget.ContactInfoContactName />
        <div className="flex flex-col lg:flex-row gap-x-3">
          <Field.Validator validation={emailValidation}>
            <Widget.ContactInfoContactEmail isLoading={isLoading} />
          </Field.Validator>
          <Field.Validator validation={PHONE_NUMBER_VALIDATION}>
            <Widget.ContactInfoContactPhone {...PHONE_NUMBER_PROPS} />
          </Field.Validator>
        </div>
        <AddressFields baseFieldName="contact_info.contact_address" />
        <Button.Text
          submit
          id="my-account-company-information-contact-submit"
          label="Update Contact Details"
          size={40}
          className="ml-auto"
          disabled={isSubmitting}
        />
      </Form>
    </FormTheme>
  );
};

export default ContactForm;
