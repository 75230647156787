import type { FinanceRate, WorkType } from '@kanda-libs/ks-frontend-services';
import {
  createContext,
  useContext,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import type { FormattedLenderConfig } from 'utils';

export interface FinanceOptionsContextType {
  lenderConfigs: FormattedLenderConfig | undefined;
  rates: FinanceRate[] | undefined;
  workType: WorkType | undefined;
  total: number;
  deposit: number;
  toggleable: boolean;
  fieldName: string;
  showHandle: boolean;
  showDivider: boolean;
  isExample: boolean;
  isLoading: boolean;
  hideBox: boolean;
  hideNoRates: boolean;
  hideSubtitle: boolean;
  hideUnavailable: boolean;
  ignoreConfigs: boolean;
}

export const FinanceOptionsContext = createContext<FinanceOptionsContextType>({
  lenderConfigs: undefined,
  rates: undefined,
  workType: undefined,
  total: 0,
  deposit: 0,
  showHandle: true,
  toggleable: true,
  fieldName: '',
  showDivider: true,
  isExample: false,
  isLoading: false,
  hideBox: false,
  hideNoRates: false,
  hideSubtitle: false,
  hideUnavailable: false,
  ignoreConfigs: false,
});

export const useFinanceOptionsContext = () => useContext(FinanceOptionsContext);

export interface FinanceOptionsProviderProps {
  children: ReactNode;
  lenderConfigs: FormattedLenderConfig | undefined;
  rates: FinanceRate[] | undefined;
  total: number;
  deposit: number;
  workType: WorkType | undefined;
  toggleable: boolean;
  fieldName: string;
  showHandle: boolean;
  showDivider: boolean;
  isExample: boolean;
  isLoading: boolean;
  hideBox: boolean;
  hideNoRates: boolean;
  hideSubtitle: boolean;
  hideUnavailable: boolean;
  ignoreConfigs: boolean;
}

const FinanceOptionsProvider: FunctionComponent<FinanceOptionsProviderProps> =
  function ({
    children,
    lenderConfigs,
    rates,
    workType,
    total,
    deposit,
    showHandle,
    toggleable,
    fieldName,
    showDivider,
    isExample,
    isLoading,
    hideBox,
    hideNoRates,
    hideSubtitle,
    hideUnavailable,
    ignoreConfigs,
  }) {
    const values = useMemo(
      () => ({
        lenderConfigs,
        rates,
        workType,
        total,
        deposit,
        showHandle,
        toggleable,
        fieldName,
        showDivider,
        isExample,
        isLoading,
        hideBox,
        hideNoRates,
        hideSubtitle,
        hideUnavailable,
        ignoreConfigs,
      }),
      [
        lenderConfigs,
        rates,
        total,
        workType,
        deposit,
        showHandle,
        toggleable,
        fieldName,
        showDivider,
        isExample,
        isLoading,
        hideBox,
        hideNoRates,
        hideSubtitle,
        hideUnavailable,
        ignoreConfigs,
      ],
    );

    return (
      <FinanceOptionsContext.Provider value={values}>
        {children}
      </FinanceOptionsContext.Provider>
    );
  };

export default FinanceOptionsProvider;
