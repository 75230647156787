import type { Company, Enquiry, Job } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { pipe } from 'fp-ts/lib/function';
import { useCurrentCompany } from 'hooks';
import usePagination from 'hooks/usePagination';
import useQuery from 'hooks/useQuery';
import useSearchQuery from 'hooks/useSearchQuery';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectors } from 'store';
import { getJobsFilters } from 'store/selectors/filters';
import { getJobsSorting } from 'store/selectors/sorting';
import type { JobsFilter } from 'store/slices/filters';
import { setSorting } from 'store/slices/sorting';
import { SEARCH_OPTIONS } from './constants';
import {
  filterJobs,
  filterJobsForArchiveStatus,
  formatJobs,
  getNewOrder,
  getNewSorting,
  sortJobsData,
  type FormattedJob,
} from './helpers';

export interface JobsSortingAction {
  type?: string;
}

export interface JobsContextType {
  jobs: Job[];
  leads: Enquiry[];
  data: FormattedJob[];
  company: Company | undefined;
  archive: boolean;
  filter: JobsFilter;
  query: string;
  hasActiveFilters: boolean;
  noJobs: boolean;
  showOptions: boolean;
  numItems: number;
  pageIndex: number;
  perPage: number;
  totalPages: number;
  setPage: (index: number) => void;
  onAction: (action?: JobsSortingAction) => void;
  isLoading: boolean;
}

export interface JobsProviderProps {
  children: ReactNode;
}

export const JobsContext = createContext<JobsContextType>({
  jobs: [],
  leads: [],
  data: [],
  company: undefined,
  archive: false,
  filter: {},
  query: '',
  hasActiveFilters: false,
  noJobs: false,
  showOptions: true,
  numItems: 0,
  pageIndex: 0,
  perPage: 0,
  totalPages: 0,
  setPage: () => {},
  onAction: () => {},
  isLoading: false,
});

export const useJobsContext = () => useContext(JobsContext);

const JobsProvider: FunctionComponent<JobsProviderProps> = function ({
  children,
}) {
  const jobs = useSelector(selectors.job.getEntitiesAsArray);
  const jobsHasFetched = useSelector(selectors.job.getHasFetched);

  const leads = useSelector(selectors.enquiry.getEntitiesAsArray);
  const leadsHasFetched = useSelector(selectors.enquiry.getHasFetched);

  const filter = useSelector(getJobsFilters);
  const { sorting, order } = useSelector(getJobsSorting);

  const { company } = useCurrentCompany();
  const { fetchedQuery: query } = useQuery('jobs');

  const isLoading = useMemo(
    () => !leadsHasFetched || !jobsHasFetched || !company,
    [leadsHasFetched, jobsHasFetched, company],
  );

  const { pathname } = useLocation();

  const archive = useMemo(() => pathname === URLS.archive, [pathname]);

  const dispatch = useAppDispatch();

  const onAction = useCallback(
    (action?: JobsSortingAction) => {
      if (!action) return;
      const { type } = action;
      const newOrder = getNewOrder(type, order);
      const newSorting = getNewSorting(type);
      dispatch(
        setSorting({
          jobs: {
            sorting: newSorting,
            order: newOrder,
          },
        }),
      );
    },
    [dispatch, order],
  );

  const filteredJobs = useMemo(
    () =>
      pipe(
        jobs,
        (all) => filterJobsForArchiveStatus(all, archive),
        (filtered) => formatJobs(filtered, leads),
        (formatted) => filterJobs(formatted, filter),
      ),
    [jobs, archive, filter, leads],
  );

  const { hits } = useSearchQuery<FormattedJob>(
    filteredJobs,
    'jobs',
    SEARCH_OPTIONS,
    0,
  );

  const sortedJobs = useMemo(
    () =>
      sortJobsData(
        hits.map((hit) => hit.item),
        sorting,
        order,
      ),
    [hits, sorting, order],
  );

  const numItems = useMemo(() => sortedJobs.length, [sortedJobs]);

  const { pageIndex, perPage, totalPages, setPageIndex } =
    usePagination(numItems);

  const paginatedData = useMemo(
    () => sortedJobs.slice(pageIndex * perPage, pageIndex * perPage + perPage),
    [sortedJobs, pageIndex, perPage],
  );

  const noJobs = useMemo(() => {
    if (isLoading) return false;
    return jobs.length === 0;
  }, [isLoading, jobs]);

  const showOptions = useMemo(
    () => !isLoading && leads?.length > 0,
    [leads, isLoading],
  );

  const hasActiveFilters = useMemo(
    () =>
      Boolean(
        (filter?.status || []).length > 0 ||
          (filter?.financeStatus || []).length > 0,
      ),
    [filter],
  );

  const values = useMemo(
    () => ({
      jobs,
      leads,
      data: paginatedData,
      company: company || undefined,
      archive,
      filter,
      query,
      hasActiveFilters,
      noJobs,
      showOptions,
      numItems,
      pageIndex,
      perPage,
      totalPages,
      setPage: setPageIndex,
      onAction,
      isLoading,
    }),
    [
      jobs,
      leads,
      paginatedData,
      company,
      archive,
      filter,
      query,
      hasActiveFilters,
      noJobs,
      showOptions,
      numItems,
      pageIndex,
      perPage,
      totalPages,
      setPageIndex,
      onAction,
      isLoading,
    ],
  );

  return <JobsContext.Provider value={values}>{children}</JobsContext.Provider>;
};

export default JobsProvider;
