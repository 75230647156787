import { type FunctionComponent } from 'react';
import Highlighter from 'react-highlight-words';
import { type QueryType } from 'store/slices/query';
import { CLASS_NAMES, HIGHLIGHT_PROPS } from './constants';
import useTableContact from './useTableContact';

export interface TableContactProps {
  email: string;
  phone: string;
  address?: string;
  queryType?: QueryType;
  textClassName?: string;
  addressClassName?: string;
}

const TableContact: FunctionComponent<TableContactProps> = function ({
  email,
  phone,
  queryType = 'leads',
  address,
  textClassName = 'text-14-22',
  addressClassName = 'text-12-18',
}) {
  const { searchWords } = useTableContact(queryType);

  return (
    <div className={CLASS_NAMES.container}>
      <Highlighter
        {...HIGHLIGHT_PROPS}
        className={`${textClassName}  max-w-full overflow-hidden overflow-ellipsis`}
        searchWords={searchWords}
        textToHighlight={email || ''}
      />
      <Highlighter
        {...HIGHLIGHT_PROPS}
        className={`${textClassName}  max-w-full overflow-hidden overflow-ellipsis`}
        searchWords={searchWords}
        textToHighlight={phone || ''}
      />
      {address && (
        <Highlighter
          {...HIGHLIGHT_PROPS}
          className={`${addressClassName}  max-w-full overflow-hidden overflow-ellipsis`}
          searchWords={searchWords}
          textToHighlight={address || ''}
        />
      )}
    </div>
  );
};

export default TableContact;
