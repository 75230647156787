import { Form } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import Logo from './Logo';
import LogoInput from './LogoInput';
import useLogoForm from './useLogoForm';

const LogoForm: React.FunctionComponent = function ({}) {
  const { form, onSubmit, isSubmitting, isLoading, hasLogo } = useLogoForm();
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      id="my-account-company-information-logo-form"
      className="flex flex-col gap-y-4"
    >
      {hasLogo || isLoading ? (
        <Logo />
      ) : (
        <>
          <LogoInput />
          <Button.Text
            submit
            id="my-account-company-information-logo-form-submit"
            label="Upload Logo"
            size={40}
            disabled={isSubmitting}
            className="ml-auto -mt-6"
          />
        </>
      )}
    </Form>
  );
};

export default LogoForm;
