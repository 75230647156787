import { type TagProps } from '@kanda-libs/ks-design-library';
import { type FinanceStatus, type Job } from '@kanda-libs/ks-frontend-services';
import JobExampleTag from 'components/JobExample';
import JobFinanceStatus from 'components/JobFinanceStatus';
import JobStatus from 'components/JobStatus';

export interface JobCombinedStatusProps {
  status?: Job['status'];
  size?: TagProps['size'];
  financeStatus?: FinanceStatus;
  archived?: boolean;
  example?: boolean;
  isLoading?: boolean;
}

const JobCombinedStatus: React.FunctionComponent<JobCombinedStatusProps> =
  function ({
    status,
    financeStatus,
    size = 21,
    archived = false,
    example = false,
    isLoading = false,
  }) {
    return (
      <div className="flex flex-row flex-wrap md:flex-nowrap gap-x-1 md:gap-x-2 whitespace-nowrap align-end justify-end md:align-start md:justify-start my-auto">
        {example && (
          <div className="flex ml-auto md:ml-0">
            <JobExampleTag isLoading={isLoading} size={size} />
          </div>
        )}
        {status && status !== 'draft' && (
          <div className="flex ml-auto md:ml-0">
            <JobStatus
              status={status}
              archived={archived}
              isLoading={isLoading}
              size={size}
            />
          </div>
        )}
        {financeStatus && (
          <div className="flex ml-auto md:ml-0">
            <JobFinanceStatus
              financeStatus={financeStatus}
              archived={archived}
              isLoading={isLoading}
              size={size}
            />
          </div>
        )}
      </div>
    );
  };

export default JobCombinedStatus;
