import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import { type StringIndexedObject } from '@kanda-libs/ks-design-library';
import { actions, type Company } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { scrollToTop } from 'utils';
import { formatBody } from './helpers';

export interface BusinessConfigFormValues {
  company: Partial<Company>;
}

export interface BusinessConfigFormHook {
  form: UseFormReturn<BusinessConfigFormValues>;
  onSubmit: (formValues: StringIndexedObject<BusinessConfigFormValues>) => void;
  isSubmitting: boolean;
}

export default function useBusinessConfigForm(): BusinessConfigFormHook {
  const { company, isLoading } = useCurrentCompany();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const onCompanyError = useApiError('Error updating company info');

  const defaultValues = useMemo(() => {
    if (!company || isLoading) return {};
    return { company };
  }, [company, isLoading]);

  const form = useForm<BusinessConfigFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<BusinessConfigFormValues>) => {
      const id = company?.id;
      if (!company || !id) return;
      const body = formatBody(
        company,
        formValues as unknown as BusinessConfigFormValues,
      );
      setIsSubmitting(true);
      dispatch(
        actions.putCompany({
          params: { id },
          body,
          onError: (err) => {
            setIsSubmitting(false);
            onCompanyError(err);
          },
          onSuccess: (response) => {
            setIsSubmitting(false);
            const lifecycle = response?.lifecycle;
            setTimeout(() => {
              const url =
                lifecycle === 'registered' ? URLS.setupInfo : URLS.home;
              push(url);
              scrollToTop();
            }, 10);
          },
        }),
      );
    },
    [company, dispatch, onCompanyError, push],
  );

  useEffect(() => {
    if (!company) return;
    form.reset(defaultValues);
  }, [company, form, defaultValues]);

  return {
    form,
    onSubmit,
    isSubmitting,
  };
}
