import type { FinanceRate, WorkType } from '@kanda-libs/ks-frontend-services';
import type { FormattedLenderConfig } from 'utils';
import { EXAMPLE_COPY } from './constants';
import FinanceOptionsProvider from './context';
import FinanceRatesRegulated from './Regulated';
import FinanceRatesUnregulated from './Unregulated';

export interface FinanceOptionsProps {
  lenderConfigs: FormattedLenderConfig | undefined;
  rates: FinanceRate[] | undefined;
  total: number;
  deposit: number;
  workType?: WorkType;
  toggleable?: boolean;
  fieldName?: string;
  showHandle?: boolean;
  isLoading?: boolean;
  isExample?: boolean;
  showDivider?: boolean;
  hideUnregulated?: boolean;
  hideBox?: boolean;
  hideNoRates?: boolean;
  hideSubtitle?: boolean;
  hideUnavailable?: boolean;
  ignoreConfigs?: boolean;
}

const FinanceOptions: React.FunctionComponent<FinanceOptionsProps> = function ({
  lenderConfigs,
  rates,
  total,
  deposit,
  workType,
  toggleable = true,
  fieldName = '',
  showHandle = true,
  isLoading = false,
  isExample = false,
  hideUnregulated = false,
  showDivider = true,
  hideBox = false,
  hideNoRates = false,
  hideSubtitle = false,
  hideUnavailable = false,
  ignoreConfigs = false,
}) {
  return (
    <FinanceOptionsProvider
      lenderConfigs={lenderConfigs}
      rates={rates}
      total={total}
      deposit={deposit}
      workType={workType}
      showHandle={showHandle}
      toggleable={toggleable}
      fieldName={fieldName}
      showDivider={showDivider}
      isExample={isExample}
      isLoading={isLoading}
      hideBox={hideBox}
      hideNoRates={hideNoRates}
      hideSubtitle={hideSubtitle}
      hideUnavailable={hideUnavailable}
      ignoreConfigs={ignoreConfigs}
    >
      <div className="flex flex-col gap-y-4">
        {isExample && showHandle && (
          <div className="flex text-14-22 flex-col px-4 py-2 bg-lavender-100 text-lavender-200 border border-lavender-200 rounded-xl mb-4">
            <p className="text-14-22-em mb-1">Finance Rates</p>
            <p>{EXAMPLE_COPY}</p>
          </div>
        )}
        {!hideUnregulated && <FinanceRatesUnregulated />}
        <FinanceRatesRegulated />
      </div>
    </FinanceOptionsProvider>
  );
};

export default FinanceOptions;
