import { Link } from 'react-router-dom';

import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import LeadsActionBanner from 'components/LeadsActionBanner';
import SearchBar from 'components/SearchBar';
import { URLS } from 'config';
import {
  CompleteAccountBadge,
  NewJobButton,
  UpgradeAccountBadge,
} from './components';
import { BUTTON_PROPS, CLASS_NAMES } from './constants';
import useJobsBanner from './useJobsBanner';

const JobsBanner: React.FunctionComponent = function ({}) {
  const {
    showBanner,
    showOptions,
    setupInProgress,
    archive,
    showSubscriptionBanner,
    isLoading,
    showSearch,
    hideButton,
  } = useJobsBanner();
  return (
    <div className={CLASS_NAMES.container}>
      <LeadsActionBanner />
      {setupInProgress && !archive && (
        <div className="mb-8 md:mb-16">
          <CompleteAccountBadge />
        </div>
      )}
      {!setupInProgress && showSubscriptionBanner && (
        <div className="mb-8 md:mb-16">
          <UpgradeAccountBadge />
        </div>
      )}
      <div className={CLASS_NAMES.header}>
        {archive && (
          <div className="hidden md:block md:flex">
            <Link to={URLS.leads} className="-ml-3 mr-3">
              <Button.Icon id="jobs-banner-back" icon="arrow-left" size={40} />
            </Link>
          </div>
        )}
        <p className={CLASS_NAMES.title}>
          {archive ? 'Archived Jobs' : 'Jobs'}
        </p>
        {showBanner && showSearch && (
          <div className="hidden md:flex flex-1 flex-row ml-6 mr-12">
            <SearchBar queryType="jobs" isLoading={isLoading} />
          </div>
        )}
        <div className={CLASS_NAMES.options}>
          {showBanner && showOptions && !archive && (
            <Link to={URLS.leadsArchive} className="ml-2">
              <BreakPoints
                mobile={<Button.Icon {...BUTTON_PROPS.mobile} />}
                desktop={<Button.Text {...BUTTON_PROPS.desktop} />}
              />
            </Link>
          )}
          {!hideButton && <NewJobButton />}
        </div>
      </div>
      {showBanner && showSearch && (
        <div className="flex md:hidden flex-1 flex-row mb-4">
          <SearchBar queryType="jobs" isLoading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default JobsBanner;
