export const ACCOUNT_NAME_VALIDATION = {
  minLength: { value: 1, message: 'Account Name requires minimum length of 1' },
};

export const ACCOUNT_NUMBER_VALIDATION = {
  minLength: {
    value: 1,
    message: 'Account Number requires minimum length of 1',
  },
  pattern: {
    value: /^[0-9]{8}$/,
    message:
      'Account Number must be 8 digits. If your account number is less than 8 digits long please add a 0 at the start.',
  },
};

export const SORT_CODE_VALIDATION = {
  pattern: {
    value: /^[0-9]{2}[-][0-9]{2}[-][0-9]{2}$/,
    message: 'Sort Code input is invalid',
  },
};
