import type { WorkType } from '@kanda-libs/ks-frontend-services';

export const RENEWABLES_WORKTYPES = [
  'air_source_heat_pumps',
  'battery_storage',
  'ground_source_heat_pumps',
  'solar_pv_panels',
];

export const HOME_IMPROVEMENT_WORKTYPES: WorkType[] = [
  'air_conditioning',
  'alarm_systems',
  'artificial_grass',
  'bathrooms_supply_and_fitted',
  'bathrooms_supply_only',
  'bedrooms_furnitue_custom',
  'blinds_awnings_shutters',
  'boiler_swap',
  'carpets',
  'cctv',
  'central_heating_system',
  'conservatory',
  'conservatory_roof',
  'damp_proofing',
  'decking',
  'door_canopy',
  'doors',
  'driveways',
  'electrical_installation',
  'ev_charger',
  'fencing',
  'fireplaces',
  'fitted_furniture',
  'flooring_excluding_carpets',
  'garage_doors',
  'garden_buildings',
  'gates',
  'glass_splash_backs',
  'granite_worktops',
  'home_lifts',
  'home_study',
  'hot_tubs_and_spas',
  'insulation_roof_foil_wrap_only',
  'kitchens_fitted',
  'kitchens_supply_only',
  'landscaping',
  'loft_boarding',
  'loft_conversion',
  'media_walls',
  'painting_and_decorating',
  'patios',
  'plastering',
  'porch',
  'rendering',
  'resin_driveways',
  'roofing',
  'roofline',
  'security_lighting',
  'stair_lifts',
  'staircases',
  'tiling',
  'under_stair_storage',
  'underfloor_heating',
  'upvc_doors',
  'verandas',
  'wall_insulation_and_cladding',
  'wardrobes',
  'water_softeners',
  'windows',
  'windows_and_doors',
];

export const NON_HOME_IMPROVEMENT_WORKTYPES = [
  'aesthetics',
  'appliances',
  'car_body_shop',
  'car_detailing_and_alloys',
  'catering',
  'computer_builder',
  'dental',
  'electronics',
  'entertainers',
  'events',
  'experiences',
  'flowers',
  'full_events_package',
  'furniture',
  'graphic_designer',
  'high_end_clothing',
  'home_removals',
  'jewllery',
  'medical_assessments',
  'memberships',
  'mobillity_and_mobility_scooters',
  'network_engineer',
  'number_plates',
  'personal_security',
  'photography',
  'planners',
  'sports equipment',
  'technology_repair',
  'tool sales',
  'tyres',
  'vehicle_wrapping',
  'venues',
  'website_builder',
];

export const WORK_TYPE_VALIDATION = {
  required: {
    value: true,
    message: 'At least one work type is required',
  },
  minLength: {
    value: 1,
    message: 'At least one work type is required',
  },
};
