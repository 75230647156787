import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useJobContext } from 'pages/Job/context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  calculateJobTotal,
  formatLenderConfigs,
  getRateCost,
  type FormattedLenderConfig,
} from 'utils';

export type JobFinanceOptionsDisplayType = 'all' | 'selected';

export interface JobFinanceOptionsHook {
  type: JobFinanceOptionsDisplayType;
  lenderConfigs: FormattedLenderConfig | undefined;
  rates: FinanceRate[] | undefined;
  total: number;
  deposit: number;
  loan: number;
  subsidy?: number;
  payout?: number;
  classNames: {
    card: string;
    deposit: string;
  };
  acknowledged: boolean;
  showPayout: boolean;
  onAcknowledgeClick: () => void;
  isLoading: boolean;
}

export default function useJobFinanceOptions(): JobFinanceOptionsHook {
  const { job, company, isLoading } = useJobContext();

  const [acknowledged, setAcknowledged] = useState<boolean>(false);

  const type = useMemo(() => {
    if (isLoading) return 'all';
    const financeStatus = job?.finance_status;
    if (financeStatus === 'not_applied') return 'all';
    const option = job?.customer_options?.finance_option;
    return option ? 'selected' : 'all';
  }, [isLoading, job]);

  const lenderConfigs = useMemo(
    () => (company ? formatLenderConfigs(company) : undefined),
    [company],
  );

  const rates = useMemo(() => {
    if (isLoading) return [];
    if (type === 'all') return job?.finance_options;
    const option = job?.customer_options?.finance_option;
    return option ? [option] : undefined;
  }, [isLoading, type, job]);

  const total = useMemo(
    () => (job?.job_items ? calculateJobTotal(job.job_items).totalIncVat : 0),
    [job],
  );

  const deposit = useMemo(
    () =>
      job?.customer_options?.deposit_value?.amount ||
      job?.deposit_value?.amount ||
      0,
    [job],
  );

  const subsidy = useMemo(() => {
    if (type === 'all' || !rates) return undefined;
    const rate = rates[0];
    return getRateCost(rate, total, deposit);
  }, [rates, type, total, deposit]);

  const loan = useMemo(() => total - deposit, [total, deposit]);

  const payout = useMemo(
    () => (subsidy ? loan - subsidy : undefined),
    [loan, subsidy],
  );

  const higherDeposit = useMemo(() => {
    if (type === 'all') return false;
    const customerDeposit = job?.customer_options?.deposit_value?.amount;
    if (!customerDeposit) return false;
    const setDeposit = job?.deposit_value?.amount || 0;
    return customerDeposit !== setDeposit;
  }, [type, job]);

  const classNames = useMemo(
    () => ({
      card: higherDeposit && !acknowledged ? 'order-1' : 'order-3',
      deposit: higherDeposit ? 'order-1 mb-2' : 'order-3',
    }),
    [higherDeposit, acknowledged],
  );

  const jobIdDepositKey = useMemo(() => {
    const id = job?.id;
    if (!id) return undefined;
    return `job-${id}-deposit-warning-acknowledged`;
  }, [job]);

  const onAcknowledgeClick = useCallback(() => {
    setAcknowledged(true);
    if (!jobIdDepositKey) return;
    window.localStorage.setItem(jobIdDepositKey, 'true');
  }, [jobIdDepositKey]);

  const showPayout = useMemo(
    () => payout !== undefined && subsidy !== undefined,
    [payout, subsidy],
  );

  useEffect(() => {
    if (!jobIdDepositKey) return;
    const previouslyAcknowledged =
      window.localStorage.getItem(jobIdDepositKey) === 'true';
    if (!previouslyAcknowledged) return;
    setAcknowledged(true);
  }, [jobIdDepositKey]);

  return {
    type,
    lenderConfigs,
    rates,
    total,
    deposit,
    loan,
    subsidy,
    payout,
    classNames,
    acknowledged,
    onAcknowledgeClick,
    showPayout,
    isLoading,
  };
}
