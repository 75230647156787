import {
  SkeletonLoader,
  Tag,
  type TagProps,
} from '@kanda-libs/ks-design-library';
import type { EnquiryExtendedState } from 'pages/Leads/helpers';
import useLeadStatusTag from './useLeadStatusTag';

export interface LeadStatusTagProps {
  status: EnquiryExtendedState;
  archived?: boolean;
  size?: TagProps['size'];
  isLoading?: boolean;
}

const LeadStatusTag: React.FunctionComponent<LeadStatusTagProps> = function ({
  status,
  archived = false,
  size = 21,
  isLoading = false,
}) {
  const { label, color } = useLeadStatusTag(status, archived, isLoading);
  if (isLoading) return <SkeletonLoader width={100} height={size} isLoading />;
  if (!status) return null;
  return (
    <SkeletonLoader
      isLoading={isLoading}
      height={size}
      width={100}
      afterLoading={
        <Tag
          label={label}
          color={color}
          size={size}
          variant="solid"
          className="whitespace-nowrap"
        />
      }
    />
  );
};

export default LeadStatusTag;
