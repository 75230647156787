import { Text } from '@kanda-libs/ks-design-library';
import { Calculator, SubHeaderPoints, Subscriptions } from '../../components';
import { useSubscriptionContext } from '../../context';
import {
  BLACK_FRIDAY_DISCOUNT,
  REFERRED_TITLE,
  STANDARD_TITLE,
} from './constants';

const getTitle = (referred: boolean): string => {
  if (referred) return REFERRED_TITLE;
  const cutoff = new Date('2024-12-01');
  const now = new Date();
  const blackFriday = now.getTime() - cutoff.getTime() < 0;
  return blackFriday ? BLACK_FRIDAY_DISCOUNT : STANDARD_TITLE;
};

const SubscriptionsPage: React.FunctionComponent = function () {
  const { isLoading, referredByUser } = useSubscriptionContext();
  const title = getTitle(referredByUser);
  return (
    <>
      <Text
        isLoading={isLoading}
        text={title}
        className="text-24-28-em md:text-40-48-em mb-6"
      />
      <SubHeaderPoints />
      <Subscriptions />
      <Calculator />
    </>
  );
};

export default SubscriptionsPage;
