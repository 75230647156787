import { URLS } from 'config';
import useQuery from 'hooks/useQuery';
import useSearchWords from 'hooks/useSearchWords';
import type { FormattedJob } from 'pages/Jobs/helpers';
import { useMemo, type FunctionComponent, type ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import { CLASS_NAMES, type JobCardClassNames } from './constants';
import DivWrapper from './DivWrapper';
import LinkWrapper from './LinkWrapper';

export interface WrapperProps {
  children: ReactNode;
  className?: string;
  to?: string;
}

export interface JobCardHook {
  Wrapper: FunctionComponent<WrapperProps>;
  wrapperProps: Omit<WrapperProps, 'children'>;
  classNames: JobCardClassNames;
  searchWords: string[];
}

export default function useJobCard(
  job: FormattedJob,
  isLoading = false,
): JobCardHook {
  const { fetchedQuery: query } = useQuery('jobs');
  const { searchWords } = useSearchWords(query);

  const Wrapper = useMemo(
    () => (isLoading ? DivWrapper : LinkWrapper),
    [isLoading],
  );

  const wrapperProps = useMemo(() => {
    if (isLoading) return {};
    const url = job?.type?.includes('draft')
      ? URLS.createJobFromDraft
      : URLS.job;
    return {
      to: generatePath(url, { id: job.id }),
    };
  }, [isLoading, job]);

  const classNames = useMemo(
    () => ({
      ...CLASS_NAMES,
      card: [CLASS_NAMES.card, 'bg-neutral-000'].filter(Boolean).join(' '),
    }),
    [],
  );

  return {
    Wrapper,
    wrapperProps,
    classNames,
    searchWords,
  };
}
