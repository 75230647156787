import type { JobItem } from '@kanda-libs/ks-frontend-services';
import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';
import { BASE_LINE_ITEMS } from './constants';

export interface JobQuoteLineItemsHook {
  lineItems: JobItem[];
  isLoading: boolean;
}

export default function useJobQuoteLineItems(): JobQuoteLineItemsHook {
  const { job, isLoading } = useJobContext();

  const lineItems = useMemo(() => {
    if (isLoading || !job) return BASE_LINE_ITEMS;
    return job.job_items;
  }, [job, isLoading]);

  return {
    lineItems,
    isLoading,
  };
}
