import { Button, Text } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Link } from 'react-router-dom';
import { WARNING } from './constants';
import useJobFinanceApplicationProgressCardAddBankDetails from './useJobFinanceApplicationProgressCardAddBankDetails';

const JobFinanceApplicationProgressCardAddBankDetails: React.FunctionComponent =
  function ({}) {
    const { showButton, isLoading } =
      useJobFinanceApplicationProgressCardAddBankDetails();
    if (!showButton) return null;
    return (
      <div className="flex flex-col mt-2">
        <Text text={WARNING} className="text-12-18 text-neutral-700" />
        <Link to={URLS.companyInformation}>
          <Button.Text
            id="job-details-finance-progress-add-bank-details"
            label="Add bank details"
            size={40}
            className="w-full mt-2"
            variant="gradient"
            isLoading={isLoading}
          />
        </Link>
      </div>
    );
  };

export default JobFinanceApplicationProgressCardAddBankDetails;
