import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-design-library';
import { JobMenu } from 'components';
import { useJobContext } from 'pages/Job/context';
import type { MutableRefObject } from 'react';
import { JOB_SLIDE_UP_MODAL_ID } from './constants';

const JobSlideUpModal: React.FunctionComponent = function ({}) {
  const { id, status, downloadPdf } = useJobContext();
  return (
    <ModalContainer id={JOB_SLIDE_UP_MODAL_ID}>
      {({ id: modalId, handleClose, ref }) => (
        <ModalLayoutSlideUp
          id={modalId}
          onClose={handleClose}
          ref={ref as MutableRefObject<HTMLDivElement>}
          variant="MARKETING"
        >
          <JobMenu
            jobId={id}
            status={status}
            handleClose={handleClose}
            downloadPdf={downloadPdf}
          />
        </ModalLayoutSlideUp>
      )}
    </ModalContainer>
  );
};

export default JobSlideUpModal;
