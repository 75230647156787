import { Button } from '@kanda-libs/ks-design-library';
import JobSendReminderModal from './JobSendReminderModal';
import useJobSendReminderButton from './useJobSendReminderButton';

const JobSendReminderButton: React.FunctionComponent = function ({}) {
  const { show, onClick, isLoading, isSubmitting } = useJobSendReminderButton();
  if (!show) return null;
  return (
    <>
      <Button.Text
        id="job-details-send-reminder"
        onClick={onClick}
        label="Send reminder"
        size={40}
        icon="send"
        left
        className="w-full whitespace-nowrap md:mr-2"
        disabled={isLoading || isSubmitting}
      />
      <JobSendReminderModal />
    </>
  );
};

export default JobSendReminderButton;
