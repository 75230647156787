import { Icon } from '@kanda-libs/ks-design-library';
import type { ReactNode } from 'react';

export interface InfoCardProps {
  icon: string;
  title: ReactNode;
  bullets: ReactNode[];
}

const InfoCard: React.FunctionComponent<InfoCardProps> = function ({
  icon,
  title,
  bullets,
}) {
  return (
    <div className="flex flex-col border border-neutral-300 overflow-hidden rounded-lg">
      <div className="flex flex-row gap-x-3 bg-neutral-100 text-16-24-em text-neutral-900 px-6 py-3 border-b border-neutral-300">
        <Icon
          icon={icon}
          stroke="neutral-900"
          className="min-w-6 my-auto"
          size={24}
        />
        {title}
      </div>
      <div className="p-6">
        <ul className="list-disc pl-4.5 text-14-22">
          {bullets.map((p) => (
            <li key={String(p)}>{p}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default InfoCard;
