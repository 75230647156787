import { Text } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { URLS } from 'config';
import { BulletPoint } from './components';
import { BULLET_POINTS, CLASS_NAMES, MORE_RATES_LINK } from './Info-constants';
import Container from './Info-container';

export interface InfoProps {
  title?: JSX.Element | string;
}

const ViewComponent = ({ title }: InfoProps) => (
  <Container>
    {({ isLoading, label, isAdmin }) => (
      <div className={CLASS_NAMES.wrapper}>
        {title ? (
          <>{title}</>
        ) : (
          <Text
            isLoading={isLoading}
            className={CLASS_NAMES.title}
            text={label}
          />
        )}
        {isAdmin && (
          <Link
            to={URLS.financeRates}
            className="mb-4 -mt-4 text-sm text-blue-200 underline cursor-pointer"
          >
            {MORE_RATES_LINK}
          </Link>
        )}
        <div>
          {BULLET_POINTS.map((bulletTitle) => (
            <BulletPoint
              isLoading={isLoading}
              key={bulletTitle}
              title={bulletTitle}
            />
          ))}
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'PaymentAndFinanceOptions-Info-view';

export default ViewComponent;
