import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';
import { getDescription } from './helpers';

export interface JobFinanceApplicationProgressCardDescriptionHook {
  heading: string;
  subHeading: string;
  isLoading: boolean;
}

export default function useJobFinanceApplicationProgressCardDescription(): JobFinanceApplicationProgressCardDescriptionHook {
  const { isLoading, job, checkJob } = useJobContext();

  const creditStatus = useMemo(() => {
    if (!job || !checkJob) return undefined;
    if (!checkJob?.has_finance_application) return undefined;
    return checkJob?.current_status;
  }, [job, checkJob]);

  const xstatus = useMemo(() => checkJob?.xstatus, [checkJob]);

  const financeStatus = useMemo(() => {
    if (xstatus?.includes('AG_LIVE')) return 'ag_live';
    return job?.finance_status || 'not_applied';
  }, [job, xstatus]);

  const { heading, subHeading } = useMemo(
    () => getDescription(job, financeStatus, creditStatus),
    [job, financeStatus, creditStatus],
  );

  return {
    heading,
    subHeading,
    isLoading,
  };
}
