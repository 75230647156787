import type { FinanceStatus, Job } from '@kanda-libs/ks-frontend-services';
import type { ProgressStep } from 'components/ProgressIndicator';

export interface JobProgressStep {
  text: string;
  statuses: NonNullable<Job['status']>[];
  financeStatuses?: FinanceStatus[];
}

export const STEPS: JobProgressStep[] = [
  {
    text: 'Sent',
    statuses: ['sent'],
  },
  {
    text: 'Applied',
    statuses: ['accepted'],
    financeStatuses: ['applied_for_finance', 'not_applied', 'under_review'],
  },
  {
    text: 'Approved',
    statuses: ['accepted'],
    financeStatuses: ['financed'],
  },
  {
    text: 'Work Finished',
    statuses: ['accepted', 'finished'],
    financeStatuses: [
      'payout_pending',
      'sat_note_delayed',
      'sat_note_lender_review',
      'sat_note_sent',
      'sat_note_signed',
      'sat_note_viewed',
    ],
  },
  {
    text: 'Paid',
    statuses: ['finished'],
    financeStatuses: ['paid'],
  },
];

export const DECLINED_NO_FINANCE_STEPS: ProgressStep[] = [
  {
    active: false,
    text: 'Sent',
  },
  {
    active: true,
    text: 'Job Declined',
  },
];

export const DECLINED_FINANCE_STEPS: ProgressStep[] = [
  {
    active: false,
    text: 'Sent',
  },
  {
    active: false,
    text: 'Applied',
  },
  {
    active: true,
    text: 'Declined for Finance',
  },
];

export const CANCELLED_FINANCE_STEPS: ProgressStep[] = [
  {
    active: false,
    text: 'Sent',
  },
  {
    active: false,
    text: 'Applied',
  },
  {
    active: true,
    text: 'Finance Cancelled',
  },
];
