import {
  EnquiryState,
  FinanceStatus,
  Job,
  reset,
} from '@kanda-libs/ks-frontend-services';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type LeadsFilter = {
  state?: EnquiryState;
  source?: string;
  includeConverted?: boolean;
};

export type JobNonNullStatus = NonNullable<Job['status']>;

export type JobsFilter = {
  status?: JobNonNullStatus[];
  financeStatus?: FinanceStatus[];
};

export type FiltersActionPayload = {
  leads?: LeadsFilter;
  jobs?: JobsFilter;
};

export type Filters = {
  leads: LeadsFilter;
  jobs: JobsFilter;
};

export interface FilterState {
  filters: Filters;
}

export const filtersInitialState: FilterState = {
  filters: {
    leads: {
      state: undefined,
      source: 'all',
      includeConverted: true,
    },
    jobs: {
      status: undefined,
      financeStatus: undefined,
    },
  },
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState: filtersInitialState,
  reducers: {
    setFilter: (state, action: PayloadAction<FiltersActionPayload>) => {
      const { payload } = action;
      const filterKeys = Object.keys(payload) as (keyof Filters)[];
      const updatedFilters = filterKeys.reduce(
        (final: FiltersActionPayload, key: keyof Filters) => {
          const current = {
            [key]: {
              ...state.filters[key],
              ...payload[key],
            },
          };
          return {
            ...final,
            ...current,
          };
        },
        {},
      );
      return {
        ...state,
        filters: {
          ...state.filters,
          ...updatedFilters,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => ({
      ...filtersInitialState,
    }));
  },
});

export const { setFilter } = filtersSlice.actions;

export default filtersSlice.reducer;
