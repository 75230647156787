import { useCurrentUser } from '@kanda-libs/ks-frontend-services';
import { URLS } from 'config';
import { useCurrentCompany, useMe } from 'hooks';
import useQueryParam from 'hooks/useQueryParam';
import useSkipInsuranceFlag from 'hooks/useSkipInsuranceFlag';
import { useEffect, type FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';

export interface UserHasCompletedOnboardingWrapperProps {
  children: JSX.Element;
}

const UserHasCompletedOnboardingWrapper: FunctionComponent<UserHasCompletedOnboardingWrapperProps> =
  function ({ children }) {
    const pathKey = useSelector(selectors.getPathKey);
    const appIsLoading = useSelector(selectors.getIsLoading);

    const page = pathKey.pages[pathKey.page as keyof typeof pathKey.pages];

    const [pid] = useQueryParam('pid');
    const { push } = useHistory();

    const { user, isValidating: userIsValidating } = useCurrentUser();
    const { company, isLoading: companyIsLoading } = useCurrentCompany();

    const { skipInsurance } = useSkipInsuranceFlag();

    const { me } = useMe();

    const isValidating = userIsValidating || appIsLoading;
    const isLoading = !me || !user || isValidating || companyIsLoading;

    useEffect(() => {
      if (isLoading) return;
      if (me?.role === 'staff') return;
      if (window?.location?.pathname?.includes('billing')) return;

      // Page does not require auth (e.g. claim account)
      if ((page.requiredRoles || []).length === 0) return;

      if (!company) {
        if (pid !== 'null') {
          push(`${URLS.setupAccount}?pid=${pid}`);
          return;
        }
        push(URLS.setupAccount);
        return;
      }
      const lifecycle = company?.lifecycle;
      const businessConfig = company?.business_config;
      if (!lifecycle) return;

      // Page does not require auth
      if ((page.requiredRoles || []).length === 0) {
        return;
      }

      if (!businessConfig && me.role === 'company-admin') {
        push(URLS.setupBusinessConfig);
        return;
      }

      if (lifecycle === 'registered') {
        if (window?.location?.pathname?.includes('setup')) return;
        push(URLS.setupInfo);
        return;
      }

      if (['completed_setup', 'verified'].includes(lifecycle)) return;
      if (skipInsurance) return;
      if (window?.location?.pathname?.includes('onboarding')) return;
      push(URLS.onboarding);
    }, [isLoading, me, company, push, skipInsurance, pid, page.requiredRoles]);

    return children;
  };

export default UserHasCompletedOnboardingWrapper;
