import type { ProgressStep } from 'components/ProgressIndicator';
import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';
import {
  CANCELLED_FINANCE_STEPS,
  DECLINED_FINANCE_STEPS,
  DECLINED_NO_FINANCE_STEPS,
  STEPS,
} from './constants';
import { getProgressBarToShow } from './helpers';

export interface JobProgressBarHook {
  showBar: boolean;
  redBar: boolean;
  steps: ProgressStep[];
  isLoading: boolean;
}

export default function useJobProgressBar(): JobProgressBarHook {
  const { job, archived, isLoading } = useJobContext();

  const status = useMemo(() => {
    if (!job) return undefined;
    return job.status;
  }, [job]);

  const financeStatus = useMemo(() => {
    if (!job) return undefined;
    return job?.finance_status;
  }, [job]);

  const progressBarToShow = useMemo(() => {
    if (isLoading) return 'standard';
    if (!status || archived) return undefined;
    return getProgressBarToShow(status, financeStatus);
  }, [isLoading, status, financeStatus, archived]);

  const showBar = useMemo(
    () => Boolean(progressBarToShow),
    [progressBarToShow],
  );

  const redBar = useMemo(
    () => progressBarToShow !== 'standard',
    [progressBarToShow],
  );

  const steps = useMemo(() => {
    if (progressBarToShow === 'declined_no_finance')
      return DECLINED_NO_FINANCE_STEPS;
    if (progressBarToShow === 'declined_finance') return DECLINED_FINANCE_STEPS;
    if (progressBarToShow === 'cancelled_finance')
      return CANCELLED_FINANCE_STEPS;
    return STEPS.map(({ text, statuses, financeStatuses }) => {
      const inStatus = Boolean(status && statuses.includes(status));
      const inFinanceStatus = financeStatuses
        ? Boolean(financeStatus && financeStatuses.includes(financeStatus))
        : true;
      return {
        text,
        active: inStatus && inFinanceStatus,
      };
    });
  }, [progressBarToShow, status, financeStatus]);

  return {
    showBar,
    redBar,
    steps,
    isLoading,
  };
}
