import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useMemo, useState } from 'react';
import { scrollToTop } from 'utils';
import { useSubscriptionContext } from '../../context';
import { NEED_BULLETS } from './constants';

export interface IwocaPayHook {
  name: string;
  isSubmitting: boolean;
  onClick: () => void;
  onBackClick: () => void;
  needBullets: string[];
}

export default function useIwocaPay(): IwocaPayHook {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { setFormDisplay } = useSubscriptionContext();
  const { company } = useCurrentCompany();
  const dispatch = useAppDispatch();
  const onError = useApiError('There was an error creating the subscription');
  const { showError } = useToast();

  const name = useMemo(() => {
    if (!company) return 'this company';
    return (
      company?.contact_info?.trading_name ||
      company?.limited_company_info?.company_name ||
      company?.sole_trader_info?.trading_name ||
      'this company'
    );
  }, [company]);

  const needBullets = useMemo(
    () => NEED_BULLETS.map((b) => b.replace('{{name}}', name)),
    [name],
  );

  const onBackClick = useCallback(() => {
    scrollToTop();
    setFormDisplay('subscriptions');
  }, [setFormDisplay]);

  const onClick = useCallback(() => {
    const id = company?.id;
    if (isSubmitting || !id) return;
    setIsSubmitting(true);
    dispatch(
      actions.postCompanyBilling({
        params: {
          id,
          provider: 'iwoca',
        },
        onError: (error) => {
          setIsSubmitting(false);
          onError(error);
        },
        onSuccess: (data) => {
          setIsSubmitting(false);
          const url = data?.checkout_url;
          if (!url) {
            showError(
              'Authorisation URL missing from response - please contact Kanda',
            );
            return;
          }
          window.location.href = url;
        },
      }),
    );
  }, [company, isSubmitting, setIsSubmitting, dispatch, onError, showError]);

  return {
    name,
    isSubmitting,
    onClick,
    onBackClick,
    needBullets,
  };
}
