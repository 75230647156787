export const NEED_BULLETS = [
  'You must be a director of {{name}}',
  'One form of photographic ID (drivers license or passport)',
  'Debit card for repayments',
];

export const SETUP_BULLETS = [
  'Submit details about your business',
  'Complete an ID check',
  'Connect a debit card',
  <span key="debit">
    First payment will debit in <b>30 days</b>
  </span>,
];
