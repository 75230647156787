import type { JobItem } from '@kanda-libs/ks-frontend-services';

const BASE_LINE_ITEM: Omit<JobItem, 'title'> = {
  quantity: 100,
  price: { amount: 0, currency: 'GBP' },
  vat_rate: 'vat_not_set',
  vat: { amount: 0, currency: 'GBP' },
  style: 'kanda',
};

export const BASE_LINE_ITEMS: JobItem[] = [
  {
    title: 'Placeholder 1',
    ...BASE_LINE_ITEM,
  },
  {
    title: 'Placeholder 2',
    ...BASE_LINE_ITEM,
  },
  {
    title: 'Placeholder 3',
    ...BASE_LINE_ITEM,
  },
];
