import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import JobAddBankDetailsModal from './components/JobAddBankDetailsModal';
import JobMarkAsFinishedModal from './components/JobMarkAsFinishedModal';
import JobProvider from './context';
import JobDesktop from './Desktop';
import JobMobile from './Mobile';

const Job: React.FunctionComponent = function () {
  return (
    <JobProvider>
      <Helmet title="Job Details" />
      <BreakPoints mobile={<JobMobile />} desktop={<JobDesktop />} />
      <JobAddBankDetailsModal />
      <JobMarkAsFinishedModal />
    </JobProvider>
  );
};

export default Job;
