import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMyAccountCompanyInformationContext } from '../../context';
import { getVatFormBody } from './helpers';

export interface VatFormValues {
  vat_number: string | undefined;
}

export type VatFormVariant = 'loading' | 'sole' | 'limited';

export interface VatFormHook {
  form: UseFormReturn<VatFormValues>;
  onSubmit: (formValues: StringIndexedObject<VatFormValues>) => void;
  isLoading: boolean;
  isSubmitting: boolean;
}

export default function useVatForm(): VatFormHook {
  const { company, isLoading, isSubmitting } =
    useMyAccountCompanyInformationContext();
  const dispatch = useAppDispatch();
  const onError = useApiError('Error updating VAT number at this time');
  const { showSuccess } = useToast();

  const defaultValues = useMemo(() => {
    if (isLoading || !company)
      return {
        vat_number: undefined,
      };
    const limited = company?.company_type === 'limited_company';
    const vatNumber = limited
      ? company?.limited_company_info?.vat_number
      : company?.sole_trader_info?.vat_number;
    return {
      vat_number: vatNumber,
    };
  }, [isLoading, company]);

  const form = useForm<VatFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<VatFormValues>) => {
      const fv = formValues as unknown as VatFormValues;
      if (!company || !company.id) return;
      const { id } = company;
      const body = getVatFormBody(company, fv);
      if (!body) return;
      dispatch(
        actions.putCompany({
          params: { id },
          body,
          onError,
          onSuccess: () => {
            showSuccess('VAT number updated');
          },
        }),
      );
    },
    [company, dispatch, onError, showSuccess],
  );

  const initRef = useRef<boolean>(false);
  useEffect(() => {
    if (initRef.current || !company || isLoading) return;
    initRef.current = true;
    form.reset(defaultValues);
  }, [company, isLoading, defaultValues, form]);

  return {
    form,
    onSubmit,
    isLoading,
    isSubmitting,
  };
}
