import {
  SkeletonLoader,
  Tag,
  type SkeletonLoaderProps,
  type TagProps,
} from '@kanda-libs/ks-design-library';
import { BASE_SKELETON_PROPS } from './constants';

export interface JobExampleTagProps {
  size?: TagProps['size'];
  isLoading?: boolean;
  skeletonProps?: SkeletonLoaderProps;
}

const JobExampleTag: React.FunctionComponent<JobExampleTagProps> = function ({
  isLoading = false,
  size = 21,
  skeletonProps,
}) {
  return (
    <SkeletonLoader
      {...{
        ...(skeletonProps || {}),
        ...BASE_SKELETON_PROPS,
      }}
      isLoading={isLoading}
      afterLoading={
        <Tag
          label="Example"
          variant="solid"
          size={size}
          color="violet"
          className="my-auto md:ml-0"
        />
      }
    />
  );
};

export default JobExampleTag;
