import ManualField from './ManualField';
import Postcode from './Postcode';
import Select from './Select';

const Widgets = {
  ManualField,
  Postcode,
  Select,
};

export default Widgets;
