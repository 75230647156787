import type { FinanceStatus, Job } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { getSuffix } from './helpers';

export interface JobActivityHook {
  suffix: string;
  className: string;
}

export default function useJobActivity(
  status: Job['status'] | undefined,
  financeStatus: FinanceStatus | undefined,
  textClassName?: string,
): JobActivityHook {
  const className = useMemo(
    () =>
      [textClassName || 'text-16-20 text-neutral-900', 'text-left mr-1'].join(
        ' ',
      ),
    [textClassName],
  );

  const suffix = useMemo(
    () => getSuffix(status, financeStatus),
    [status, financeStatus],
  );

  return { className, suffix };
}
