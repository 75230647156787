import { Icon, SkeletonLoader, Text } from '@kanda-libs/ks-design-library';
import useLogo from './useLogo';

const Logo: React.FunctionComponent = function ({}) {
  const { isLoading, dataUrl, alt, onRemoveClicked } = useLogo();
  return (
    <div className="flex flex-col">
      <Text
        text="Company Logo"
        isLoading={isLoading}
        className="text-14-22-em mb-3"
        skeletonProps={{
          width: 100,
        }}
      />
      <div className="rounded rounded-xl border border-green-500 p-6">
        <SkeletonLoader
          isLoading={isLoading}
          width={150}
          height={100}
          afterLoading={
            <div className="flex flex-row justify-between">
              <img src={dataUrl} alt={alt} className="w-28" />
              <button
                type="button"
                className="my-auto w-6 h-6 bg-neutral-600 rounded-full"
                onClick={onRemoveClicked}
              >
                <Icon
                  icon="close"
                  stroke="neutral-000"
                  size={12}
                  className="m-auto"
                />
                <p className="hidden">close-logo</p>
              </button>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Logo;
