export type Headings = {
  heading: string;
  subHeading: string;
};

export const HEADINGS: Record<string, Headings> = {
  not_applied: {
    heading: '',
    subHeading: '',
  },
  under_review: {
    heading: 'Finance application ongoing',
    subHeading:
      "We'll keep you updated via email. Applications can take a couple of minutes to process.",
  },
  applied_for_finance: {
    heading: 'Finance application ongoing',
    subHeading:
      "We'll keep you updated via email. Applications can take a couple of minutes to process.",
  },
  finance_deposit_paid: {
    heading: 'Finance application ongoing',
    subHeading:
      "We'll keep you updated via email. Applications can take a couple of minutes to process.",
  },
  finance_not_approved: {
    heading: 'Finance application not approved',
    subHeading:
      'The customer is not elligble for finance according to the lender: Omni Capital Retail Finance.',
  },
  financed: {
    heading: 'Finance application approved',
    subHeading: "The work's ready to begin.",
  },
  sat_note_sent: {
    heading: "We've sent your customer a satisfaction note to sign",
    subHeading: "When the customer has signed off on the work you'll get paid.",
  },
  sat_note_viewed: {
    heading:
      "We've sent your customer a satisfaction note to sign and they have viewed it, but not yet signed it",
    subHeading: "When the customer has signed off on the work you'll get paid.",
  },
  sat_note_signed: {
    heading: 'Satisfaction note in review',
    subHeading: "We're reviewing the satisfaction note now it's been signed",
  },
  sat_note_delayed: {
    heading: 'Satisfaction note delayed',
    subHeading:
      "Your satisfaction note review is taking longer than usual as we can't contact your customer or they've raised an issue with the work. Please let them know we're trying to contact them.",
  },
  sat_note_lender_review: {
    heading: 'Satisfaction note with lender',
    subHeading:
      "Your customer's satisfaction has been reviewed by Kanda, and is now with the lender for their diligence.",
  },
  ag_live: {
    heading: 'This quote will be paid out soon',
    subHeading:
      'Your customer has completed their welcome call and their credit agreement has been made live.',
  },
  payout_pending: {
    heading: 'This quote will be paid out soon',
    subHeading:
      "We've approved the sat note and payment will be made to your bank account soon",
  },
  paid: {
    heading: 'This quote has been paid out',
    subHeading:
      "We've approved the sat note and payment has been made - you'll see it shortly.",
  },
  finance_cancelled: {
    heading: 'Finance application canceled',
    subHeading:
      'This finance application has been canceled. Please contact Kanda for more details.',
  },
};
