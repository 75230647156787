import type {
  TableHeaderColumn,
  TableProps,
} from '@kanda-libs/ks-component-ts';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { useJobsContext } from 'pages/Jobs/context';
import type { FormattedJob } from 'pages/Jobs/helpers';
import { useCallback, useEffect, useRef } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { scrollToTop } from 'utils';
import { TABLE_COLUMNS } from './constants';

export interface JobsTableHook {
  tableProps: TableProps;
}

export default function useJobsTable(): JobsTableHook {
  const {
    data,
    onAction,
    pageIndex,
    perPage,
    totalPages,
    query,
    setPage,
    numItems,
    isLoading,
  } = useJobsContext();

  const { push } = useHistory();

  const onRowClicked = useCallback(
    (row?: StringIndexedObject) => {
      if (!row) return;
      const original = row?.original as FormattedJob;
      if (!original) return;
      const { id, status } = original;
      if (!status) return;
      const url = status === 'draft' ? URLS.createJobFromDraft : URLS.job;
      scrollToTop();
      push(generatePath(url, { id }));
    },
    [push],
  );

  const queryRef = useRef<string>(query);
  // Effect resets page index to 0 when query changes - when user searches
  useEffect(() => {
    if (queryRef.current && queryRef.current === query) return;
    setPage(0);
  }, [query, setPage]);

  useEffect(() => {
    if (numItems === 0) {
      setPage(0);
      return;
    }
    const maxPage = Math.ceil(numItems / perPage);
    if (pageIndex <= maxPage - 1) return;
    setPage(maxPage - 1);
  }, [setPage, numItems, pageIndex, perPage]);

  return {
    tableProps: {
      data,
      onRowClicked,
      pageIndex,
      totalPages,
      setPage,
      columns: TABLE_COLUMNS as unknown as TableHeaderColumn[],
      onAction,
      isLoading,
    },
  };
}
