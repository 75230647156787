import { Price, Text } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES, PRICE_PROPS, TOTAL_PROPS } from './constants';
import useJobQuoteTotals from './useJobQuoteTotals';

const JobQuoteTotals: React.FunctionComponent = function () {
  const { totals, deposit, isLoading } = useJobQuoteTotals();
  return (
    <div className={CLASS_NAMES.container}>
      <Text
        className={CLASS_NAMES.title}
        text="Total exc. VAT"
        isLoading={isLoading}
      />
      <Price
        amount={totals.totalExcVat}
        {...PRICE_PROPS}
        isLoading={isLoading}
      />
      <Text className={CLASS_NAMES.title} text="VAT" isLoading={isLoading} />
      <Price amount={totals.totalVat} {...PRICE_PROPS} isLoading={isLoading} />
      <div className={CLASS_NAMES.border} />
      <Text
        className={CLASS_NAMES.title}
        text="Total inc. VAT"
        isLoading={isLoading}
      />
      <Price
        amount={totals.totalIncVat}
        {...TOTAL_PROPS}
        isLoading={isLoading}
      />
      {deposit && (
        <>
          <div className={CLASS_NAMES.border} />
          <Text
            className={CLASS_NAMES.title}
            text="Minimum Deposit"
            isLoading={isLoading}
          />
          <Price amount={deposit} {...TOTAL_PROPS} isLoading={isLoading} />
        </>
      )}
    </div>
  );
};

export default JobQuoteTotals;
