import { Field, Form, FormTheme } from '@kanda-libs/ks-component-ts';
import { Button, Text } from '@kanda-libs/ks-design-library';
import useVatNumberValidation from 'hooks/useVatNumberValidation';
import useVatForm from './useVatForm';

const VatForm: React.FunctionComponent = function ({}) {
  const { form, onSubmit, isLoading, isSubmitting } = useVatForm();
  const vatNumberValidation = useVatNumberValidation();
  return (
    <FormTheme variant="streamline">
      <Form
        form={form}
        onSubmit={onSubmit}
        id="my-account-company-information-vat-number-form"
      >
        <div className="flex flex-col">
          <Text
            text="VAT Number"
            className="text-14-22-em text-neutral-900 mb-4"
            isLoading={isLoading}
          />
          <Field.Validator validation={vatNumberValidation}>
            <Field.Input name="vat_number" isLoading={isLoading} />
          </Field.Validator>
          <Button.Text
            submit
            id="my-account-company-information-vat-number-submit"
            label="Update VAT Number"
            size={40}
            className="ml-auto"
            disabled={isSubmitting}
          />
        </div>
      </Form>
    </FormTheme>
  );
};

export default VatForm;
