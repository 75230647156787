import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import JobsProvider from './context';
import JobsDesktop from './Desktop';
import JobsMobile from './Mobile';

const Jobs: React.FunctionComponent = function ({}) {
  return (
    <JobsProvider>
      <Helmet title="Jobs" />
      <BreakPoints mobile={<JobsMobile />} desktop={<JobsDesktop />} />
    </JobsProvider>
  );
};

export default Jobs;
