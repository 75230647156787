import {
  SkeletonLoader,
  StatusTag,
  type SkeletonLoaderProps,
  type StatusTagStatus,
  type TagProps,
} from '@kanda-libs/ks-design-library';
import { BASE_SKELETON_PROPS } from './constants';

export interface JobStatusProps {
  status: StatusTagStatus;
  archived?: boolean;
  size?: TagProps['size'];
  isLoading?: boolean;
  timestamp?: Date;
  skeletonProps?: SkeletonLoaderProps;
}

const JobStatus: React.FunctionComponent<JobStatusProps> = function ({
  status,
  archived = false,
  isLoading = false,
  size = 21,
  timestamp,
  skeletonProps,
}) {
  return (
    <SkeletonLoader
      {...(skeletonProps || BASE_SKELETON_PROPS)}
      isLoading={isLoading}
      afterLoading={
        <StatusTag
          status={(archived ? 'archived' : status) as StatusTagStatus}
          timestamp={timestamp ? timestamp.getTime() : undefined}
          size={size}
          variant="solid"
        />
      }
    />
  );
};

export default JobStatus;
