import JobCombinedStatus from 'pages/Jobs/components/JobCombinedStatus';
import JobPrice from 'pages/Jobs/components/JobPrice';
import useJobStatusBar from './useJobStatusBar';

const JobStatusBar: React.FunctionComponent = function ({}) {
  const {
    price,
    status,
    financeStatus,
    archive,
    example,
    isLoading,
    isDesktop,
  } = useJobStatusBar();
  return (
    <div className="flex flex-row justify-between md:jsutify-start flex-wrap gap-y-1 gap-x-1 -mt-2 md:mt-0 -ml-1 md:ml-0">
      <JobCombinedStatus
        financeStatus={financeStatus}
        status={status}
        archived={archive}
        example={example}
        isLoading={isLoading}
        size={isDesktop ? 28 : 21}
      />
      <JobPrice
        price={price}
        isLoading={isLoading}
        priceClassName="text-20-24-em ml-1"
      />
    </div>
  );
};

export default JobStatusBar;
