import { pipe } from 'fp-ts/lib/function';
import { useMemo } from 'react';
import {
  filterForUnregulatedRates,
  groupByAprValuesAndFee,
  type GroupedByAprAndFee,
} from 'utils';
import { useFinanceOptionsContext } from '../context';

export interface UnregulatedRatesHook {
  rates: GroupedByAprAndFee | undefined;
  toggleable: boolean;
  hasRates: boolean;
  hideNoRates: boolean;
}

export default function useUnregulatedRates(): UnregulatedRatesHook {
  const {
    rates: allRates,
    toggleable,
    hideNoRates,
  } = useFinanceOptionsContext();

  const rates = useMemo(() => {
    if (!allRates) return undefined;
    return pipe(allRates, filterForUnregulatedRates, groupByAprValuesAndFee);
  }, [allRates]);

  const hasRates = useMemo(() => {
    if (!rates) return false;
    return Object.keys(rates).length > 0;
  }, [rates]);

  return {
    rates,
    toggleable,
    hasRates,
    hideNoRates,
  };
}
