import type { FormattedJob } from 'pages/Jobs/helpers';

export const CLASS_NAMES = {
  container: 'flex flex-col',
  list: 'flex flex-col gap-y-3 -mx-4',
  pagination: 'flex flex-row mb-4 justify-center',
  link: 'border-b border-solid border-neutral-200 last:border-0',
  cardPreview: 'bg-violet-100',
};

const BASE_SKELETON_JOB: FormattedJob = {
  id: '',
  status: 'sent',
  financeStatus: 'not_applied',
  updatedAt: new Date(),
  firstName: 'first',
  lastName: 'last',
  email: 'example@example.com',
  phone: '+447123456789',
  address: '123 example strees, postcode',
  title: 'New Job',
  price: 100000,
  type: ['sent'],
  flowType: 'live',
};

export const SKELETON_JOBS: FormattedJob[] = [
  { ...BASE_SKELETON_JOB, id: 'temp-1' },
  { ...BASE_SKELETON_JOB, id: 'temp-2' },
  { ...BASE_SKELETON_JOB, id: 'temp-3' },
];
