import type { ValidationItems } from '@kanda-libs/ks-component-ts';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import get from 'lodash.get';

const flattenObject = (obj) =>
  Object.keys(obj).reduce((flat, val) => {
    if (typeof obj[val] === 'string')
      return {
        ...flat,
        [val]: obj[val],
      };
    return {
      ...flat,
      ...flattenObject(obj[val]),
    };
  }, {});

export const valueIsNotBlank = (value?: string) =>
  value && value.trim().length > 0;

export const getValidationConditions = (
  validation: ValidationItems,
): ValidationItems =>
  Object.keys(validation).reduce(
    (validators, key) => ({
      ...validators,
      [key]: validation[key].value,
    }),
    {},
  );

export const getValidationErrors = (
  validation: ValidationItems,
): ValidationItems =>
  Object.keys(validation).reduce((validators, key) => {
    if (typeof validation[key].message === 'string')
      return {
        ...validators,
        [key]: validation[key].message,
      };
    const messages = flattenObject(validation[key].message);
    return {
      ...validators,
      ...messages,
    };
  }, {});

export const validateAddressSelect = (
  formValues: StringIndexedObject,
  addressFieldName: string,
  isArray = false,
  index: number | undefined = undefined,
): boolean => {
  const formattedName = isArray ? `company.users.${index}` : addressFieldName;
  if (!addressFieldName) return false;
  const address = get(formValues, formattedName);
  if (!address) return false;
  const line1 = address?.line_1;
  const country = address?.country;
  const postcode = address?.postcode;
  const city = address?.city;
  if (
    !valueIsNotBlank(line1) ||
    !valueIsNotBlank(country) ||
    !valueIsNotBlank(postcode) ||
    !valueIsNotBlank(city)
  )
    return false;
  return true;
};
