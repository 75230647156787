import { Field, FormTheme } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import AddressFields from '../AddressFields';
import CompanyName from '../CompanyName';
import SwitchCompanyTypeButton from '../SwitchCompanyTypeButton';
import { COMPANY_NAME_PROPS } from './constants';
import NoCompanyFoundFields from './NoCompanyFoundFields';
import useLimitedCompanyFields from './useLimitedCompanyFields';

interface LimitedCompanyFieldsProps {
  schemaError: boolean;
  showToggleButton?: boolean;
}

const LimitedCompanyFields: React.FunctionComponent<LimitedCompanyFieldsProps> =
  function ({ schemaError, showToggleButton = true }) {
    const { showButton, noCompany, noCompanyCallback, searchCompany } =
      useLimitedCompanyFields();
    return (
      <FormTheme variant="streamline">
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row w-full">
            {!noCompany ? (
              <>
                <div className="flex-1">
                  <CompanyName noCompanyCallback={noCompanyCallback} />
                </div>
                {!showButton && showToggleButton && <SwitchCompanyTypeButton />}
              </>
            ) : (
              <>
                <div className="flex-1">
                  <Field.Input {...COMPANY_NAME_PROPS} />
                </div>
                <Button.Text
                  id="enable-search-company"
                  size={32}
                  variant="outline"
                  label="Search Company"
                  className="mt-0 mb-4 md:ml-3 md:mt-7 md:mb-0"
                  onClick={searchCompany}
                />
              </>
            )}
          </div>
          {noCompany || schemaError ? (
            <>
              <NoCompanyFoundFields />
              <AddressFields companyType="limited_company" />
            </>
          ) : (
            <p className="text-neutral-600 text-12-18">
              Struggling to find your company? Try searching by company number.
            </p>
          )}
        </div>
      </FormTheme>
    );
  };

export default LimitedCompanyFields;
