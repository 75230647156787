import type { Company } from '@kanda-libs/ks-frontend-services';
import type { VatFormValues } from './useVatForm';

export const getVatFormBody = (
  company: Company,
  formValues: VatFormValues,
): Company | undefined => {
  const limited = company.company_type === 'limited_company';
  if (limited) {
    const limitedInfo = company?.limited_company_info;
    if (!limitedInfo) return undefined;
    return {
      ...company,
      limited_company_info: {
        ...limitedInfo,
        vat_number: formValues.vat_number,
      },
    };
  }
  const soleInfo = company?.sole_trader_info;
  if (!soleInfo) return undefined;
  return {
    ...company,
    sole_trader_info: {
      ...soleInfo,
      vat_number: formValues.vat_number,
    },
  };
};
