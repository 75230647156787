import { useMemo, type FunctionComponent } from 'react';
import JobsNoResults from '../components/JobsNoResults';
import JobsPlaceholder from '../components/JobsPlaceholder';
import { useJobsContext } from '../context';
import JobsList from './JobsList';

export interface JobsMobileHook {
  archive: boolean;
  Content: FunctionComponent;
}

export default function useJobsMobile(): JobsMobileHook {
  const { archive, data, noJobs, isLoading } = useJobsContext();

  const Content = useMemo(() => {
    if (isLoading) return JobsList;
    if (noJobs) return JobsPlaceholder;
    if (data.length === 0 && !noJobs) return JobsNoResults;
    return JobsList;
  }, [isLoading, noJobs, data]);

  return { Content, archive };
}
