import { Form } from '@kanda-libs/ks-component-ts';
import CompanyDetailsFormLimited from './Limited';
import CompanyDetailsFormSole from './Sole';
import useCompanyDetailsForm from './useCompanyDetailsForm';

const CompanyDetailsForm: React.FunctionComponent = function ({}) {
  const { form, onSubmit, isLoading, isLimitedCompany, schemaError } =
    useCompanyDetailsForm();
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      id="my-account-company-information-company-details-form"
    >
      <div className="flex flex-col gap-y-4">
        {isLimitedCompany ? (
          <CompanyDetailsFormLimited
            isLoading={isLoading}
            schemaError={schemaError}
          />
        ) : (
          <CompanyDetailsFormSole isLoading={isLoading} />
        )}
      </div>
    </Form>
  );
};

export default CompanyDetailsForm;
