import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';

export type JobFinanceOptionsDisplayType = 'all' | 'selected';

export interface JobFinanceDepositChosenHook {
  setDeposit: number;
  chosenDeposit: number;
  difference: number;
  higher: boolean;
}

export default function useJobFinanceDepositChosen(): JobFinanceDepositChosenHook {
  const { job } = useJobContext();

  const setDeposit = useMemo(() => job?.deposit_value?.amount || 0, [job]);

  const chosenDeposit = useMemo(
    () => job?.customer_options?.deposit_value?.amount || 0,
    [job],
  );

  const difference = useMemo(
    () => chosenDeposit - setDeposit,
    [chosenDeposit, setDeposit],
  );

  const higher = useMemo(() => difference > 0, [difference]);

  return {
    setDeposit,
    chosenDeposit,
    difference,
    higher,
  };
}
