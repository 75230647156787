import { useMemo } from 'react';
import type { SectorProps } from '../../useWorkTypesForm';

const Sector: React.FunctionComponent<SectorProps> = function ({
  label,
  onClick,
  active,
}) {
  const className = useMemo(
    () =>
      [
        'py-1.5 px-4 rounded-full border text-14-22-em',
        active
          ? 'border-neutral-900 bg-neutral-900 text-neutral-000'
          : 'border-neutral-300 bg-neutral-000 hover:bg-neutral-100 text-neutral-900',
      ].join(' '),
    [active],
  );

  return (
    <button
      id={label.replace(' g', '_')}
      className={className}
      onClick={onClick}
      type="button"
    >
      {label}
    </button>
  );
};

export default Sector;
