import { Widget } from '@kanda-libs/ks-component-ts';
import { LOGO_DOCUMENT_PROPS } from './constants';

const LogoInput: React.FunctionComponent = function ({}) {
  return (
    <Widget.CompanyCompanyLogoContent
      {...LOGO_DOCUMENT_PROPS}
      label={
        <p className="text-14-22-em mb-1 text-neutral-900">Company Logo</p>
      }
      placeholder={
        <div className="flex flex-row my-auto">
          <span className="mr-1 text-green-400 text-14-22-em">
            Click to upload file
          </span>
        </div>
      }
    />
  );
};

export default LogoInput;
