import { Price, Text } from '@kanda-libs/ks-design-library';
import FinanceOptions from 'components/FinanceOptions';
import InfoCard from 'components/InfoCard';
import JobFinanceDepositChosen from './JobFinanceDepositChosen';
import useJobFinanceOptions from './useJobFinanceOptions';

const JobFinanceOptions: React.FunctionComponent = function ({}) {
  const {
    type,
    isLoading,
    classNames,
    deposit,
    loan,
    subsidy,
    payout,
    onAcknowledgeClick,
    showPayout,
    acknowledged,
    ...rest
  } = useJobFinanceOptions();
  return (
    <div className={classNames.card}>
      <InfoCard
        title={type === 'all' ? 'Finance Options' : 'Chosen Option'}
        isLoading={isLoading}
      >
        <div className="order-2">
          <FinanceOptions
            {...rest}
            deposit={deposit}
            showHandle={false}
            hideBox
            hideNoRates
            hideSubtitle
            hideUnavailable
            ignoreConfigs
          />
        </div>
        <div className={classNames.deposit}>
          {type === 'selected' && (
            <JobFinanceDepositChosen
              acknowledged={acknowledged}
              onAcknowledgeClick={onAcknowledgeClick}
            />
          )}
        </div>

        <div className="grid grid-cols-[min-content_auto] order-5 text-14-22-em gap-x-2 text-neutral-700">
          <Text className="my-auto" text="Deposit:" isLoading={isLoading} />
          <Price
            amount={deposit}
            color="green-600"
            poundsClassName="text-16-20-em"
            centsClassName="text-16-20-em"
            className="ml-auto"
            isLoading={isLoading}
          />
          {!isLoading && showPayout && (
            <>
              <div className="col-span-2 border-b border-neutral-400 mb-2" />
              <p className="my-auto">Loan:</p>
              <Price
                amount={loan}
                color="neutral-700"
                poundsClassName="text-16-20-em"
                centsClassName="text-16-20-em"
                className="ml-auto"
              />
              <p className="my-auto">Subsidy:</p>
              <Price
                amount={subsidy}
                color="neutral-700"
                poundsClassName="text-16-20-em"
                centsClassName="text-16-20-em"
                className="ml-auto"
              />
              <div className="col-span-2 border-b border-neutral-400" />
              <p className="my-auto">Payout:</p>
              <Price
                amount={payout}
                color="green-600"
                poundsClassName="text-16-20-em"
                centsClassName="text-16-20-em"
                className="ml-auto"
              />
            </>
          )}
        </div>
      </InfoCard>
    </div>
  );
};

export default JobFinanceOptions;
