import { Widget } from '@kanda-libs/ks-component-ts';
import { useMemo } from 'react';

import { type AddressFieldsLabels } from '../..';
import { useAddressFieldsContext } from '../../context';
import { getFieldWidgetTag } from '../../helpers';
import { type AddressManualFieldNames } from './constants';

export interface AddressFieldsManualFieldWidgetHook {
  widgetName: keyof typeof Widget;
  label?: string;
}

export default function useManualFieldWidget(
  name: AddressManualFieldNames,
): AddressFieldsManualFieldWidgetHook {
  const { widgetPrefix, labels, showLabels } = useAddressFieldsContext();
  const widgetName = useMemo(
    () => getFieldWidgetTag(name, widgetPrefix),
    [widgetPrefix, name],
  );
  const label = showLabels ? labels?.[name as keyof AddressFieldsLabels] : '';
  return {
    widgetName,
    label,
  };
}
