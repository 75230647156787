import { CopyContent } from '@kanda-libs/ks-design-library';
import type { Tag } from '@kanda-libs/ks-frontend-services';
import TagBanner from './TagBanner';
import useTag from './useTag';

export interface MyAccountFinanceEverywhereLinkTagProps {
  tag: Tag;
  index: number;
  logoBase64?: string;
}

const MyAccountFinanceEverywhereLinkTag: React.FunctionComponent<MyAccountFinanceEverywhereLinkTagProps> =
  function ({ tag, index, logoBase64 }) {
    const { url, id } = useTag(tag);
    return (
      <div className="w-full bg-neutral-000 border border-neutral-300 px-4 py-5 rounded-lg flex flex-col shadow-card">
        <p className="text-16-20-em text-neutral-900 mb-3">Link {index}</p>
        <div className="flex flex-col gap-x-2 gap-y-1">
          <div className="flex flex-row gap-x-6">
            <p className="text-14-22-em">URL:</p>
            <div className="flex flex-col">
              <p className="text-14-22">{url}</p>
              <CopyContent
                className="mr-auto"
                content={url}
                clickedView={
                  <p className="text-14-22-em text-green-600">
                    URL copied to clipboard
                  </p>
                }
              >
                <p className="text-14-22-em text-green-600 underline">
                  Click here to copy URL
                </p>
              </CopyContent>
            </div>
          </div>
          <div className="flex flex-row gap-x-3">
            <p className="text-14-22-em">Code share location:</p>
            <p className="text-14-22">{tag.source}</p>
          </div>

          <p className="text-14-22-em col-span-2">QR Code/Banner:</p>
          {logoBase64 && (
            <TagBanner url={url} id={id} logoBase64={logoBase64} />
          )}
        </div>
      </div>
    );
  };

export default MyAccountFinanceEverywhereLinkTag;
