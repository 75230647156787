import {
  Cache,
  type BankAccount,
  type Document,
} from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { PC_ENABLE_CACHE_KEY } from '../PremiumCreditToggle/constants';
// import { TEST_CONTRACT } from './constants';

export interface SubscriptionProviderProps {
  children: ReactNode;
}

export type Display = 'subscriptions' | 'form' | 'contract' | 'iwoca';

export interface Contract {
  content: string;
  name: string;
  signee?: string;
  timestamp?: number;
}

export interface SubscriptionContextType {
  isLoading: boolean;
  cache: Cache | undefined;
  premiumCreditEnable: boolean;
  display: Display;
  setFormDisplay: (newDisplay: Display) => void;
  contract: Contract | undefined;
  setContract: (contract: Contract) => void;
  bankAccount: BankAccount | undefined;
  setBankAccount: (bank: BankAccount) => void;
  referredByUser: boolean;
}

export const SubscriptionContext = createContext<SubscriptionContextType>({
  isLoading: false,
  cache: undefined,
  premiumCreditEnable: false,
  display: 'subscriptions',
  setFormDisplay: () => {},
  contract: undefined,
  setContract: () => {},
  bankAccount: undefined,
  setBankAccount: () => {},
  referredByUser: false,
});

export const useSubscriptionContext = () => useContext(SubscriptionContext);

const SubscriptionProvider: FunctionComponent<SubscriptionProviderProps> =
  function ({ children }) {
    const [display, setDisplay] = useState<Display>('subscriptions');
    const [contract, _setContract] = useState<Contract | undefined>(undefined);
    const [bankAccount, _setBankAccount] = useState<BankAccount | undefined>(
      undefined,
    );

    const globalIsLoading = useSelector(selectors.getIsLoading);
    const { company, isLoading: companyIsLoading } = useCurrentCompany();
    const documents = useSelector(selectors.document.getEntitiesAsArray);
    const caches = useSelector(selectors.infoGetCache.getEntities);
    const me = useSelector(selectors.getAuth);

    const cache = useMemo(() => {
      const id = me.user?.id;
      if (!id) return undefined;
      return (caches?.[id] || {}) as Cache;
    }, [me, caches]);

    const isLoading = useMemo(
      () => globalIsLoading || companyIsLoading || !cache,
      [globalIsLoading, companyIsLoading, cache],
    );

    const referredByUser = useMemo(
      () => company?.referred_by === 'kanda-referrals',
      [company],
    );

    const setFormDisplay = useCallback(
      (newDisplay: Display) => setDisplay(newDisplay),
      [],
    );

    const setContract = useCallback(
      (newContract: Contract) => _setContract(newContract),
      [],
    );

    const setBankAccount = useCallback(
      (bank: BankAccount) => _setBankAccount(bank),
      [],
    );

    const premiumCreditEnable = useMemo(() => {
      if (!cache) return false;
      return (
        (cache as unknown as Record<string, string>)?.[PC_ENABLE_CACHE_KEY] ===
        'true'
      );
    }, [cache]);

    useEffect(() => {
      if (isLoading) return;
      const unsignedContract = documents
        ?.filter((doc: Document) =>
          doc?.name?.includes('UNSIGNED-PREMIUMCREDIT_'),
        )
        .sort(
          (c1: Document, c2: Document) =>
            new Date(c2.metadata?.created_at || 0).getTime() -
            new Date(c1.metadata?.created_at || 0).getTime(),
        );
      const doc = unsignedContract?.[0];
      if (!doc) return;
      const { content, name } = doc;
      const parts = name.split('.')[0].split('_');
      if (parts.length !== 4) return;
      const filename = parts[1];
      const signee = parts[2].split('-').join(' ');
      const timestamp = parseInt(parts[3], 10);
      if (!filename || !signee || !timestamp || !content) return;
      setContract({
        name: filename,
        signee,
        timestamp,
        content,
      });
      setFormDisplay('contract');
    }, [isLoading, documents, setContract, setFormDisplay]);

    const values = useMemo(
      () => ({
        isLoading,
        cache,
        premiumCreditEnable,
        display,
        setFormDisplay,
        contract,
        setContract,
        bankAccount,
        setBankAccount,
        referredByUser,
      }),
      [
        isLoading,
        cache,
        premiumCreditEnable,
        display,
        setFormDisplay,
        contract,
        setContract,
        bankAccount,
        setBankAccount,
        referredByUser,
      ],
    );

    return (
      <SubscriptionContext.Provider value={values}>
        {children}
      </SubscriptionContext.Provider>
    );
  };

export default SubscriptionProvider;
