import { Text } from '@kanda-libs/ks-design-library';
import { type CompanyTypeButtonClassNames } from './useCompanyTypeButtons';

export interface TypeButtonProps {
  classNames: CompanyTypeButtonClassNames;
  title: string;
  subtitle: string;
  onClick: () => void;
  isLoading?: boolean;
}

const TypeButton: React.FunctionComponent<TypeButtonProps> = function ({
  classNames,
  title,
  subtitle,
  onClick,
  isLoading = false,
}) {
  return (
    <button
      type="button"
      aria-label="company-type-button"
      onClick={onClick}
      className={classNames.button}
    >
      <Text
        isLoading={isLoading}
        className="text-16-24-em mb-1"
        text={title}
        skeletonProps={{ width: 200 }}
      />
      <Text isLoading={isLoading} className="text-14-22" text={subtitle} />
      <div className={classNames.indicator}>
        <div className="w-2 h-2 m-auto rounded-full bg-neutral-000" />
      </div>
    </button>
  );
};

export default TypeButton;
