import { Field, Form, FormTheme, Label } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';
import { BASE_URL } from '../../constants';
import { NAME_VALIDATION } from './constants';
import useNewTagFormForm from './useNewTagForm';

export interface NewTagFormFormProps {
  onCancelClick: () => void;
}

const NewTagFormForm: React.FunctionComponent<NewTagFormFormProps> = function ({
  onCancelClick,
}) {
  const { form, onSubmit, isSubmitting, onBlur } =
    useNewTagFormForm(onCancelClick);
  return (
    <Form
      id="new-tag-form"
      form={form}
      onSubmit={onSubmit}
      className="w-full bg-neutral-000 border border-neutral-300 px-4 py-5 rounded-lg flex flex-col shadow-card"
    >
      <FormTheme variant="streamline">
        <div className="flex flex-col">
          <Label label="Desired URL" />
          <div className="flex flex-col sm:flex-row">
            <p className="text-14-22 mt-1.5 mb-auto whitespace-nowrap">
              {BASE_URL}
            </p>
            <Field.Validator validation={NAME_VALIDATION}>
              <Field.Input name="name" onBlur={onBlur} />
            </Field.Validator>
          </div>
          <Field.Input
            name="source"
            label="(Optional) Where are you going to share this link (e.g. FaceBook, printed media, quote footer)?"
          />
        </div>
        <div className="flex flex-row w-full gap-x-4">
          <Button.Text
            onClick={onCancelClick}
            id="new-tag-form-cancel"
            label="Cancel"
            variant="outline"
            className="w-full"
            size={40}
            disabled={isSubmitting}
          />
          <Button.Text
            submit
            id="new-tag-form-submit"
            label="Submit"
            variant="gradient"
            className="w-full"
            size={40}
            disabled={isSubmitting}
          />
        </div>
        <Loader isLoading={isSubmitting} />
      </FormTheme>
    </Form>
  );
};

export default NewTagFormForm;
