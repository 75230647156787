import { Card, NoRates, Options } from '../components';
import { SUBTITLE, TITLE } from './constants';
import useUnregulatedRates from './useUnregulatedRates';

const FinanceRatesUnregulated: React.FunctionComponent = function () {
  const { rates, hideNoRates, toggleable, hasRates } = useUnregulatedRates();
  if (!hasRates && hideNoRates) return null;
  return (
    <Card title={TITLE} subtitle={SUBTITLE}>
      {rates && hasRates ? (
        <Options rates={rates} toggleable={toggleable} />
      ) : (
        <NoRates />
      )}
    </Card>
  );
};

export default FinanceRatesUnregulated;
