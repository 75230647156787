import {
  Button,
  Icon,
  ModalContainer,
  ModalLayoutCenter,
  ModalLayoutFullScreen,
  useIsDesktop,
} from '@kanda-libs/ks-design-library';
import { useCallback, type MutableRefObject } from 'react';
import {
  CLASS_NAMES,
  DONT_SHOW_AGAIN_LOCAL_STORAGE_KEY,
  SEND_REMINDER_MODAL_ID,
} from './constants';

const JobSendReminderModal: React.FunctionComponent = function ({}) {
  const isDesktop = useIsDesktop();
  const ModalLayout = isDesktop ? ModalLayoutCenter : ModalLayoutFullScreen;

  const onClickDontShowAgain = useCallback(() => {
    window.localStorage.setItem(DONT_SHOW_AGAIN_LOCAL_STORAGE_KEY, 'true');
  }, []);

  return (
    <ModalContainer id={SEND_REMINDER_MODAL_ID}>
      {({ id, handleClose, ref }) => (
        <>
          <ModalLayout
            id={id}
            onClose={handleClose}
            ref={ref as MutableRefObject<HTMLDivElement>}
            className={CLASS_NAMES.modal}
          >
            <div className={CLASS_NAMES.wrapper}>
              <Icon icon="send" className={CLASS_NAMES.icon} size={60} />
              <span className={CLASS_NAMES.title}>
                Reminder sent to customer
              </span>
              <ul className={CLASS_NAMES.list}>
                <li className={CLASS_NAMES.listItem}>
                  Tell your customer to check their SPAM folder
                </li>
                <li className={CLASS_NAMES.listItem}>
                  Search for an email from KANDA
                </li>
              </ul>
              <Button.Text
                id="job-details-send-reminder-modal-confirm"
                label="Ok! Got it"
                onClick={handleClose}
                className={CLASS_NAMES.button}
              />
              <button
                className={CLASS_NAMES.link}
                onClick={() => {
                  onClickDontShowAgain();
                  handleClose();
                }}
                type="button"
              >
                Don&apos;t show me this again
              </button>
            </div>
          </ModalLayout>
        </>
      )}
    </ModalContainer>
  );
};

export default JobSendReminderModal;
