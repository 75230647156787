import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';

export interface JobFinanceApplicationProgressHook {
  showStatus: boolean;
  isLoading: boolean;
}

export default function useJobFinanceApplicationProgress(): JobFinanceApplicationProgressHook {
  const { job, isLoading } = useJobContext();

  const showStatus = useMemo(
    () => (job?.finance_status || 'not_applied') !== 'not_applied',
    [job],
  );

  return {
    showStatus,
    isLoading,
  };
}
