import type { Company } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import {
  createContext,
  useContext,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';

export interface MyAccountCompanyInformationContextType {
  company: Company | undefined;
  id: string | undefined;
  isLoading: boolean;
  isSubmitting: boolean;
}

export const MyAccountCompanyInformationContext =
  createContext<MyAccountCompanyInformationContextType>({
    company: undefined,
    id: undefined,
    isLoading: false,
    isSubmitting: false,
  });

export const useMyAccountCompanyInformationContext = () =>
  useContext(MyAccountCompanyInformationContext);

export interface MyAccountCompanyInformationProviderProps {
  children: ReactNode;
}

const MyAccountCompanyInformationProvider: FunctionComponent<MyAccountCompanyInformationProviderProps> =
  function ({ children }) {
    const { company, id, isLoading, isSubmitting } = useCurrentCompany();

    const values = useMemo(
      () => ({
        company: company || undefined,
        id,
        isLoading,
        isSubmitting,
      }),
      [company, id, isLoading, isSubmitting],
    );

    return (
      <MyAccountCompanyInformationContext.Provider value={values}>
        {children}
      </MyAccountCompanyInformationContext.Provider>
    );
  };

export default MyAccountCompanyInformationProvider;
