import {
  useFormContext,
  useWatch,
  type AddressApiResponseAddress,
  type ValidationItems,
  type ValidationProps,
} from '@kanda-libs/ks-component-ts';
import { type StringIndexedObject } from '@kanda-libs/ks-design-library';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type FunctionComponent,
  type ReactNode,
  type SetStateAction,
} from 'react';
import {
  getValidationConditions,
  getValidationErrors,
  validateAddressSelect,
} from 'utils';
import { type AddressFieldsLabels } from '.';
import { LABELS } from './constants';
import { getWidgetPrefix } from './helpers';

export interface AddressFieldsProviderProps {
  children: ReactNode;
  baseFieldName: string;
  showYears?: boolean;
  showLabels?: boolean;
  labels?: AddressFieldsLabels;
}

export interface AddressFieldsContextType {
  baseFieldName: string;
  rootName: string;
  postCode: string;
  widgetPrefix: string;
  apiPostcode: string;
  setPostcodeName: (id: string) => void;
  postcodeCallback: (data: StringIndexedObject) => unknown;
  postcodeName: string | undefined;
  addresses: AddressApiResponseAddress[] | null;
  manual: boolean;
  enterManually: () => void;
  selectProps: ValidationProps;
  buildingValidation: ValidationItems;
  postcodeIsValidating: boolean;
  setPostcodeIsValidating: Dispatch<SetStateAction<boolean>>;
  showYears: boolean;
  showLabels: boolean;
  labels?: AddressFieldsLabels;
}

export const AddressFieldsContext = createContext<AddressFieldsContextType>({
  baseFieldName: '',
  rootName: '',
  widgetPrefix: '',
  postCode: '',
  apiPostcode: '',
  setPostcodeName: () => {},
  postcodeCallback: () => {},
  postcodeName: undefined,
  addresses: null,
  manual: false,
  enterManually: () => {},
  selectProps: {},
  buildingValidation: {},
  postcodeIsValidating: false,
  setPostcodeIsValidating: () => {},
  showYears: false,
  showLabels: false,
  labels: LABELS,
});

export const useAddressFieldsContext = () => useContext(AddressFieldsContext);

const AddressFieldsProvider: FunctionComponent<AddressFieldsProviderProps> =
  function ({
    children,
    baseFieldName,
    showYears = false,
    showLabels = true,
    labels,
  }) {
    const [addresses, setAddresses] = useState<
      AddressApiResponseAddress[] | null
    >(null);
    const [postcodeName, setPostcodeName] = useState<string | undefined>();
    const [manual, setManual] = useState<boolean>(false);
    const [apiPostcode, setApiPostcode] = useState<string>('');
    const [postcodeIsValidating, setPostcodeIsValidating] =
      useState<boolean>(false);

    const { getValues, setValue } = useFormContext();

    const postcodeCallback = useCallback((results: StringIndexedObject) => {
      setApiPostcode(results?.postcode);
      setAddresses(results?.addresses);
    }, []);
    const enterManually = useCallback(() => setManual(true), []);

    const rootName = useMemo(() => {
      if (!postcodeName) return baseFieldName;
      return postcodeName.replace('.postcode', '');
    }, [postcodeName, baseFieldName]);

    const widgetPrefix = useMemo(() => {
      if (!rootName) return getWidgetPrefix(baseFieldName);
      return getWidgetPrefix(rootName);
    }, [baseFieldName, rootName]);

    const [buildingName, buildingNumber, postCode, line1, selected] = useWatch({
      name: [
        `${rootName}.building_name`,
        `${rootName}.building_number`,
        `${rootName}.postcode`,
        `${rootName}.line_1`,
        `${rootName}.selected`,
      ],
    });

    const selectValidation = useMemo(
      () => ({
        validate: {
          value: () => validateAddressSelect(getValues(), rootName),
          message:
            'You must select an address or enter the address details manually',
        },
      }),
      [getValues, rootName],
    );

    const selectProps = useMemo(
      () =>
        ({
          validationConditions: getValidationConditions(selectValidation),
          validationErrors: getValidationErrors(selectValidation),
        } as unknown as ValidationProps),
      [selectValidation],
    );

    const buildingValidation = useMemo(
      () => ({
        required: {
          value: !buildingName && !buildingNumber,
          message: 'Building name or number is required',
        },
      }),
      [buildingName, buildingNumber],
    );

    const values = useMemo(
      () => ({
        baseFieldName,
        rootName,
        widgetPrefix,
        setPostcodeName,
        postcodeCallback,
        postcodeName,
        postCode,
        apiPostcode,
        addresses,
        manual,
        enterManually,
        selectProps,
        buildingValidation,
        postcodeIsValidating,
        setPostcodeIsValidating,
        showYears,
        showLabels,
        labels,
      }),
      [
        baseFieldName,
        rootName,
        widgetPrefix,
        setPostcodeName,
        postcodeCallback,
        postcodeName,
        postCode,
        apiPostcode,
        addresses,
        manual,
        enterManually,
        selectProps,
        buildingValidation,
        postcodeIsValidating,
        showYears,
        showLabels,
        labels,
      ],
    );

    useEffect(() => {
      if (!line1 || !addresses || addresses?.length === 0) return;
      const match = addresses.findIndex((address) => address.line_1 === line1);
      if (match === selected) return;
      setValue(`${rootName}.selected`, match);
    }, [line1, addresses, selected, setValue, rootName]);

    return (
      <AddressFieldsContext.Provider value={values}>
        {children}
      </AddressFieldsContext.Provider>
    );
  };

export default AddressFieldsProvider;
