import JobCustomer from 'pages/Job/components/JobCustomer';
import JobFinanceApplicationProgress from 'pages/Job/components/JobFinanceApplicationProgress';
import JobFinanceOptions from 'pages/Job/components/JobFinanceOptions';
import JobProgressBar from 'pages/Job/components/JobProgressBar';
import JobQuoteDetails from 'pages/Job/components/JobQuoteDetails';
import JobStatusBar from 'pages/Job/components/JobStatusBar';
import JobTitle from 'pages/Job/components/JobTitle';

const JobMobileContent: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col flex-1 gap-y-4 -mt-8 mb-6">
      <JobProgressBar />
      <JobTitle />
      <JobStatusBar />
      <div className="flex flex-col gap-y-4 order-2">
        <JobFinanceApplicationProgress />
        <JobCustomer />
        <JobQuoteDetails />
      </div>
      <JobFinanceOptions />
    </div>
  );
};

export default JobMobileContent;
