import { useJobContext } from 'pages/Job/context';

import { useMemo } from 'react';
import { formatWorkTypeToString } from 'utils';

export interface JobQuoteDetailsHook {
  title: string;
  description: string | undefined;
  workType: string | undefined;
  notes: string[] | undefined;
  isLoading: boolean;
}

export default function useJobQuoteDetails(): JobQuoteDetailsHook {
  const { job, isLoading } = useJobContext();

  const title = useMemo(() => {
    if (isLoading) return '';
    return job?.title || 'No title added';
  }, [isLoading, job]);

  const description = useMemo(() => {
    if (isLoading) return undefined;
    return job?.description;
  }, [isLoading, job]);

  const workType = useMemo(() => {
    if (isLoading) return undefined;
    if (!job?.work_type) return undefined;
    return formatWorkTypeToString(job?.work_type);
  }, [isLoading, job]);

  const notes = useMemo(() => {
    if (isLoading) return undefined;
    return job?.notes;
  }, [isLoading, job]);

  return {
    title,
    description,
    workType,
    notes,
    isLoading,
  };
}
