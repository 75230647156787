import JobTitleLineClamp from 'pages/Jobs/components/JobTitleLineClamp';
import useJobTitle from './useJobTitle';

const JobTitle: React.FunctionComponent = function ({}) {
  const { title, isLoading, isDesktop } = useJobTitle();
  return (
    <JobTitleLineClamp
      text={title}
      lines={2}
      className="text-18-22-em md:text-28-32-em lg:text-32-40-em"
      isLoading={isLoading}
      skeletonProps={{
        width: isDesktop ? 400 : 200,
        height: isDesktop ? 40 : 22,
      }}
    />
  );
};

export default JobTitle;
