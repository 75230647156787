import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
  LoadingSpinner,
} from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import { HEADING, SUBHEADING } from './constants';
import useBillingSuccess from './useBillingSuccess';

const BillingSuccess: FunctionComponent = function () {
  useBillingSuccess();
  return (
    <DesktopLayoutBoxed>
      <DesktopLayoutBoxedContent>
        <div className="flex flex-col gap-y-4 text-center">
          <LoadingSpinner
            stroke="green-400"
            className="m-auto animate-spin"
            duration={0}
          />
          <p className="text-24-28-em mt-4">{HEADING}</p>
          <p className="text-14-22">{SUBHEADING}</p>
        </div>
      </DesktopLayoutBoxedContent>
    </DesktopLayoutBoxed>
  );
};

export default BillingSuccess;
