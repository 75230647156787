import {
  Button,
  SkeletonLoader,
  Text,
  type ButtonTextProps,
} from '@kanda-libs/ks-design-library';
import { ReactComponent as Lines } from 'assets/background.svg';
import type { FunctionComponent, ReactNode } from 'react';
import { isBlackFriday } from 'utils';
import Bullets from './Bullets';
import Features, { type Feature } from './Features';
import type { SubscriptionCardVariant, SubscriptionProps } from './types';
import useSubscriptionCard from './useSubscriptionCard';

export interface SubscriptionCardProps {
  title: string;
  subscription: SubscriptionProps;
  bullets: string[];
  features: Feature[];
  badge?: ReactNode;
  buttonProps: ButtonTextProps;
  variant?: SubscriptionCardVariant;
  isLoading?: boolean;
}

const SubscriptionCard: FunctionComponent<SubscriptionCardProps> = ({
  title,
  subscription,
  bullets,
  features,
  buttonProps,
  badge = null,
  variant = 'standard',
  isLoading = false,
}) => {
  const { classNames, showLines } = useSubscriptionCard(variant);

  return (
    <div className={classNames.card}>
      {showLines && <Lines className={classNames.lines} />}
      <div
        className={`flex flex-col ${
          isBlackFriday() && variant === 'highlight'
            ? 'p-4 bg-neutral-900 rounded-lg -mx-2 -my-2'
            : ''
        }`}
      >
        <div className="flex flex-row gap-x-3">
          <div className="flex flex-col w-full">
            <Text
              text={title}
              className={classNames.title}
              isLoading={isLoading}
              skeletonProps={{ width: 80 }}
            />
            <div className="flex flex-row">
              <Text
                className={classNames.subscriptionPrice}
                text={`£${subscription.amount}`}
                isLoading={isLoading}
                skeletonProps={{ width: 60 }}
              />
              <Text
                className={classNames.subscriptionFrequency}
                text={` / ${subscription.frequency}`}
                isLoading={isLoading}
                skeletonProps={{ width: 60 }}
              />
            </div>
          </div>
          <SkeletonLoader
            isLoading={isLoading}
            afterLoading={<div>{badge}</div>}
            width={120}
            height={72}
          />
        </div>
        <Bullets bullets={bullets} variant={variant} isLoading={isLoading} />
        <Features features={features} variant={variant} isLoading={isLoading} />
        <Button.Text {...buttonProps} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default SubscriptionCard;
