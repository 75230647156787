import { pipe } from 'fp-ts/lib/function';
import { useMemo } from 'react';
import {
  filerOutHummRegulatedIfOtherRegulated,
  filterForRegulatedRates,
  filterOut149If129,
  filterOutPropensio149IfAllium149,
  groupByAprValuesAndFee,
  type GroupedByAprAndFee,
} from 'utils';
import { useFinanceOptionsContext } from '../context';

export interface RegulatedRatesHook {
  rates: GroupedByAprAndFee | undefined;
  hasRates: boolean;
  hideNoRates: boolean;
}

export default function useRegulatedRates(): RegulatedRatesHook {
  const {
    rates: allRates,
    total,
    deposit,
    hideNoRates,
  } = useFinanceOptionsContext();

  const rates = useMemo(() => {
    if (!allRates) return undefined;
    return pipe(
      allRates,
      filterForRegulatedRates,
      (regulated) =>
        filerOutHummRegulatedIfOtherRegulated(regulated, total, deposit),
      (hummFiltered) => filterOut149If129(hummFiltered, total, deposit),
      (rateFiltered) =>
        filterOutPropensio149IfAllium149(rateFiltered, total, deposit),
      groupByAprValuesAndFee,
    );
  }, [allRates, total, deposit]);

  const hasRates = useMemo(() => {
    if (!rates) return false;
    return Object.keys(rates).length > 0;
  }, [rates]);

  return {
    rates,
    hasRates,
    hideNoRates,
  };
}
