import type { PaginationProps } from '@kanda-libs/ks-design-library';
import { useJobsContext } from 'pages/Jobs/context';
import type { FormattedJob } from 'pages/Jobs/helpers';
import { useMemo } from 'react';
import { SKELETON_JOBS } from './constants';

export interface JobsListHook {
  data: FormattedJob[];
  isLoading: boolean;
  showPagination: boolean;
  paginationProps: PaginationProps;
  archive: boolean;
}

export default function useJobsList(): JobsListHook {
  const {
    data: jobData,
    totalPages,
    pageIndex,
    setPage,
    archive,
    isLoading,
  } = useJobsContext();

  const showPagination = useMemo(() => totalPages > 1, [totalPages]);

  const data = useMemo(() => {
    if (isLoading) return SKELETON_JOBS;
    return jobData;
  }, [isLoading, jobData]);

  return {
    data,
    isLoading,
    showPagination,
    paginationProps: {
      pageCount: totalPages,
      pageIndex,
      setPage,
    },
    archive,
  };
}
