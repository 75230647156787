import { AddressFieldsLabels } from '.';

export const LABELS: AddressFieldsLabels = {
  postcode: 'Postcode',
  select: 'Address',
  buildingName: 'Building name',
  buildingNumber: 'Building number',
  line1: 'Address Line 1 or Street Name',
  line2: 'Address Line 2',
  city: 'City',
  county: 'County',
  country: 'Country',
};
