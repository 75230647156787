import type { Company } from '@kanda-libs/ks-frontend-services';
import type { BankDetailsFormValues } from './useBankDetailsForm';

export const getBankAccountFormBody = (
  company: Company,
  formValues: BankDetailsFormValues,
): Company | undefined => {
  const bank = company?.bank_account;
  if (!bank)
    return {
      ...company,
      bank_account: formValues.bank_account,
    };
  return {
    ...company,
    bank_account: {
      ...bank,
      ...formValues.bank_account,
    },
  };
};
