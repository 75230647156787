import { ModalsWrapperContext } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { useCallback, useContext, type MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { JOB_SLIDE_UP_MODAL_ID } from '../JobSlideUpModal/constants';

export interface JobMobileHeaderHook {
  onClick: () => void;
  openSlideUp: (event: MouseEvent<HTMLButtonElement>) => void;
}

export default function useJobMobileHeader(): JobMobileHeaderHook {
  const { showModal } = useContext(ModalsWrapperContext);
  const { length, goBack, push } = useHistory();

  const openSlideUp = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      showModal(JOB_SLIDE_UP_MODAL_ID);
    },
    [showModal],
  );

  const onClick = useCallback(() => {
    if (length === 0) {
      push(URLS.jobs);
      return;
    }
    const previousURL = document.referrer;
    if (previousURL.includes('jobs')) {
      goBack();
      return;
    }
    push(URLS.jobs);
  }, [length, goBack, push]);

  return {
    onClick,
    openSlideUp,
  };
}
