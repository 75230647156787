import { Button, Icon, Loader, Text } from '@kanda-libs/ks-design-library';

import Container from './Custom-container';

const ViewComponent = () => (
  <Container>
    {({ name, onQuoteDocumentClick, isSubmitting }) => (
      <div className="flex flex-col gap-y-1">
        <p className="text-12-18-em text-neutral-700">
          Uploaded PDF (click to download)
        </p>
        <button
          onClick={onQuoteDocumentClick}
          type="button"
          aria-label="quote-line-item-download"
          className="flex flex-row justify-between bg-neutral-000 border rounded-xl border-neutral-300 px-3 py-3.5"
        >
          <div className="flex">
            <Icon
              icon="file"
              stroke="green-300"
              className="mr-3 my-auto"
              size={20}
            />
            <Text text={name} className="text-14-22 text-neutral-700 my-auto" />
          </div>
          <Button.Icon
            id="mobile-custom-quote-line-item"
            icon="chevron-right"
            variant="ghost-grey"
            className="ml-3 my-auto"
            size="28-16"
          />
        </button>
        <Loader isLoading={isSubmitting} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Mobile-Custom-view';

export default ViewComponent;
