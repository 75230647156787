import type { Company } from '@kanda-libs/ks-frontend-services';
import type { LogoFormValues } from './useLogoForm';

export const formatLogoBody = (
  company: Company,
  fv: LogoFormValues,
): Company => ({
  ...company,
  company_logo: fv.company.company_logo,
});
