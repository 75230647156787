import type { TagBody } from '@kanda-libs/ks-frontend-services';
import type { NewTagFormFormValues } from './useNewTagForm';

export const tradingNameToSlug = (tradingName: string) =>
  tradingName
    .replace(/&/g, 'and')
    .replace(/[^a-zA-Z0-9- ]+/g, ' ')
    .split(' ')
    .filter(Boolean)
    .map((s) => s.toLowerCase())
    .join('-');

export const formatTagBody = (formValues: NewTagFormFormValues): TagBody => ({
  ...formValues,
  source: formValues?.source || 'kanda',
});
