import { Button } from '@kanda-libs/ks-design-library';
import { OMNI_WARNING } from './constants';
import useJobMarkAsFinishedButton from './useJobMarkAsFinishedButton';

const JobMarkAsFinishedButton: React.FunctionComponent = function ({}) {
  const {
    showButton,
    showOmniWarning,
    onClick,
    companyIsLoading,
    isSubmitting,
  } = useJobMarkAsFinishedButton();
  if (!showButton) return null;
  if (showOmniWarning)
    return (
      <div className="w-full max-w-80 p-2 border border-red-200 rounded-lg bg-red-100 mb-2 md:mb-0 md:mr-2 text-14-22-em text-red-200">
        <span>
          {OMNI_WARNING}
          <a
            href="https://clt1489884.bmeurl.co/108F1772"
            className="text-blue-200 underline"
          >
            click here
          </a>
        </span>
      </div>
    );
  return (
    <Button.Text
      id="job-details-mark-as-finished"
      onClick={onClick}
      label="Mark as finished"
      size={40}
      icon="send"
      left
      className="w-full md:mr-2"
      disabled={companyIsLoading || isSubmitting}
    />
  );
};

export default JobMarkAsFinishedButton;
