import { Text } from '@kanda-libs/ks-design-library';
import TypeButton from './TypeButton';
import useCompanyTypeButtons from './useCompanyTypeButtons';

export interface CompanyTypeButtonsProps {
  isLoading?: boolean;
}

const CompanyTypeButtons: React.FunctionComponent<CompanyTypeButtonsProps> =
  function ({ isLoading = false }) {
    const { classNames, onLimitedClick, onSoleClick } = useCompanyTypeButtons();
    return (
      <div className="flex flex-col w-full mb-6">
        <Text
          text="What type of business do you run?"
          className="text-14-22-em mb-3"
          isLoading={isLoading}
        />
        <div className="flex flex-col gap-y-2">
          <TypeButton
            classNames={classNames.limited}
            title="Limited Company"
            subtitle="My business is officially registered with HMRC and has its own business name."
            onClick={onLimitedClick}
            isLoading={isLoading}
          />
          <TypeButton
            classNames={classNames.sole}
            title="Sole trader"
            subtitle="I'm self-employed and use my own name to do business."
            onClick={onSoleClick}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  };

export default CompanyTypeButtons;
