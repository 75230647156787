import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Link } from 'react-router-dom';
import { BUTTON_PROPS } from './constants';

const NewJobButton: React.FunctionComponent = function () {
  return (
    <Link to={URLS.createJob} className="ml-2">
      <BreakPoints
        mobile={<Button.Icon {...BUTTON_PROPS.mobile} />}
        desktop={<Button.Text {...BUTTON_PROPS.desktop} />}
      />
    </Link>
  );
};

export default NewJobButton;
