import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import {
  useCallback,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { scrollToTop } from 'utils';
import { REFERRAL_DISCOUNT } from '../../constants';
import { useSubscriptionContext } from '../../context';
import {
  HUMM_MONTHLY_CARD,
  HUMM_YEARLY_CARD,
  IWOCA_FEATURES,
  PREMIUM_CREDIT_FEATURES,
  STANDARD_MONTHLY_CARD,
  STANDARD_YEARLY_CARD,
} from './constants';
import IwocaBadge from './IwocaBadge';
import StripeBadge from './StripeBadge';
import type { SubscriptionCardProps } from './SubscriptionCard';

export interface SubscriptionsHook {
  subscriptions: SubscriptionCardProps[];
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  showCancelledBanner: boolean;
  showIwocaBanner: boolean;
  showPCBanner: boolean;
}

export type MonthlyProvider = 'PremiumCredit' | 'iwoca';

export default function useSubscriptions(): SubscriptionsHook {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { showError } = useToast();
  const onError = useApiError('There was an error creating the subscription');
  const companies = useSelector(selectors.company.getEntitiesAsArray);
  const { setFormDisplay, isLoading, referredByUser, premiumCreditEnable } =
    useSubscriptionContext();

  const company = useMemo(() => {
    if (!companies || companies?.length === 0) return null;
    return companies[0];
  }, [companies]);

  const billing = useMemo(() => company?.billing, [company]);
  const companyType = useMemo(() => company?.company_type, [company]);
  const provider: MonthlyProvider = useMemo(() => {
    if (isLoading) return 'iwoca';
    if (premiumCreditEnable) return 'PremiumCredit';
    if (companyType === 'sole_trader') return 'PremiumCredit';
    return premiumCreditEnable ? 'PremiumCredit' : 'iwoca';
  }, [companyType, isLoading, premiumCreditEnable]);

  const showIwocaBanner = useMemo(
    () => !isLoading && provider === 'iwoca',
    [provider, isLoading],
  );

  const showCancelledBanner = useMemo(() => {
    if (isLoading) return false;
    return provider === 'iwoca' && billing === 'cancelled';
  }, [isLoading, billing, provider]);

  const showPCBanner = useMemo(() => {
    if (isLoading) return false;
    return premiumCreditEnable;
  }, [isLoading, premiumCreditEnable]);

  const onMonthlyClick = useCallback(() => {
    const id = company?.id;
    if (!id) {
      showError('Error creating subscription - company id missing');
      return;
    }
    scrollToTop();
    if (provider === 'PremiumCredit') {
      setFormDisplay('form');
      return;
    }
    setFormDisplay('iwoca');
  }, [showError, company, setFormDisplay, provider]);

  const onYearlyClick = useCallback(() => {
    const id = company?.id;
    if (!id) {
      showError('Error creating subscription - company id missing');
      return;
    }
    setIsSubmitting(true);
    dispatch(
      actions.postCompanyBilling({
        params: {
          id,
        },
        onError: (error) => {
          setIsSubmitting(false);
          onError(error);
        },
        onSuccess: (data) => {
          setIsSubmitting(false);
          const url = data?.checkout_url;
          if (!url) {
            showError(
              'Authorisation URL missing from response - please contact Kanda',
            );
            return;
          }
          window.location.href = url;
        },
      }),
    );
  }, [company, dispatch, onError, showError]);

  const subscriptions: SubscriptionCardProps[] = useMemo(() => {
    if (!company)
      return [
        {
          ...STANDARD_YEARLY_CARD,
          subscription: {
            ...STANDARD_YEARLY_CARD.subscription,
            ...(referredByUser
              ? {
                  amount:
                    STANDARD_YEARLY_CARD.subscription.amount -
                    REFERRAL_DISCOUNT,
                }
              : {}),
          },
          buttonProps: {
            ...STANDARD_YEARLY_CARD.buttonProps,
            onClick: onYearlyClick,
            disabled: isSubmitting,
          },
          badge: <StripeBadge />,
        },
        {
          ...STANDARD_MONTHLY_CARD,
          features:
            provider === 'PremiumCredit'
              ? PREMIUM_CREDIT_FEATURES
              : IWOCA_FEATURES,
          buttonProps: {
            ...STANDARD_MONTHLY_CARD.buttonProps,
            onClick: onMonthlyClick,
            disabled: isSubmitting,
          },
          ...(provider === 'iwoca' ? { badge: <IwocaBadge /> } : {}),
        },
      ];
    const hummUnreg =
      company?.business_config?.lender === 'humm' &&
      company?.business_config?.lender_rate_type === 'unregulated';
    if (hummUnreg)
      return [
        {
          ...HUMM_YEARLY_CARD,
          subscription: {
            ...HUMM_YEARLY_CARD.subscription,
            ...(referredByUser
              ? {
                  amount:
                    HUMM_YEARLY_CARD.subscription.amount - REFERRAL_DISCOUNT,
                }
              : {}),
          },
          buttonProps: {
            ...HUMM_YEARLY_CARD.buttonProps,
            onClick: onYearlyClick,
            disabled: isSubmitting,
          },
          badge: <StripeBadge />,
        },
        {
          ...HUMM_MONTHLY_CARD,
          features:
            provider === 'PremiumCredit'
              ? PREMIUM_CREDIT_FEATURES
              : IWOCA_FEATURES,
          buttonProps: {
            ...HUMM_MONTHLY_CARD.buttonProps,
            onClick: onMonthlyClick,
            disabled: isSubmitting,
          },
          ...(provider === 'iwoca' ? { badge: <IwocaBadge /> } : {}),
        },
      ];
    return [
      {
        ...STANDARD_YEARLY_CARD,
        subscription: {
          ...STANDARD_YEARLY_CARD.subscription,
          ...(referredByUser
            ? {
                amount:
                  STANDARD_YEARLY_CARD.subscription.amount - REFERRAL_DISCOUNT,
              }
            : {}),
        },
        buttonProps: {
          ...STANDARD_YEARLY_CARD.buttonProps,
          onClick: onYearlyClick,
          disabled: isSubmitting,
        },
        badge: <StripeBadge />,
      },
      {
        ...STANDARD_MONTHLY_CARD,
        features:
          provider === 'PremiumCredit'
            ? PREMIUM_CREDIT_FEATURES
            : IWOCA_FEATURES,
        buttonProps: {
          ...STANDARD_MONTHLY_CARD.buttonProps,
          onClick: onMonthlyClick,
          disabled: isSubmitting,
        },
        ...(provider === 'iwoca' ? { badge: <IwocaBadge /> } : {}),
      },
    ];
  }, [
    company,
    isSubmitting,
    onMonthlyClick,
    onYearlyClick,
    referredByUser,
    provider,
  ]);

  return {
    subscriptions,
    isSubmitting,
    setIsSubmitting,
    isLoading,
    showCancelledBanner,
    showIwocaBanner,
    showPCBanner,
  };
}
