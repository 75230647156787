import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';
import { getIcon } from './helpers';

export interface JobFinanceApplicationProgressCardIconHook {
  icon: string;
  color: string | undefined;
  isLoading: boolean;
}

export default function useJobFinanceApplicationProgressCardIcon(): JobFinanceApplicationProgressCardIconHook {
  const { isLoading, job, checkJob } = useJobContext();

  const financeStatus = useMemo(() => {
    if (!job || !checkJob) return 'not_applied';
    if (job?.finance_status === 'financed') {
      const payment = [...(job?.payments || [])]
        ?.sort(
          (p1, p2) =>
            (new Date(
              p2?.metadata?.created_at as unknown as number,
            ) as unknown as number) -
            (new Date(
              p1?.metadata?.created_at as unknown as number,
            ) as unknown as number),
        )
        ?.filter((p) => p?.payment_option?.payment_method === 'card')?.[0];
      if (!payment) return job?.finance_status;
      if (
        ['accepted_sign_document', 'accepted_deposit_required']?.includes(
          checkJob?.current_status || '',
        )
      )
        return 'applied_for_finance';
      return job?.finance_status;
    }
    return job?.finance_status;
  }, [job, checkJob]);

  const { icon, color } = useMemo(
    () => getIcon(financeStatus),
    [financeStatus],
  );

  return {
    icon,
    color,
    isLoading,
  };
}
