import Widgets from './Widgets';
import { useAddressFieldsContext } from './context';

const AddressFieldsContent: React.FunctionComponent = function () {
  const { manual, showYears } = useAddressFieldsContext();
  return (
    <div className="flex flex-col w-full text-left">
      <div className="flex flex-col md:flex-row gap-x-5">
        <div className="w-full md:w-32 md:min-w-32">
          <Widgets.Postcode />
        </div>
        <div className="flex flex-col flex-grow w-full">
          <Widgets.Select />
        </div>
      </div>
      {manual && (
        <>
          <Widgets.ManualField name="buildingName" />
          <Widgets.ManualField name="buildingNumber" />
          <Widgets.ManualField name="line1" />
          <Widgets.ManualField name="line2" />
          <Widgets.ManualField name="city" />
          <Widgets.ManualField name="county" />
          <Widgets.ManualField name="country" />
        </>
      )}
      {showYears && <p>years</p>}
    </div>
  );
};

export default AddressFieldsContent;
