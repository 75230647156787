import { Icon } from '@kanda-libs/ks-design-library';
import { ICON_PROPS } from './constants';
import useJobFinanceApplicationProgressCardIcon from './useJobFinanceApplicationProgressCardIcon';

const JobFinanceApplicationProgressCardIcon: React.FunctionComponent =
  function () {
    const { icon, color, isLoading } =
      useJobFinanceApplicationProgressCardIcon();
    return (
      <Icon icon={icon} stroke={color} isLoading={isLoading} {...ICON_PROPS} />
    );
  };

export default JobFinanceApplicationProgressCardIcon;
