import type { Company } from '@kanda-libs/ks-frontend-services';
import type { ContactFormValues } from './useContactForm';

export const getContactFormBody = (
  company: Company,
  formValues: ContactFormValues,
): Company | undefined => {
  const contactInfo = company?.contact_info;
  if (!contactInfo)
    return {
      ...company,
      contact_info: {
        ...formValues.contact_info,
      },
    };
  const { trading_name: tradingName, ...rest } = contactInfo;
  return {
    ...company,
    contact_info: {
      ...rest,
      ...formValues.contact_info,
    },
  };
};
