import iwocaLogoImage from 'assets/iwocapaylogowhite.png';

const IwocaBanner: React.FunctionComponent = function ({}) {
  return (
    <div className="-mt-4 md:-mt-6">
      <div className="flex flex-col w-full bg-[#08172b] p-3 md:p-5 rounded-xl overflow-hidden">
        <p className="text-24-28-em md:text-28-32-em text-neutral-000 mb-4">
          Spread your subscription payment over 3 or 12 months interest free
        </p>
        <div className="flex flex-col md:flex-row">
          <span className="text-16-24-em text-neutral-000 mb-2">
            Choose <span className="text-[#fb5950]">monthly</span> below to pay
            with <span className="text-[#fb5950]">iwocaPay</span> and don&apos;t
            pay for 30 days
          </span>
          <div className="w-60 -mb-10 -mr-10 bg-[#fb5950] ml-auto pl-5 pr-10 pt-5 pb-10 -skew-x-[20deg] rounded-xl">
            <div className="skew-x-[20deg]">
              <img src={iwocaLogoImage} alt="iwocaPay" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IwocaBanner;
