import stripeBadgeImage from 'assets/stripe.png';

const StripeBadge: React.FunctionComponent = function ({}) {
  return (
    <div className="w-30 min-w-30 -mt-2">
      <img src={stripeBadgeImage} alt="stripe" />
    </div>
  );
};

export default StripeBadge;
