/* eslint import/no-cycle: 0 */
import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';

import type { JobItem } from '@kanda-libs/ks-frontend-services';
import Context, { type LineItemContextValue } from '../context';
import Container from './Provider-container';

export interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
  /**
   * Name of field
   */
  item?: JobItem;
  /**
   * Is loading
   */
  isLoading?: boolean;
  /**
   * Total price props
   */
  totalPriceProps?: StringIndexedObject;
}

const ViewComponent = ({ children, ...restProps }: ViewComponentProps) => (
  <Container {...restProps}>
    {(values) => (
      <Context.Provider value={values as LineItemContextValue}>
        {children}
      </Context.Provider>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  children: null,
  isLoading: undefined,
  item: undefined,
  totalPriceProps: {},
};

ViewComponent.displayName = 'LineItem-Context-Provider-view';

export default ViewComponent;
