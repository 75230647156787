export const SKELETONS = {
  title: {
    width: 120,
  },
  quantity: {
    width: 20,
  },
  description: {
    width: 180,
  },
};

export const VAT_OPTIONS = [
  { name: 'No VAT', value: 'exempted' },
  { name: '0% VAT', value: '0%' },
  { name: '+5% VAT', value: '5%' },
  { name: '+20% VAT', value: '20%' },
];

export const VARIANTS = {
  'job-details': {
    mobile: {
      container:
        'flex flex-col flex-1 px-3 py-4 rounded-xl bg-neutral-100 mb-1',
      values: 'flex flex-row flex-wrap items-center mt-3',
    },
    desktop: {
      container: 'flex flex-row flex-1 p-6 rounded-xl bg-neutral-100 mb-3',
      titleWrapper: 'flex flex-1 flex-col mr-5',
      values: 'flex flex-row items-center',
    },
    title: 'text-16-20-em text-neutral-700  md:mb-2 break-all',
    description:
      'text-style-h text-neutral-600 mt-1 md:text-style-g break-all child-ul:list-disc child-ol:list-decimal',
    quantity:
      'text-style-14-22 text-neutral-600 mr-3 min-w-6 md:text-style-g md:mr-4',
    price: {
      wrapperClassName: 'mr-3 h-6',
      poundsClassName: 'text-14-22 md:text-14-22',
      centsClassName: 'text-14-22 md:text-14-22',
      color: 'neutral-600',
    },
    vat: 'text-style-h text-neutral-600 mr-3 min-w-6 md::text-style-g md:mr-4',
    total: {
      wrapperClassName: 'ml-auto md:min-w-20 md:flex md:justify-end md:mb-1',
      poundsClassName: 'text-style-f-em my-auto md:text-style-e',
      centsClassName: 'text-style-k-em',
      color: 'green-500 md:neutral-500',
    },
  },
  'job-summary': {
    mobile: {
      container:
        'flex flex-col flex-1 px-3 py-4 rounded-xl bg-neutral-100 mb-1',
      values: 'flex flex-row flex-wrap items-center mt-3',
    },
    desktop: {
      container:
        'flex flex-row flex-1 p-4 rounded-xl bg-neutral-100 mt-3 first:mt-0',
      titleWrapper: 'flex flex-1 flex-col mr-5',
      values: 'flex flex-row items-center',
    },
    title: 'text-style-g-em text-neutral-600 md:text-neutral-700 break-words',
    description:
      'text-style-h text-neutral-600 mt-1 md:text-style-g break-words child-ul:list-disc child-ol:list-decimal',
    quantity:
      'text-style-g text-neutral-600 mr-3 min-w-6 md:text-style-g md:mr-4',
    price: {
      wrapperClassName: 'mr-3 h-6',
      poundsClassName: 'text-style-g md:text-style-g',
      centsClassName: 'text-style-g md:text-style-g',
      color: 'neutral-600',
    },
    vat: 'text-style-h-em text-neutral-600 mr-3 min-w-6 md::text-style-g md:mr-4',
    total: {
      wrapperClassName: 'ml-auto md:min-w-20 md:flex md:justify-end md:mb-1',
      poundsClassName:
        'text-style-f-em my-auto text-turquoise-400 md:text-style-e md:text-neutral-500',
      centsClassName:
        'text-style-k-em text-turquoise-400 md:text-neutral-500 mt-0.5',
    },
  },
  'customer-job-view': {
    mobile: {
      container:
        'flex flex-col flex-1 px-3 py-4 first:rounded-t-xl last:rounded-b-xl border border-neutral-200 border-b-0 last:border-b',
      values: 'flex flex-row flex-wrap items-center mt-3',
      smaller: true,
    },
    desktop: {
      container:
        'flex flex-row flex-1 px-3 py-4 first:rounded-t-xl last:rounded-b-xl border border-neutral-200 border-b-0 last:border-b bg-neutral-000',
      titleWrapper: 'flex flex-1 flex-col mr-5',
      values: 'flex flex-row items-center',
      smaller: true,
    },
    title: 'text-style-g-em text-neutral-600 break-all',
    description: 'text-style-h text-neutral-600 mt-1 break-all',
    quantity: 'text-style-g text-neutral-600 mr-1',
    price: {
      wrapperClassName: 'mr-3 h-6',
      poundsClassName: 'text-style-h md:text-style-g',
      centsClassName: 'text-style-h md:text-style-g',
      color: 'neutral-600',
    },
    vat: 'text-style-h-em text-neutral-600 mr-3 min-w-6 md::text-style-g md:mr-4',
    total: {
      wrapperClassName: 'ml-auto md:min-w-20 md:flex md:justify-end md:mb-1',
      poundsClassName: 'text-style-f-em my-auto md:text-style-e',
      centsClassName: 'text-style-k-em',
      color: 'neutral-600',
    },
  },
};
