import { Dropzone } from '@kanda-libs/ks-component-ts';
import {
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
} from '@kanda-libs/ks-design-library';
import JobsBanner from '../components/Banner';
import useJobsDesktop from './useJobsDesktop';

const JobsDesktop: React.FunctionComponent = function () {
  const { Content, onDrop } = useJobsDesktop();
  return (
    <Dropzone onDrop={onDrop}>
      <DesktopLayoutDefault>
        <DesktopLayoutDefaultContent>
          <JobsBanner />
          <Content />
        </DesktopLayoutDefaultContent>
      </DesktopLayoutDefault>
    </Dropzone>
  );
};

export default JobsDesktop;
