import { useDownloadPdf } from '@kanda-libs/ks-component-ts';
import type {
  Company,
  Job,
  JobCreditState,
} from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import {
  createContext,
  useContext,
  useMemo,
  useState,
  type Dispatch,
  type FunctionComponent,
  type ReactNode,
  type SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface JobContextType {
  job: Job | undefined;
  checkJob: JobCreditState | undefined;
  company: Company | undefined;
  archived: boolean;
  example: boolean;
  id: string | undefined;
  downloadPdf: (id: string) => void;
  status: NonNullable<Job['status']>;
  isLoading: boolean;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

export const JobContext = createContext<JobContextType>({
  job: undefined,
  checkJob: undefined,
  company: undefined,
  archived: false,
  example: false,
  id: undefined,
  downloadPdf: () => {},
  status: 'draft',
  isLoading: false,
  isSubmitting: false,
  setIsSubmitting: () => {},
});

export const useJobContext = () => useContext(JobContext);

export interface JobProviderProps {
  children: ReactNode;
}

const JobProvider: FunctionComponent<JobProviderProps> = function ({
  children,
}) {
  const [contextIsSubmitting, setIsSubmitting] = useState<boolean>(false);

  const job = useSelector(selectors.job.getEntity);
  const jobHasFetched = useSelector(selectors.job.getHasFetched);
  const jobIsSubmitting = useSelector(selectors.job.getIsSubmitting);
  const id = useSelector(selectors.job.getId);
  const checkJob = useSelector(selectors.checkJob.getEntity);
  const checkJobHasFetched = useSelector(selectors.checkJob.getHasFetched);

  const { company } = useCurrentCompany();
  const { downloadPdf, isSubmitting: pdfDownloadIsSubmitting } =
    useDownloadPdf();

  const archived = useMemo(() => {
    if (!job) return false;
    return job.archived === 'yes';
  }, [job]);

  const example = useMemo(() => {
    if (!job) return false;
    return job.flow_type === 'example';
  }, [job]);

  const status = useMemo(() => {
    if (!job) return 'draft';
    return job.status || 'draft';
  }, [job]);

  const isLoading = useMemo(
    () => !jobHasFetched || !company || !checkJobHasFetched,
    [jobHasFetched, company, checkJobHasFetched],
  );

  const isSubmitting = useMemo(
    () => pdfDownloadIsSubmitting || contextIsSubmitting || jobIsSubmitting,
    [pdfDownloadIsSubmitting, contextIsSubmitting, jobIsSubmitting],
  );

  const values = useMemo(
    () => ({
      job,
      checkJob,
      company: company || undefined,
      archived,
      example,
      id,
      downloadPdf,
      status,
      isLoading,
      isSubmitting,
      setIsSubmitting,
    }),
    [
      job,
      checkJob,
      company,
      archived,
      example,
      id,
      downloadPdf,
      status,
      isLoading,
      isSubmitting,
    ],
  );

  return <JobContext.Provider value={values}>{children}</JobContext.Provider>;
};

export default JobProvider;
