import { Widget, type ValidationItems } from '@kanda-libs/ks-component-ts';
import { type FunctionComponent } from 'react';
import {
  ADDRESS_FIELD_MANUAL_PROPS,
  type AddressManualFieldNames,
} from './constants';
import useManualFieldWidget from './useManualFieldWidget';

export interface AddressFieldsWidgetsManualFieldProps {
  name: AddressManualFieldNames;
  validation?: ValidationItems;
}

const AddressFieldsWidgetsManualField: React.FunctionComponent<AddressFieldsWidgetsManualFieldProps> =
  function ({ name, ...rest }) {
    const { widgetName, label } = useManualFieldWidget(name);
    const Tag = Widget[widgetName] as FunctionComponent<any>;
    const props = ADDRESS_FIELD_MANUAL_PROPS[name];

    if (!Tag) return null;
    return <Tag label={label} {...props} {...rest} />;
  };

export default AddressFieldsWidgetsManualField;
