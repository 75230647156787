import { Widget } from '@kanda-libs/ks-component-ts';
import { GetName } from 'common';
import { type FunctionComponent } from 'react';
import { PROPS } from './constants';
import usePostcodeWidget from './usePostcodeWidget';

const AddressFieldsWidgetsPostcode: React.FunctionComponent = function () {
  const {
    setPostcodeName,
    widgetName,
    onPostcodeSearch,
    extraProps,
    showLabels,
    label,
  } = usePostcodeWidget();
  const Tag = Widget[widgetName] as FunctionComponent<any>;
  if (!Tag) {
    // eslint-disable-next-line
    console.warn(`No Widget with name ${widgetName}`);
    return null;
  }
  return (
    <div className={`flex flex-1 ${showLabels ? '' : 'mt-6 pt-0.5'}`}>
      <GetName callback={setPostcodeName}>
        <Tag
          onPostcodeSearch={onPostcodeSearch}
          label={showLabels ? label : ''}
          placeholder={PROPS.placeholder}
          {...extraProps}
        />
      </GetName>
    </div>
  );
};

export default AddressFieldsWidgetsPostcode;
