import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { useMyAccountCompanyInformationContext } from '../../../context';
import { type LogoFormValues } from '../useLogoForm';
import { formatImage } from './helpers';

export interface LogoHook {
  isLoading: boolean;
  dataUrl: string | undefined;
  alt: string;
  onRemoveClicked: () => void;
}

export default function useLogo(): LogoHook {
  const dispatch = useAppDispatch();

  const { isLoading: contextIsLoading } =
    useMyAccountCompanyInformationContext();

  const { setValue } = useFormContext<LogoFormValues>();

  const logoId = useWatch<LogoFormValues, 'company.company_logo.id'>({
    name: 'company.company_logo.id',
  });

  const documents = useSelector(selectors.document.getEntities);
  const documentsIsLoading = useSelector(selectors.document.getIsLoading);

  const logo = useMemo(() => {
    if (!logoId) return undefined;
    return documents?.[logoId];
  }, [logoId, documents]);

  const isLoading = useMemo(
    () => contextIsLoading || documentsIsLoading,
    [contextIsLoading, documentsIsLoading],
  );

  const dataUrl = useMemo(() => {
    if (!logo) return undefined;
    return formatImage(logo);
  }, [logo]);

  const alt = useMemo(() => {
    if (!logo) return 'logo';
    return logo.name;
  }, [logo]);

  const onRemoveClicked = useCallback(() => {
    setValue('company.company_logo', undefined);
  }, [setValue]);

  const loadRef = useRef<boolean>(false);
  useEffect(() => {
    if (isLoading || !logoId || loadRef.current) return;
    loadRef.current = true;
    dispatch(
      actions.getDocument({
        params: {
          id: logoId,
        },
        forceReload: true,
      }),
    );
  }, [isLoading, logoId, logo, dispatch]);

  return {
    isLoading,
    dataUrl,
    alt,
    onRemoveClicked,
  };
}
