import TableContact from 'components/table/TableContact';
import JobActivity from 'pages/Jobs/components/JobActivity';
import JobNameType from 'pages/Jobs/components/JobNameType';
import JobPriceStatus from 'pages/Jobs/components/JobPriceStatus';
import JobTitleLineClamp from 'pages/Jobs/components/JobTitleLineClamp';

export const TABLE_COLUMNS = [
  {
    Header: 'Homeowner Name',
    accessor: 'firstName',
    subAccessors: ['lastName', 'type'],
    items: [
      {
        action: {
          type: 'first-name-alternate',
        },
        icon: 'alphabet',
        name: 'Sort by first name',
      },
      {
        action: {
          type: 'last-name-alternate',
        },
        icon: 'alphabet',
        name: 'Sort by last name',
      },
    ],
    width: 100,
    minWidth: 100,
    maxWidth: 2000,
    renderComponent: {
      component: JobNameType,
      value: 'firstName',
      optionalProps: ['lastName', 'type'],
      props: {
        className: 'text-16-20-em text-neutral-700',
        queryType: 'jobs',
      },
    },
  },
  {
    Header: 'Homeowner Contact',
    accessor: 'email',
    subAccessors: ['phone'],
    items: [
      {
        action: {
          type: 'email-alternate',
        },
        icon: 'email',
        name: 'Sort by email',
      },
      {
        action: {
          type: 'phone-alternate',
        },
        icon: 'phone',
        name: 'Sort by phone',
      },
    ],
    width: 180,
    minWidth: 100,
    maxWidth: 2000,
    renderComponent: {
      component: TableContact,
      value: 'email',
      optionalProps: ['phone'],
      props: {
        queryType: 'jobs',
      },
    },
  },
  {
    Header: 'Job title',
    accessor: 'title',
    items: [],
    renderComponent: {
      component: JobTitleLineClamp,
      value: 'text',
      removeOverflowRestriction: true,
      props: {
        className: 'text-14-22-em text-neutral-700',
      },
    },
    width: 200,
    minWidth: 100,
    maxWidth: 2000,
  },
  {
    Header: 'Job Price',
    accessor: 'price',
    subAccessors: ['status', 'financeStatus'],
    items: [
      {
        action: {
          type: 'price-ascending',
        },
        icon: 'sort-ascending',
        name: 'Sort ascending',
      },
      {
        action: {
          type: 'price-descending',
        },
        icon: 'sort-descending',
        name: 'Sort descending',
      },
    ],
    width: 100,
    minWidth: 100,
    maxWidth: 160,
    renderComponent: {
      component: JobPriceStatus,
      value: 'price',
      optionalProps: ['status', 'financeStatus'],
      textClassName: 'text-14-22',
    },
  },
  {
    Header: 'Last updated',
    accessor: 'updatedAt',
    subAccessors: ['status', 'financeStatus'],
    items: [
      {
        action: {
          type: 'updated-ascending',
        },
        icon: 'sort-ascending',
        name: 'Newest First',
      },
      {
        action: {
          type: 'updated-descending',
        },
        icon: 'sort-descending',
        name: 'Oldest First',
      },
    ],
    width: 100,
    minWidth: 100,
    maxWidth: 160,
    renderComponent: {
      component: JobActivity,
      value: 'updatedAt',
      optionalProps: ['status', 'financeStatus'],
      textClassName: 'text-14-22',
    },
  },
];

export const DEFAULT_COLUMN = {
  minWidth: 80,
  width: 200,
  maxWidth: 400,
};
