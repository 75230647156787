import { useToast } from '@kanda-libs/ks-design-library';
import { actions, Cache, Company } from '@kanda-libs/ks-frontend-services';
import type { PostCompanyBillingSuccessRequestParameters } from '@kanda-libs/ks-frontend-services/src/generated/operations/postCompanyBillingSuccess';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import useQueryParam from 'hooks/useQueryParam';
import { PC_ENABLE_CACHE_KEY } from 'pages/Setup/PremiumCreditToggle/constants';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';

export type BillingSuccessHook = void;

export default function useBillingSuccess(): BillingSuccessHook {
  const dispatch = useAppDispatch();
  const { showSuccess, showError } = useToast();
  const { push } = useHistory();
  const [cid] = useQueryParam('cid');
  const [provider] = useQueryParam('provider');
  const [tier] = useQueryParam('tier');
  const { company } = useCurrentCompany();
  const handleApiError = useApiError(
    'Unknown error processing this payment - please contact Kanda',
  );
  const caches = useSelector(selectors.infoGetCache.getEntities);
  const me = useSelector(selectors.getAuth);

  const cache = useMemo(() => {
    const id = me.user?.id;
    if (!id) return undefined;
    return caches?.[id] || ({} as Cache);
  }, [me, caches]);

  const removeKeyFromCache = useCallback(
    (onSuccess: () => void) => {
      const body = {
        ...cache,
        [PC_ENABLE_CACHE_KEY]: '',
      } as unknown as Cache;
      dispatch(
        actions.infoPutCache({
          body,
          onSuccess,
        }),
      );
    },
    [cache, dispatch],
  );

  const checkSubscription = useCallback(() => {
    if (!cid) {
      showError(
        'Error marking subscription as successful due to missing company id. Please contact Kanda.',
      );
      return;
    }

    const formattedProvider =
      provider && provider !== 'null' ? provider : undefined;

    const formattedTier = tier && tier !== 'null' ? tier : undefined;

    const successUrl = formattedTier ? URLS.enterpriseRatesSuccess : URLS.home;

    dispatch(
      actions.postCompanyBillingSuccess({
        params: {
          id: cid,
          ...(formattedProvider && {
            provider:
              formattedProvider as PostCompanyBillingSuccessRequestParameters['provider'],
          }),
          ...(formattedTier && {
            tier: formattedTier as PostCompanyBillingSuccessRequestParameters['tier'],
          }),
        },
        onError: (err) => {
          handleApiError(err);
          showError(
            'Error marking subscription as successful. Please contact Kanda or refresh the page to try again',
          );
        },
        onSuccess: (companyData: Company) => {
          const { billing } = companyData;
          if (
            billing !== 'subscribed' &&
            billing !== 'referred' &&
            billing !== 'trial'
          ) {
            push(successUrl);
            return;
          }
          showSuccess('Subscription processed successfully');
          removeKeyFromCache(() => push(successUrl));
        },
        forceReload: true,
      }),
    );
  }, [
    cid,
    provider,
    dispatch,
    push,
    showSuccess,
    showError,
    tier,
    handleApiError,
    removeKeyFromCache,
  ]);

  const initRef = useRef<boolean>(false);
  useEffect(() => {
    if (!company || initRef.current) return;
    initRef.current = true;
    checkSubscription();
  }, [checkSubscription, company]);
}
