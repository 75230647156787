import { Field, Label } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import useSelectWidget from './useSelectWidget';

const AddressFieldsWidgetsSelect: React.FunctionComponent = function () {
  const {
    postcodeName,
    manual,
    enterManually,
    selectProps,
    data,
    showLabels,
    label,
  } = useSelectWidget();
  if (!postcodeName) return null;
  return (
    postcodeName && (
      <>
        <Label
          label={showLabels ? label : ''}
          helperText={
            !manual ? (
              <Button.Link
                id="apply-for-finance-your-info-address-enter-manually"
                label="Enter address manually"
                size={12}
                variant="turquoise"
                className="whitespace-nowrap mb-2"
                onClick={enterManually}
              />
            ) : undefined
          }
        />
        <Field.Address.Select
          postcodeName={postcodeName}
          data={data}
          {...selectProps}
        />
      </>
    )
  );
};

export default AddressFieldsWidgetsSelect;
