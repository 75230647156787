import { FormTheme } from '@kanda-libs/ks-component-ts';
import { useMemo, type ReactNode } from 'react';
import { BusinessForm, MeForm, WorkTypesForm } from './components';

export interface SetupAccountBusinessFormProps {
  schemaError: boolean;
  children: ReactNode;
  hideBox?: boolean;
  maxWidth?: string;
}

const SetupAccountBusinessForm: React.FunctionComponent<SetupAccountBusinessFormProps> =
  function ({
    schemaError,
    children,
    hideBox = false,
    maxWidth = 'max-w-120',
  }) {
    const className = useMemo(
      () =>
        [
          'flex flex-col flex-1',
          hideBox ? '' : 'md:border border-neutral-300 rounded-lg md:p-16',
        ].join(' '),
      [hideBox],
    );
    return (
      <div className={className}>
        <div
          className={`w-full flex-1 mx-auto flex flex-col gap-y-6 ${maxWidth}`}
        >
          <FormTheme variant="streamline">
            <MeForm />
            <BusinessForm schemaError={schemaError} />
            <WorkTypesForm />
          </FormTheme>
          {children}
        </div>
      </div>
    );
  };

export default SetupAccountBusinessForm;
