import { Button, Icon, Price } from '@kanda-libs/ks-design-library';

export interface HigherDepositChosenProps {
  setDeposit: number;
  chosenDeposit: number;
  difference: number;
  onAcknowledgeClick: () => void;
  acknowledged: boolean;
}

const HigherDepositChosen: React.FunctionComponent<HigherDepositChosenProps> =
  function ({
    setDeposit,
    chosenDeposit,
    difference,
    acknowledged,
    onAcknowledgeClick,
  }) {
    return (
      <div className="flex flex-col p-3 border border-orange-200 bg-orange-100 rounded-lg gap-y-1">
        <div className="flex flex-row gap-x-2">
          <Icon
            icon="error"
            stroke="orange-200"
            className="min-w-5 mb-auto"
            size={20}
          />
          <p className="text-14-22-em text-orange-200">
            Customer selected a higher deposit than the minimum set. You need to
            collect the difference
          </p>
        </div>
        <div className="grid grid-cols-[min-content_auto] text-14-22-em gap-x-2 text-neutral-700">
          <p>Chosen</p>
          <Price
            amount={chosenDeposit}
            className="ml-auto"
            color="neutral-700"
            poundsClassName="text-16-20-em"
            centsClassName="text-16-20-em"
          />
          <p>Mimimum</p>
          <Price
            amount={setDeposit}
            className="ml-auto"
            color="neutral-700"
            poundsClassName="text-16-20-em"
            centsClassName="text-16-20-em"
          />
          <div className="col-span-2 border-b border-orange-200 my-1" />
          <p>Difference</p>
          <Price
            amount={difference}
            className="ml-auto"
            poundsClassName="text-16-20-em"
            centsClassName="text-16-20-em"
            color="orange-200"
          />
        </div>
        {!acknowledged && (
          <Button.InlineLink
            id="acknowledge-higher-deposit"
            onClick={onAcknowledgeClick}
            label="Acknowledge Warning"
            className="mx-auto text-14-22-em text-orange-200"
            variant="custom"
          />
        )}
      </div>
    );
  };

export default HigherDepositChosen;
