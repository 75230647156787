import {
  type AddressApiResponseAddress,
  type ValidationProps,
} from '@kanda-libs/ks-component-ts';
import { useMemo } from 'react';
import { useAddressFieldsContext } from '../../context';

export interface SelectData {
  addresses: AddressApiResponseAddress[] | undefined;
}

export interface AddressFieldsSelectWidgetHook {
  postcodeName: string | undefined;
  manual: boolean;
  enterManually: () => void;
  selectProps: ValidationProps;
  data: SelectData;
  showLabels: boolean;
  label?: string;
}

export default function useSelectWidget(): AddressFieldsSelectWidgetHook {
  const {
    postcodeName,
    manual,
    enterManually,
    addresses,
    apiPostcode: postcode,
    selectProps,
    postcodeIsValidating,
    showLabels,
    labels,
  } = useAddressFieldsContext();

  const data = useMemo(
    () => ({
      addresses: addresses || undefined,
      postcode,
      isLoading: postcodeIsValidating,
    }),
    [addresses, postcode, postcodeIsValidating],
  );

  return {
    postcodeName,
    manual,
    enterManually,
    selectProps,
    data,
    showLabels,
    label: labels?.select,
  };
}
