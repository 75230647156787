import type { Customer } from '@kanda-libs/ks-frontend-services';
import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';

export interface JobCustomerHook {
  customer: Customer | undefined;
  isLoading: boolean;
}

export default function useJobCustomer(): JobCustomerHook {
  const { job, isLoading } = useJobContext();

  const customer = useMemo(() => {
    if (isLoading) return undefined;
    return job?.customer;
  }, [isLoading, job]);

  return {
    customer,
    isLoading,
  };
}
