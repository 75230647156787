import { LineItem } from 'components';
import useJobQuoteLineItems from './useJobQuoteLineItems';

const JobQuoteDetailsLineItems: React.FunctionComponent = function () {
  const { lineItems, isLoading } = useJobQuoteLineItems();
  return (
    <div className="flex flex-col gap-y-2">
      {lineItems.map((item) => (
        <LineItem
          item={item}
          isLoading={isLoading}
          variant="job-summary"
          key={item.title}
          forceMobile
        />
      ))}
    </div>
  );
};

export default JobQuoteDetailsLineItems;
