import { Text } from '@kanda-libs/ks-design-library';
import { formatToCurrency } from 'utils';

export interface JobPriceProps {
  price: number;
  priceClassName?: string;
  isLoading?: boolean;
}

const JobPrice: React.FunctionComponent<JobPriceProps> = function ({
  price,
  priceClassName = 'text-16-20-em',
  isLoading = false,
}) {
  if (price === 0)
    return (
      <Text
        text="No Price Added"
        className={`${priceClassName} text-neutral-700`}
        isLoading={isLoading}
      />
    );
  return (
    <Text
      text={formatToCurrency(price)}
      className={`${priceClassName} text-neutral-700 my-auto`}
      isLoading={isLoading}
      skeletonProps={{
        width: 80,
      }}
    />
  );
};

export default JobPrice;
