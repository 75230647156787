import type { ProgressColor } from '.';

export interface ProgressHeaderClassNames {
  main: string;
  base: string;
  container: string;
  wrapper: string;
  barsWrapper: string;
  bar: string;
  progress: string;
}

export const CLASS_NAMES: ProgressHeaderClassNames = {
  main: 'flex w-full border border-neutral-300 bg-neutral-000 px-4 py-2 md:p-6 rounded-xl shadow-card',
  base: 'text-14-22-em w-full text-left md:text-center md:first:text-left md:last:text-right',
  container: 'flex flex-row-reverse md:flex-col w-full gap-y-2 gap-x-4',
  wrapper: 'flex flex-col md:flex-row gap-x-2 w-full',
  barsWrapper: 'flex flex-row my-1 md:my-0 md:flex-col',
  bar: 'h-full w-2 md:w-full md:h-2 rounded-full bg-neutral-200',
  progress: 'rounded-full w-2 md:h-2 -ml-2 md:ml-0 md:-mt-2 transition-all',
};

export interface ProgressColors {
  background: string;
  text: string;
}

export const COLORS: Record<ProgressColor, ProgressColors> = {
  green: {
    background: 'bg-green-500',
    text: 'text-green-500',
  },
  red: {
    background: 'bg-red-200',
    text: 'text-red-200',
  },
};
