import { FormTheme, Widget } from '@kanda-libs/ks-component-ts';
import { Button, Text } from '@kanda-libs/ks-design-library';
import AddressFields from 'components/Address';
import { NATIONAL_INSURANCE_NUMBER_VALIDATION } from './constants';
import useSoleTraderInfo from './useSoleTraderInfo';

interface CompanyDetailsFormSoleProps {
  isLoading: boolean;
}

const CompanyDetailsFormSole: React.FunctionComponent<CompanyDetailsFormSoleProps> =
  function ({ isLoading }) {
    useSoleTraderInfo(isLoading);
    return (
      <FormTheme variant="streamline">
        <Text
          text="Sole Trader Company Details"
          className="text-14-22-em text-neutral-900"
          isLoading={isLoading}
        />
        <Widget.CompanySoleTraderInfoTradingName
          label="Business/Trading Name (if different from your name)"
          placeholder="Business/Trading Name"
        />
        <Widget.CompanySoleTraderInfoNationalInsuranceNumber
          validation={NATIONAL_INSURANCE_NUMBER_VALIDATION}
          label="National insurance (NI) number"
          placeholder="e.g. GB 123456 A"
        />
        <AddressFields baseFieldName="company.sole_trader_info.trading_address" />
        <Button.Text
          submit
          id="my-account-company-information-company-details-submit"
          label="Update Company Details"
          size={40}
          className="ml-auto"
        />
      </FormTheme>
    );
  };

export default CompanyDetailsFormSole;
