import { Text } from '@kanda-libs/ks-design-library';
import { type ReactNode } from 'react';

interface InfoCardProps {
  children: ReactNode;
  title: string;
  headerAppend?: ReactNode;
  isLoading?: boolean;
}

const InfoCard: React.FunctionComponent<InfoCardProps> = function ({
  children,
  title,
  headerAppend,
  isLoading = false,
}) {
  return (
    <div className="flex flex-col gap-y-4 text-left">
      <div className="flex flex-row justify-between">
        <Text
          text={title}
          isLoading={isLoading}
          className="text-16-20-em text-neutral-900"
        />
        {headerAppend}
      </div>
      <div className="flex flex-col gap-y-2 p-3 md:p-4 border border-neutral-300 rounded-lg shadow-card">
        {children}
      </div>
    </div>
  );
};

export default InfoCard;
