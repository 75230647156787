import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import { type StringIndexedObject } from '@kanda-libs/ks-design-library';
import useQuery from 'hooks/useQuery';
import { useCallback, useMemo } from 'react';
import { type QueryType } from 'store/slices/query';

export interface SearchBarFormValues {
  search: string;
}

export interface SearchBarFormHook {
  form: UseFormReturn<SearchBarFormValues>;
  onSubmit: (formValues: StringIndexedObject<SearchBarFormValues>) => void;
  onClick: () => void;
  showSearchButton: boolean;
}

export default function useSearchBarForm(
  queryType: QueryType,
): SearchBarFormHook {
  const { fetchedQuery, onSetQuery: setQuery } = useQuery(queryType);

  const form = useForm<SearchBarFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      search: fetchedQuery,
    },
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<SearchBarFormValues>) => {
      const fv = formValues as unknown as SearchBarFormValues;
      setQuery(fv.search);
    },
    [setQuery],
  );

  const onClick = useCallback(() => {
    form.setValue('search', '');
    setQuery('');
  }, [form, setQuery]);

  const showSearchButton = useMemo(() => fetchedQuery === '', [fetchedQuery]);

  return {
    form,
    onSubmit,
    onClick,
    showSearchButton,
  };
}
