import { useJobContext } from 'pages/Job/context';

export interface JobActionsButtonsHook {
  id: string | undefined;
  status: string;
  downloadPdf: (id: string) => void;
}

export default function useJobActionsButtons(): JobActionsButtonsHook {
  const { id, status, downloadPdf } = useJobContext();
  return { id, status, downloadPdf };
}
