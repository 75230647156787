import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';
import type { Totals } from 'types';
import { calculateJobTotal } from 'utils';

export interface JobQuoteTotalsHook {
  totals: Totals;
  deposit: number | undefined;
  isLoading: boolean;
}

export default function useJobQuoteTotals(): JobQuoteTotalsHook {
  const { job, isLoading } = useJobContext();

  const totals = useMemo(() => calculateJobTotal(job?.job_items || []), [job]);

  const deposit = useMemo(() => {
    if (isLoading) return undefined;
    const depositType = job?.deposit_type;
    if (!depositType || depositType === 'no_deposit') return undefined;
    const jobDeposit = job?.deposit_value?.amount;
    if (!jobDeposit || jobDeposit === 0) return undefined;
    return jobDeposit;
  }, [isLoading, job]);

  return {
    totals,
    deposit,
    isLoading,
  };
}
