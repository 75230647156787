import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import { actions, type Company } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useMemo, useState } from 'react';
import { useMyAccountCompanyInformationContext } from '../../context';
import { getCompanyBody } from './helpers';

export type CompanyDetailsFieldValues = {
  company: Company;
};

export interface CompanyDetailsFormHook {
  form: UseFormReturn<CompanyDetailsFieldValues>;
  onSubmit: (
    formValues: StringIndexedObject<CompanyDetailsFieldValues>,
  ) => void;
  isLoading: boolean;
  isLimitedCompany: boolean;
  schemaError: boolean;
}

export default function useCompanyDetailsForm(): CompanyDetailsFormHook {
  const [schemaError, setSchemaError] = useState<boolean>(false);

  const { company, isLoading } = useMyAccountCompanyInformationContext();
  const dispatch = useAppDispatch();
  const { showError, showSuccess } = useToast();
  const onCompanyError = useApiError('Error updating company info');

  const form = useForm<CompanyDetailsFieldValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const isLimitedCompany = useMemo(() => {
    if (!company) return false;
    return company.company_type === 'limited_company';
  }, [company]);

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<CompanyDetailsFieldValues>) => {
      if (!company || !company.id) return;
      const { id } = company;
      const body = getCompanyBody(
        company,
        formValues as unknown as CompanyDetailsFieldValues,
      );
      if (!body) {
        showError('Error with form info');
        return;
      }
      dispatch(
        actions.putCompany({
          params: { id },
          body,
          onError: (error) => {
            if (error?.message?.includes('schema')) {
              showError('Error with company info - please fill missing fields');
              setSchemaError(true);
              return;
            }
            onCompanyError(error);
          },
          onSuccess: () => {
            showSuccess('Company details updated');
          },
        }),
      );
    },
    [company, dispatch, onCompanyError, showError, showSuccess],
  );

  return {
    form,
    onSubmit,
    isLoading,
    isLimitedCompany,
    schemaError,
  };
}
