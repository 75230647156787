import { type Company } from '@kanda-libs/ks-frontend-services';
import type { BusinessConfigFormValues } from './useBusinessConfigForm';

export const formatBody = (
  company: Company,
  formValues: BusinessConfigFormValues,
): Company => ({
  ...company,
  business_config: formValues.company.business_config,
});
