import useQuery from 'hooks/useQuery';
import useSearchWords from 'hooks/useSearchWords';
import type { FormattedJobType } from 'pages/Jobs/helpers';
import { useMemo } from 'react';
import { type QueryType } from 'store/slices/query';

export interface JobNameTypeHook {
  name: string;
  searchWords: string[];
  showTags: boolean;
  showExample: boolean;
  showDraft: boolean;
  showLead: boolean;
}

export default function useJobNameType(
  firstName: string,
  lastName: string,
  type: FormattedJobType[],
  queryType: QueryType,
  hideTag: boolean,
): JobNameTypeHook {
  const { fetchedQuery: query } = useQuery(queryType);
  const { searchWords } = useSearchWords(query);

  const showExample = useMemo(() => type.includes('example'), [type]);

  const showDraft = useMemo(() => type.includes('draft'), [type]);

  const showLead = useMemo(() => type.includes('lead'), [type]);

  const showTags = useMemo(() => {
    if (hideTag) return false;
    return showExample || showDraft || showLead;
  }, [hideTag, showExample, showDraft, showLead]);

  const name = useMemo(() => {
    if (showExample && !firstName) return 'Joe Bloggs';
    return [firstName, lastName].filter(Boolean).join(' ');
  }, [showExample, firstName, lastName]);

  return {
    name,
    searchWords,
    showExample,
    showDraft,
    showLead,
    showTags,
  };
}
