import { ModalContainer, ModalLayoutCenter } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import type { MutableRefObject } from 'react';
import { Link } from 'react-router-dom';

import { URLS } from '../../../../config';
import { ADD_DETAILS_MODAL_ID } from './constants';

const JobAddBankDetailsModal = () => (
  <ModalContainer id={ADD_DETAILS_MODAL_ID}>
    {({ id, handleClose, ref }) => (
      <>
        <ModalLayoutCenter
          id={id}
          onClose={handleClose}
          ref={ref as MutableRefObject<HTMLDivElement>}
          className="w-10/12 md:w-100"
        >
          <div className="flex flex-col">
            <span className="mb-4 text-14-22-em">Add your bank details</span>
            <span className="mb-4">
              You need to add your bank details so Kanda know which account to
              pay out into. Please add them, then return here to mark this job
              as finished
            </span>
            <Link to={URLS.companyInformation} className="w-full">
              <Button.Text
                id="job-details-add-bank-details-modal"
                label="Add bank details"
                onClick={handleClose}
                className="w-full"
              />
            </Link>
          </div>
        </ModalLayoutCenter>
      </>
    )}
  </ModalContainer>
);

export default JobAddBankDetailsModal;
