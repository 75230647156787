import { LoadingSpinner } from '@kanda-libs/ks-design-library';
import iwocaLogoImage from 'assets/iwocapaylogowhite.png';

export interface IwocaPayButtonProps {
  onClick: () => void;
  isSubmitting: boolean;
}

const IwocaPayButton: React.FunctionComponent<IwocaPayButtonProps> = function ({
  onClick,
  isSubmitting,
}) {
  return (
    <button
      className="flex flex-row gap-x-1 justify-center bg-[#fb5950] hover:opacity-90 px-4 py-3 rounded-lg w-full"
      type="button"
      onClick={onClick}
      disabled={isSubmitting}
    >
      {isSubmitting ? (
        <LoadingSpinner size={32} className="" stroke="neutral-000" />
      ) : (
        <>
          <span className="flex flex-row my-auto text-16-24-em text-neutral-000">
            Pay with{' '}
          </span>
          <img src={iwocaLogoImage} alt="iwocaPay" className="h-8" />
        </>
      )}
    </button>
  );
};

export default IwocaPayButton;
