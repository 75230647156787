import {
  FlowType,
  type Enquiry,
  type FinanceStatus,
  type Job,
} from '@kanda-libs/ks-frontend-services';
import { pipe } from 'fp-ts/lib/function';
import get from 'lodash.get';
import type { JobNonNullStatus, JobsFilter } from 'store/slices/filters';
import type { BaseOrder, JobSorting } from 'store/slices/sorting';
import { formatAddress } from 'utils/Address-functions';
import { NO_CUSTOMER } from './constants';

export type FormattedJobType = 'draft' | 'lead' | 'sent' | 'example';

export interface FormattedJob {
  id: string;
  status?: Job['status'];
  financeStatus?: FinanceStatus;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  title: string;
  price: number;
  type: FormattedJobType[];
  flowType: FlowType;
  updatedAt: Date;
}

export const getFormattedJobType = (
  job: Job,
  leadIds: string[],
): FormattedJobType[] => {
  const { status, flow_type: flowType } = job;
  if (!status) {
    if (!flowType || flowType === 'live') return ['draft'];
    return ['draft', 'example'];
  }
  const isFromLead = leadIds.includes(job?.id || '');
  const draftOrSent = status === 'draft' ? 'draft' : 'sent';
  if (isFromLead) {
    if (!flowType || flowType === 'live') return ['lead', draftOrSent];
    return ['example', 'lead', draftOrSent];
  }
  if (!flowType || flowType === 'live') return [draftOrSent];
  return ['example', draftOrSent];
};

export interface JobCustomerData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
}

export const getCustomerData = (job: Job): JobCustomerData => {
  const customer = job?.customer;
  if (!customer) return NO_CUSTOMER;
  return {
    firstName: customer.first_name,
    lastName: customer.last_name,
    email: customer.email,
    phone: customer.phone,
    address: formatAddress(customer.address, ''),
  };
};

export const formatJobs = (jobs: Job[], leads: Enquiry[]): FormattedJob[] => {
  const leadIds = leads.map((lead) => lead.id);
  return jobs.reduce((final: FormattedJob[], current: Job) => {
    const { id } = current;
    if (!id) return final;
    const type = getFormattedJobType(current, leadIds);
    const customer = getCustomerData(current);
    const datum: FormattedJob = {
      id,
      type,
      status: current.status,
      financeStatus: current.finance_status,
      title: current.title || 'No title added',
      price: current.total?.amount_vat_inclusive?.amount || 0,
      updatedAt: new Date(current?.metadata?.updated_at || 0),
      flowType: current.flow_type || 'live',
      ...customer,
    };
    return [...final, datum];
  }, []);
};

/**
 * Sorts data by date fetched via accessor
 */
const sortByDate = <T>(data: T[], order: BaseOrder, accessor: keyof T): T[] =>
  data.sort((datum1: T, datum2: T) => {
    const datum1Date = new Date(get(datum1, accessor) || 0).getTime();
    const datum2Date = new Date(get(datum2, accessor) || 0).getTime();
    if (order === 'INVERSE') return datum1Date - datum2Date;
    return datum2Date - datum1Date;
  });

/**
 * Sorts data by string fetched via accessor
 */
const sortByString = <T>(
  data: T[],
  order: BaseOrder,
  accessors: (keyof T)[],
  delimiter = ' ',
): T[] =>
  data.sort((datum1: T, datum2: T) => {
    const datum1String = accessors
      .map((accessor: keyof T) => get(datum1, accessor) || undefined)
      .filter(Boolean)
      .join(delimiter);
    const datum2String = accessors
      .map((accessor: keyof T) => get(datum2, accessor) || undefined)
      .filter(Boolean)
      .join(delimiter);
    if (!datum1String && !datum2String) return 1;
    if (!datum1String) return 1;
    if (!datum2String) return -1;
    if (order === 'INVERSE') return datum2String.localeCompare(datum1String);
    return datum1String.localeCompare(datum2String);
  });

/**
 * Sorts data by string fetched via accessor
 */
const sortByNumber = <T>(data: T[], order: BaseOrder, accessor: keyof T): T[] =>
  data.sort((datum1: T, datum2: T) => {
    const datum1Num = get(datum1, accessor);
    const datum2Num = get(datum2, accessor);
    if (!datum1Num && !datum2Num) return 1;
    if (!datum1Num) return 1;
    if (!datum2Num) return -1;
    if (order === 'INVERSE') return datum2Num - datum1Num;
    return datum1Num - datum2Num;
  });

export const sortJobsData = (
  jobs: FormattedJob[],
  sorting: JobSorting,
  order: BaseOrder,
): FormattedJob[] => {
  if (sorting === 'UPDATED_AT') return sortByDate(jobs, order, 'updatedAt');
  if (sorting === 'FIRST_NAME') return sortByString(jobs, order, ['firstName']);
  if (sorting === 'LAST_NAME') return sortByString(jobs, order, ['lastName']);
  if (sorting === 'EMAIL') return sortByString(jobs, order, ['email']);
  if (sorting === 'PHONE') return sortByString(jobs, order, ['phone']);
  if (sorting === 'TITLE') return sortByString(jobs, order, ['title']);
  if (sorting === 'PRICE') return sortByNumber(jobs, order, 'price');
  if (sorting === 'STATUS') return sortByString(jobs, order, ['status']);
  if (sorting === 'FINANCE_STATUS')
    return sortByString(jobs, order, ['financeStatus']);
  if (sorting === 'ID') return sortByString(jobs, order, ['id']);
  return jobs;
};

export const filterByStatuses = (
  jobs: FormattedJob[],
  statuses?: JobNonNullStatus[],
): FormattedJob[] => {
  if (
    !statuses ||
    statuses.length === 0 ||
    statuses.every((status) => status === undefined)
  )
    return jobs;
  return jobs.filter((job) => job.status && statuses.includes(job.status));
};

export const filterByFinanceStatuses = (
  jobs: FormattedJob[],
  financeStatuses?: FinanceStatus[],
): FormattedJob[] => {
  if (
    !financeStatuses ||
    financeStatuses.length === 0 ||
    financeStatuses.every((status) => status === undefined)
  )
    return jobs;
  return jobs.filter(
    (job) => job.financeStatus && financeStatuses.includes(job.financeStatus),
  );
};

export const filterJobs = (
  jobs: FormattedJob[],
  filter: JobsFilter,
): FormattedJob[] =>
  pipe(
    jobs,
    (all) => filterByStatuses(all, filter?.status),
    (filtered) => filterByFinanceStatuses(filtered, filter?.financeStatus),
  );

export const getNewOrder = (
  type: string | undefined,
  currentOrder: BaseOrder,
): BaseOrder => {
  if (!type) return 'NORMAL';
  if (type.includes('alternate'))
    return currentOrder === 'NORMAL' ? 'INVERSE' : 'NORMAL';
  return type.includes('descending') ? 'INVERSE' : 'NORMAL';
};

export const getNewSorting = (type?: string): JobSorting => {
  if (type?.includes('updated')) return 'UPDATED_AT';
  if (type?.includes('first-name')) return 'FIRST_NAME';
  if (type?.includes('last-name')) return 'LAST_NAME';
  if (type?.includes('email')) return 'EMAIL';
  if (type?.includes('phone')) return 'PHONE';
  if (type?.includes('price')) return 'PRICE';
  if (type?.includes('status')) return 'STATUS';
  if (type?.includes('finance-status')) return 'FINANCE_STATUS';
  if (type?.includes('id')) return 'ID';
  return 'UPDATED_AT';
};

export const filterJobsForArchiveStatus = (
  jobs: Job[],
  archive = false,
): Job[] =>
  jobs.filter((job) =>
    archive ? job.archived === 'yes' : job.archived !== 'yes',
  );
