import { Icon, Loader, Text } from '@kanda-libs/ks-design-library';

import Container from './Custom-container';

import { ReactComponent as JobPDF } from '../../../../../assets/job-pdf.svg';

const ViewComponent = () => (
  <Container>
    {({ name, onQuoteDocumentClick, isSubmitting }) => (
      <div className="flex flex-col gap-y-1">
        <p className="text-12-18-em text-neutral-700">
          Uploaded PDF (click to download)
        </p>
        <button
          onClick={onQuoteDocumentClick}
          type="button"
          aria-label="quote-line-item-download"
          className="flex flex-col bg-neutral-100 border border-neutral-200 rounded-xl overflow-hidden"
        >
          <div className="p-3 h-38">
            <JobPDF className="mx-auto" />
          </div>
          <div className="flex flex-row bg-neutral-000 border-t border-neutral-200 px-3 py-3.5">
            <Icon
              icon="file"
              stroke="green-300"
              className="mr-3 my-auto"
              size={20}
            />
            <Text text={name} className="text-14-22 text-neutral-700 my-auto" />
          </div>
        </button>
        <Loader isLoading={isSubmitting} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Desktop-Custom-view';

export default ViewComponent;
