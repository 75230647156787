import { Loader, Text } from '@kanda-libs/ks-design-library';
import {
  BankDetailsForm,
  CompanyDetailsForm,
  ContactForm,
  LogoForm,
  TradingNameForm,
  VatForm,
} from './components';

import { CLASS_NAMES, TITLE } from './constants';
import { useMyAccountCompanyInformationContext } from './context';

const Content: React.FunctionComponent = function ({}) {
  const { isLoading, isSubmitting } = useMyAccountCompanyInformationContext();
  return (
    <div className="flex flex-col gap-y-4">
      <Text
        isLoading={isLoading}
        text={TITLE}
        className={CLASS_NAMES.title}
        skeletonProps={{
          width: 200,
        }}
      />
      <LogoForm />
      <CompanyDetailsForm />
      <TradingNameForm />
      <VatForm />
      <ContactForm />
      <BankDetailsForm />
      {/* TODO: Add in:
      - Company Info
      - Insurance */}
      <Loader isLoading={isSubmitting} />
    </div>
  );
};

export default Content;
