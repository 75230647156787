import JobCustomer from 'pages/Job/components/JobCustomer';
import JobFinanceApplicationProgress from 'pages/Job/components/JobFinanceApplicationProgress';
import JobFinanceOptions from 'pages/Job/components/JobFinanceOptions';
import JobProgressBar from 'pages/Job/components/JobProgressBar';
import JobQuoteDetails from 'pages/Job/components/JobQuoteDetails';

const LeadDesktopContent: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col flex-1 -mt-8">
      <JobProgressBar />
      <div className="grid grid-cols-[minmax(100px,2fr)_1fr] gap-x-8 xl:gap-x-12 w-full max-w-screen-lg mx-auto">
        <JobQuoteDetails />
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-6 order-2">
            <JobFinanceApplicationProgress />
            <JobCustomer />
          </div>
          <JobFinanceOptions />
        </div>
      </div>
    </div>
  );
};

export default LeadDesktopContent;
