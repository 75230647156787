import LimitedCompanyFields from 'pages/Setup/Account/CompanyForm/components/LimitedCompanyFields';

export interface CompanyDetailsFormLimitedFieldsProps {
  schemaError: boolean;
}

const CompanyDetailsFormLimitedFields: React.FunctionComponent<CompanyDetailsFormLimitedFieldsProps> =
  function ({ schemaError }) {
    return (
      <LimitedCompanyFields
        schemaError={schemaError}
        showToggleButton={false}
      />
    );
  };

export default CompanyDetailsFormLimitedFields;
