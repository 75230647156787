import { Form, FormTheme, Widget } from '@kanda-libs/ks-component-ts';
import { Button, Text } from '@kanda-libs/ks-design-library';
import {
  ACCOUNT_NAME_VALIDATION,
  ACCOUNT_NUMBER_VALIDATION,
  SORT_CODE_VALIDATION,
} from './constants';
import useBankDetailsForm from './useBankDetailsForm';

const BankDetailsForm: React.FunctionComponent = function ({}) {
  const { form, onSubmit, isLoading, isSubmitting } = useBankDetailsForm();
  return (
    <FormTheme variant="streamline">
      <Form
        form={form}
        onSubmit={onSubmit}
        id="my-account-company-information-bank-details-form"
      >
        <div className="flex flex-col">
          <Text
            text="Bank Details"
            className="text-14-22-em text-neutral-900 mb-4"
            isLoading={isLoading}
          />
          <Widget.BankAccountAccountName validation={ACCOUNT_NAME_VALIDATION} />
          <div className="flex flex-col md:flex-row gap-x-3">
            <Widget.BankAccountAccountNumber
              validation={ACCOUNT_NUMBER_VALIDATION}
            />
            <Widget.BankAccountSortCode
              validation={SORT_CODE_VALIDATION}
              type="sortCode"
            />
          </div>
          <Button.Text
            submit
            id="my-account-company-information-bank-details-submit"
            label="Update Bank Details"
            size={40}
            className="ml-auto"
            disabled={isSubmitting}
          />
        </div>
      </Form>
    </FormTheme>
  );
};

export default BankDetailsForm;
