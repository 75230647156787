import { isBlackFriday } from 'utils';
import type { SubscriptionCardProps } from './SubscriptionCard';
import type { Feature } from './SubscriptionCard/Features';

export const PREMIUM_CREDIT_FEATURES: Feature[] = [
  { text: 'Paid monthly via PremiumCredit' },
  { text: 'Backed by personal gurantee' },
  { text: 'Access to Kanda for 12 months' },
];

export const IWOCA_FEATURES: Feature[] = [
  { text: 'Paid monthly via iwocaPay' },
  { text: 'No payment taken for 30 days', className: 'font-bold' },
  { text: 'Access to Kanda for 12 months' },
];

export const STANDARD_YEARLY_CARD: SubscriptionCardProps = {
  variant: 'highlight',
  title: isBlackFriday() ? 'Yearly - Black Friday Deal' : 'Yearly',
  subscription: {
    amount: isBlackFriday() ? 699 : 750,
    frequency: isBlackFriday() ? 'first year' : 'year',
  },
  bullets: [isBlackFriday() ? 'inc. VAT' : 'excl. VAT', 'over 30% cheaper'],
  features: [
    { text: 'Priority set up' },
    { text: 'Pay securely by card' },
    {
      text: isBlackFriday()
        ? 'Access to Kanda for 12 months'
        : 'Access to Kanda for 12 months',
    },
  ],
  buttonProps: {
    id: 'setup-subscription-yearly',
    label: isBlackFriday() ? 'Buy now, save £200' : 'Yearly',
    icon: 'arrow-right',
    variant: 'gradient',
    className: 'mt-6 md:mt-10',
  },
};

export const STANDARD_MONTHLY_CARD: SubscriptionCardProps = {
  variant: 'standard',
  title: 'Monthly',
  subscription: {
    amount: 90,
    frequency: 'month',
  },
  bullets: ['excl. VAT', '12-month contract paid monthly'],
  features: [],
  buttonProps: {
    id: 'setup-subscription-monthly',
    label: 'Monthly',
    icon: 'arrow-right',
    variant: isBlackFriday() ? 'outline' : 'gradient',
    className: 'mt-6 md:mt-10',
  },
};

export const HUMM_YEARLY_CARD: SubscriptionCardProps = {
  variant: 'highlight',
  title: 'Yearly',
  subscription: {
    amount: 500,
    frequency: 'year',
  },
  bullets: ['excl. VAT'],
  features: [
    { text: 'Priority set up' },
    { text: 'Pay securely by card' },
    { text: 'Access to Kanda for 12 months' },
  ],
  buttonProps: {
    id: 'setup-humm-subscription-yearly',
    label: 'Subscribe',
    icon: 'arrow-right',
    variant: 'gradient',
    className: 'mt-6 md:mt-10',
  },
};

export const HUMM_MONTHLY_CARD: SubscriptionCardProps = {
  variant: 'standard',
  title: 'Monthly',
  subscription: {
    amount: 55,
    frequency: 'month',
  },
  bullets: ['excl. VAT', '12-month contract paid monthly'],
  features: [],
  buttonProps: {
    id: 'setup-humm-subscription-monthly',
    label: 'Monthly',
    icon: 'arrow-right',
    variant: 'gradient',
    className: 'mt-6 md:mt-10',
  },
};

export const REFERRAL_DISCOUNT = 50;
