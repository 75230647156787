import ProgressIndicator from 'components/ProgressIndicator';
import useJobProgressBar from './useJobProgressBar';

const JobProgressBar: React.FunctionComponent = function () {
  const { isLoading, redBar, steps, showBar } = useJobProgressBar();
  if (!showBar) return null;
  return (
    <div className="flex w-full max-w-screen-lg md:-mt-20 md:mb-12 z-40 mx-auto">
      <ProgressIndicator
        steps={steps}
        isLoading={isLoading}
        color={redBar ? 'red' : 'green'}
      />
    </div>
  );
};

export default JobProgressBar;
