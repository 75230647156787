import { Form, FormTheme } from '@kanda-libs/ks-component-ts';
import { BreakPoints, Loader } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import { WorkTypesForm } from '../Account/BusinessForm/components';
import { Layout } from '../components';
import SetupBusinessConfigSubmitButton from './SubmitButton';
import useBusinessConfigForm from './useBusinessConfigForm';

const SetupBusinessConfig: React.FunctionComponent = function () {
  const { form, onSubmit, isSubmitting } = useBusinessConfigForm();
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      id="setup-business-config"
      className="flex flex-col"
    >
      <Layout
        mobileFooter={
          <SetupBusinessConfigSubmitButton isSubmitting={isSubmitting} />
        }
      >
        <Helmet title="Setup Business" />
        <p className="text-24-28-em md:text-40-48-em mb-4 md:mb-6">
          We seem to be missing some information about your business
        </p>
        <p className="text-14-22-em md:text-18-22-em mb-6 md:mb-16 text-neutral-700">
          Please let us know the following missing information so we can keep
          our records correct and up to date
        </p>
        <div className="flex flex-col flex-1">
          <div className="w-full flex-1 mx-auto flex flex-col gap-y-6 max-w-120">
            <FormTheme variant="streamline">
              <WorkTypesForm />
            </FormTheme>
            <BreakPoints
              desktop={
                <SetupBusinessConfigSubmitButton isSubmitting={isSubmitting} />
              }
            />
          </div>
        </div>
        <Loader isLoading={isSubmitting} />
      </Layout>
    </Form>
  );
};

export default SetupBusinessConfig;
