import { Widget } from '@kanda-libs/ks-component-ts';
import { capitalise } from 'utils';

export const getFieldWidgetTag = (
  fieldName: string,
  prefix = '',
): keyof typeof Widget => {
  const formattedName = capitalise(fieldName);
  return `${prefix}${formattedName}` as keyof typeof Widget;
};

export const getWidgetPrefix = (rootName: string): string => {
  if (!rootName) return '';
  return rootName
    .split('.')
    .map((part: string) =>
      part
        .split('_')
        .map((subPart: string) => capitalise(subPart))
        .join(''),
    )
    .join('');
};
