import { Card, NoRates, Options } from '../components';
import { SUBTITLE, TITLE } from './constants';
import useRegulatedRates from './useRegulatedRates';

const FinanceRatesRegulated: React.FunctionComponent = function () {
  const { rates, hideNoRates, hasRates } = useRegulatedRates();
  if (!hasRates && hideNoRates) return null;
  return (
    <Card title={TITLE} subtitle={SUBTITLE}>
      {rates && hasRates ? (
        <Options rates={rates} toggleable={false} />
      ) : (
        <NoRates />
      )}
    </Card>
  );
};

export default FinanceRatesRegulated;
