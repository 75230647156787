import { Icon } from '@kanda-libs/ks-design-library';

const CancelledBanner: React.FunctionComponent = function ({}) {
  return (
    <div className="-mt-4 md:-mt-6 px-4 py-3 flex flex-row gap-x-2 border border-orange-200 bg-orange-100 rounded-lg text-orange-200 text-14-22-em mb-4">
      <Icon icon="warning" size={20} className="min-w-5 mb-auto mt-px" />
      Your previous billing method has either been cancelled or unsuccessful.
      Please select the payment method you would like to use. If you previously
      chose to pay monthly via iwoca and were declined, you can still use our
      other monthly payment provider, PremiumCredit. Book a call with our team
      if you would like to pay via PremiumCredit
    </div>
  );
};

export default CancelledBanner;
