import { ModalsWrapperContext } from '@kanda-libs/ks-design-library';
import type { Payment, Pence } from '@kanda-libs/ks-frontend-services';
import { useJobContext } from 'pages/Job/context';
import { useCallback, useContext, useMemo } from 'react';
import { BYPASS_OMNI_IDS } from '../../../../constants';
import { ADD_DETAILS_MODAL_ID } from '../JobAddBankDetailsModal/constants';
import { MARK_FINISHED_MODAL_ID } from '../JobMarkAsFinishedModal/constants';

export interface JobMarkAsFinishedButtonHook {
  showButton: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  companyIsLoading: boolean;
  showOmniWarning: boolean;
  isSubmitting: boolean;
}

export default function useJobMarkAsFinishedButton(): JobMarkAsFinishedButtonHook {
  const { job, company, isLoading, isSubmitting } = useJobContext();

  const { showModal } = useContext(ModalsWrapperContext);

  const financeStatus = useMemo(
    () => job?.finance_status || 'not_applied',
    [job],
  );

  const provider = useMemo(
    () => job?.customer_options?.finance_option?.provider,
    [job],
  );

  const latestLoanPayment = useMemo(() => {
    if (!job) return undefined;
    const payments = job?.payments;
    if (!payments || payments.length === 0) return undefined;
    const loans = [...payments].filter(
      (p: Payment) => p.payment_option.payment_method === 'loan',
    );
    if (!loans || loans.length === 0) return undefined;
    if (loans.length === 1) return loans[0];
    const sorted = loans.sort(
      (l1: Payment, l2: Payment) =>
        new Date(l2?.metadata?.created_at || 0).getTime() -
        new Date(l1?.metadata?.created_at || 0).getTime(),
    );
    return sorted[0];
  }, [job]);

  const showButton = useMemo(() => {
    if (!job || !company || isLoading || !provider) return false;
    if (job.status !== 'accepted') return false;
    if (!job?.total?.amount_vat_inclusive?.amount) return false;

    const { payments = [] } = job || {};

    return (
      payments.reduce(
        (acc: Pence, payment: Payment) =>
          payment.status === 'paid'
            ? acc + payment.payment_option.amount.amount
            : acc,
        0,
      ) >= job.total.amount_vat_inclusive.amount
    );
  }, [job, isLoading, company, provider]);

  const showOmniWarning = useMemo(() => {
    if (!provider || !latestLoanPayment) return false;
    const loanId = latestLoanPayment?.id;
    if (!loanId) return provider === 'omni';
    if (BYPASS_OMNI_IDS.includes(loanId)) return false;
    return provider === 'omni';
  }, [provider, latestLoanPayment]);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (
        ['payout_pending', 'financed'].includes(financeStatus) &&
        !company?.bank_account?.account_name
      ) {
        showModal(ADD_DETAILS_MODAL_ID);
        return;
      }
      showModal(MARK_FINISHED_MODAL_ID);
    },
    [showModal, financeStatus, company],
  );
  return {
    showButton,
    onClick,
    companyIsLoading: !company,
    showOmniWarning,
    isSubmitting,
  };
}
