import { Button, Loader } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import { Layout } from 'pages/Setup/components';
import { SETUP_BULLETS } from './constants';
import InfoCard from './InfoCard';
import IwocaPayButton from './IwocaPayButton';
import useIwocaPay from './useIwocaPay';

const IwocaPayPage: React.FunctionComponent = function ({}) {
  const { onClick, isSubmitting, onBackClick, needBullets } = useIwocaPay();
  return (
    <Layout mobileFooter={null} showIndicator={false}>
      <Helmet title="iwocaPay" />
      <div className="flex flex-col w-full max-w-100 mx-auto">
        <Button.Icon
          icon="arrow-left"
          id="iwoca-pay-back"
          onClick={onBackClick}
          className="-ml-3"
        />
        <p className="text-32-40-em mt-2 mb-6">How iwocaPay works</p>
        <p className="text-14-22 md:text-16-24 mb-4 text-neutral-800">
          Kanda have partnered with iwocaPay to allow you to spread the cost of
          your subscription payment over 3 or 12 months interest free.
        </p>
        <p className="text-14-22 md:text-16-24 mb-8 text-neutral-800">
          To subscribe via iwocaPay, just cick the <b>Pay with iwocaPay</b>{' '}
          button below.
        </p>
        <InfoCard
          icon="user-square"
          title="What you'll need"
          bullets={needBullets}
        />
        <div className="mb-4" />
        <InfoCard
          icon="file"
          title="Setting up iwocaPay"
          bullets={SETUP_BULLETS}
        />
        <p className="text-14-22 md:text-16-24 my-8 text-neutral-800">
          Not ready right now? No problem, just click the back button for now
          and come back later.
        </p>
        <IwocaPayButton onClick={onClick} isSubmitting={isSubmitting} />
        <Button.Link
          id="iwoca-pay-text-back"
          label="Go back"
          variant="custom"
          className="text-neutral-900 mt-6"
          onClick={onBackClick}
        />
        <Loader isLoading={isSubmitting} />
      </div>
    </Layout>
  );
};

export default IwocaPayPage;
