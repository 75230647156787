import { Icon } from '@kanda-libs/ks-design-library';

const PremiumCreditBanner: React.FunctionComponent = function ({}) {
  return (
    <div className="-mt-4 md:-mt-6 px-4 py-3 flex flex-row gap-x-2 border border-orange-200 bg-orange-100 rounded-lg text-orange-200 text-14-22-em mb-4">
      <Icon icon="warning" size={20} className="min-w-5 mb-auto mt-px" />
      You are currently set up to have PremiumCredit as your pay monthly
      provider. If this is an error, or you would prefer to pay monthly with
      iwocaPay instead, please book a call with our sales team or contact Kanda
      for assistance.
    </div>
  );
};

export default PremiumCreditBanner;
