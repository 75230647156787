import { CompanyBanner, DesktopLayout } from '../../components';
import { Content } from '../components';

const ViewComponent = ({}) => (
  <DesktopLayout>
    <CompanyBanner />
    <Content />
  </DesktopLayout>
);

ViewComponent.displayName = 'CompanyInformation-Desktop-view';

export default ViewComponent;
