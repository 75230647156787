import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import { actions, BankAccount } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMyAccountCompanyInformationContext } from '../../context';
import { getBankAccountFormBody } from './helpers';

export interface BankDetailsFormValues {
  bank_account: BankAccount | undefined;
}

export interface BankDetailsFormHook {
  form: UseFormReturn<BankDetailsFormValues>;
  onSubmit: (formValues: StringIndexedObject<BankDetailsFormValues>) => void;
  isLoading: boolean;
  isSubmitting: boolean;
}

export default function useVatForm(): BankDetailsFormHook {
  const { company, isLoading, isSubmitting } =
    useMyAccountCompanyInformationContext();
  const dispatch = useAppDispatch();
  const onError = useApiError('Error updating VAT number at this time');
  const { showSuccess } = useToast();

  const defaultValues = useMemo(
    () => ({
      bank_account: company?.bank_account,
    }),
    [company],
  );

  const form = useForm<BankDetailsFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<BankDetailsFormValues>) => {
      const fv = formValues as unknown as BankDetailsFormValues;
      if (!company || !company.id) return;
      const { id } = company;
      const body = getBankAccountFormBody(company, fv);
      if (!body) return;
      dispatch(
        actions.putCompany({
          params: { id },
          body,
          onError,
          onSuccess: () => {
            showSuccess('Bank details updated');
          },
        }),
      );
    },
    [company, dispatch, onError, showSuccess],
  );

  const initRef = useRef<boolean>(false);
  useEffect(() => {
    if (initRef.current || !company || isLoading) return;
    initRef.current = true;
    form.reset(defaultValues);
  }, [company, isLoading, defaultValues, form]);

  return {
    form,
    onSubmit,
    isLoading,
    isSubmitting,
  };
}
