import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatAddress } from 'utils/Address-functions';
import { useMyAccountCompanyInformationContext } from '../../../context';

export interface CompanyDetailsFormLimitedHook {
  isLoading: boolean;
  showCard: boolean;
  name: string;
  number: string;
  address: string;
  onSearchCompanyClick: () => void;
}

export default function useCompanyDetailsFormLimited(
  isLoading: boolean,
): CompanyDetailsFormLimitedHook {
  const [showCard, setShowCard] = useState<boolean>(true);

  const { company } = useMyAccountCompanyInformationContext();

  const { setValue } = useFormContext();

  const name = useMemo(() => {
    if (isLoading) return 'No company name given';
    return company?.limited_company_info?.company_name || 'Not given';
  }, [isLoading, company]);

  const number = useMemo(() => {
    if (isLoading) return 'Not company number given';
    return company?.limited_company_info?.company_number || 'Not given';
  }, [isLoading, company]);

  const address = useMemo(() => {
    if (isLoading) return 'No company address given';
    return formatAddress(company?.limited_company_info?.company_address);
  }, [isLoading, company]);

  const onSearchCompanyClick = useCallback(() => {
    setValue('company.limited_company_info.company_number', undefined);
    setValue('company.limited_company_info.company_name', undefined);
    setValue('company.limited_company_info.company_address', undefined);
    setShowCard(false);
  }, [setValue]);

  return {
    isLoading,
    showCard,
    name,
    number,
    address,
    onSearchCompanyClick,
  };
}
