import type { DropzoneProps } from '@kanda-libs/ks-component-ts';
import { URLS } from 'config';
import { useCallback, useMemo, type FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import JobsNoResults from '../components/JobsNoResults';
import JobsPlaceholder from '../components/JobsPlaceholder';
import { useJobsContext } from '../context';
import JobsTable from './JobsTable';

export interface JobsDesktopHook {
  onDrop: DropzoneProps['onDrop'];
  Content: FunctionComponent;
}

export default function useJobsDesktop(): JobsDesktopHook {
  const { company, data, noJobs, isLoading } = useJobsContext();
  const { push } = useHistory();

  const customJobType = useMemo(
    () => company?.quote_preference === 'custom',
    [company],
  );

  const onDrop: DropzoneProps['onDrop'] = useCallback(
    (acceptedFiles?: File[]) => {
      if (!acceptedFiles || acceptedFiles.length === 0 || !customJobType)
        return;
      const newQuote = acceptedFiles[0];
      push(URLS.createJob, { newQuote });
    },
    [customJobType, push],
  );

  const Content = useMemo(() => {
    if (isLoading) return JobsTable;
    if (noJobs) return JobsPlaceholder;
    if (data.length === 0 && !noJobs) return JobsNoResults;
    return JobsTable;
  }, [isLoading, noJobs, data]);

  return { onDrop, Content };
}
