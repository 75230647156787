import {
  actions,
  useCurrentUser,
  type Company,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

interface CurrentCompanyHook {
  company: Company | null;
  id: string | undefined;
  directorsAreVerified: boolean;
  getCompanies: () => Promise<Company[] | undefined>;
  isValidating: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
}

/**
 * Hook to fetch the current company
 */
export default function useCurrentCompany(): CurrentCompanyHook {
  const dispatch = useAppDispatch();
  const { isValidating } = useCurrentUser();

  const isLoading = useSelector(selectors.getIsLoading);
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const companies = useSelector(selectors.company.getEntitiesAsArray);

  const getCompanies = useCallback(async () => {
    const action = await dispatch(
      actions.getCompanies({
        params: {},
        forceReload: true,
      }),
    );

    return action.payload as Company[];
  }, [dispatch]);

  const company = useMemo(() => {
    if (!companies || companies?.length === 0) return null;
    return companies[0];
  }, [companies]);

  const directorsAreVerified = useMemo(() => {
    if (!company || !company?.users || company?.users?.length === 0) {
      return false;
    }
    if (
      [
        'director_details_submitted',
        'insurance_uploaded',
        'onboarded',
      ].includes(company?.lifecycle || '')
    ) {
      return true;
    }
    const directors = company?.users?.filter((user) => user?.director_info);
    const verified = directors?.filter(
      (director) => director?.director_info?.verification_status === 'verified',
    );
    return verified?.length === directors?.length;
  }, [company]);

  return {
    company,
    id: company?.id,
    directorsAreVerified,
    getCompanies,
    isValidating,
    isLoading,
    isSubmitting,
  };
}
