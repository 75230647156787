import { ModalsWrapperContext } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useJobContext } from 'pages/Job/context';
import { useCallback, useContext, useMemo, type MouseEvent } from 'react';
import {
  DONT_SHOW_AGAIN_LOCAL_STORAGE_KEY,
  SEND_REMINDER_MODAL_ID,
} from './JobSendReminderModal/constants';

export interface JobSendReminderButtonHook {
  show: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
  isSubmitting: boolean;
}

export default function useJobSendReminderButton(): JobSendReminderButtonHook {
  const { job, checkJob, company, isLoading, isSubmitting, setIsSubmitting } =
    useJobContext();
  const dispatch = useAppDispatch();
  const { showModal } = useContext(ModalsWrapperContext);
  const onApiError = useApiError(
    'Unknown error sending reminder - please contact Kanda for support',
  );

  const showInfoModal = useMemo(
    () =>
      window.localStorage.getItem(DONT_SHOW_AGAIN_LOCAL_STORAGE_KEY) !== 'true',
    [],
  );

  const financeStatus = useMemo(() => {
    if (!job) return null;
    if (!checkJob?.has_finance_application) return null;
    return checkJob?.current_status;
  }, [job, checkJob]);

  const show = useMemo(() => {
    if (!job || !company || isLoading) return false;
    const { status, finance_status: jobFinanceStatus } = job;
    if (!['finished', 'accepted', 'sent'].includes(status || '')) return false;
    if (
      ![
        'not_applied',
        'applied_for_finance',
        'financed',
        'sat_note_sent',
        'sat_note_viewed',
      ].includes(jobFinanceStatus || '')
    )
      return false;
    if (['sat_note_sent', 'sat_note_viewed'].includes(jobFinanceStatus || ''))
      return true;
    if (['referred', 'accepted'].includes(financeStatus || '')) return false;
    return true;
  }, [job, isLoading, company, financeStatus]);

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!job?.id) return;
      setIsSubmitting(true);
      dispatch(
        actions.resendJob({
          params: { id: job.id },
          onError: (err) => {
            onApiError(err);
            setIsSubmitting(false);
          },
          onSuccess: () => {
            setIsSubmitting(false);
            if (showInfoModal) showModal(SEND_REMINDER_MODAL_ID);
          },
        }),
      );
    },
    [job, dispatch, showModal, showInfoModal, onApiError, setIsSubmitting],
  );

  return {
    show,
    onClick,
    isLoading,
    isSubmitting,
  };
}
