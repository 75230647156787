import { LABELS } from './constants';
import AddressFieldsContent from './Content';
import AddressFieldsProvider from './context';

export interface AddressFieldsLabels {
  postcode?: string;
  select?: string;
  buildingName?: string;
  buildingNumber?: string;
  line1?: string;
  line2?: string;
  city?: string;
  county?: string;
  country?: string;
}

export interface AddressFieldsProps {
  baseFieldName: string;
  showYears?: boolean;
  showLabels?: boolean;
  labels?: AddressFieldsLabels;
}

const AddressFields: React.FunctionComponent<AddressFieldsProps> = function ({
  baseFieldName,
  showYears = false,
  showLabels = true,
  labels = LABELS,
}) {
  return (
    <AddressFieldsProvider
      baseFieldName={baseFieldName}
      showYears={showYears}
      showLabels={showLabels}
      labels={{
        ...LABELS,
        ...labels,
      }}
    >
      <AddressFieldsContent />
    </AddressFieldsProvider>
  );
};

export default AddressFields;
