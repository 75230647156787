import { useIsDesktop } from '@kanda-libs/ks-design-library';
import { useJobContext } from 'pages/Job/context';
import { useMemo } from 'react';

export interface JobTitleHook {
  title: string;
  isLoading: boolean;
  isDesktop: boolean;
}

export default function useJobTitle(): JobTitleHook {
  const { job, isLoading } = useJobContext();
  const isDesktop = useIsDesktop();

  const title = useMemo(() => {
    if (isLoading) return '';
    return job?.title || 'No title added';
  }, [isLoading, job]);

  return {
    title,
    isLoading,
    isDesktop,
  };
}
