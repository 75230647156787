import { Icon, Text } from '@kanda-libs/ks-design-library';

export interface CustomerInfoCardCustomerPhoneProps {
  phone: string | undefined;
  isLoading: boolean;
}

const CustomerInfoCardCustomerPhone: React.FunctionComponent<CustomerInfoCardCustomerPhoneProps> =
  function ({ phone, isLoading }) {
    return (
      <div className="flex flex-row gap-x-3">
        <Icon
          icon="phone"
          isLoading={isLoading}
          stroke="green-600"
          size={16}
          className="min-w-4 my-auto"
        />
        <Text
          text={
            phone ? (
              <a
                href={`tel:${phone}`}
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                {phone}
              </a>
            ) : (
              <p>No phone given</p>
            )
          }
          isLoading={isLoading}
          className="text-13-14 text-neutral-700 my-auto"
          skeletonProps={{
            width: 50,
          }}
        />
      </div>
    );
  };

export default CustomerInfoCardCustomerPhone;
