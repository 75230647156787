import { useContext } from 'react';

import Context from '../../../Context';

const ContainerComponent = ({ children }) => {
  const { quoteDocument, onQuoteDocumentClick, isSubmitting } =
    useContext(Context);

  const name = quoteDocument?.name || 'Quote document';

  return children({
    name,
    onQuoteDocumentClick,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'LineItem-Mobile-Custom-container';

export default ContainerComponent;
