import { Pagination } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from './constants';
import JobCard from './JobCard';
import useJobsList from './useJobsList';

const JobsList: React.FunctionComponent = function () {
  const { data, showPagination, paginationProps, isLoading, archive } =
    useJobsList();

  return (
    <div className={CLASS_NAMES.container}>
      <div className={CLASS_NAMES.list}>
        {data.map((job) => (
          <JobCard
            key={job.id}
            job={job}
            isLoading={isLoading}
            archive={archive}
          />
        ))}
      </div>
      {!isLoading && showPagination && (
        <div className={CLASS_NAMES.pagination}>
          <Pagination {...paginationProps} />
        </div>
      )}
    </div>
  );
};

export default JobsList;
