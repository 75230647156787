import { SkeletonLoader, Text } from '@kanda-libs/ks-design-library';
import type { FinanceStatus, Job } from '@kanda-libs/ks-frontend-services';
import TableUpdatedAt from 'components/table/TableUpdatedAt';
import { type FunctionComponent } from 'react';
import useJobActivity from './useJobActivity';

export interface JobActivityProps {
  updatedAt: Date;
  status?: Job['status'];
  financeStatus?: FinanceStatus;
  textClassName?: string;
  isLoading?: boolean;
}

const JobActivity: FunctionComponent<JobActivityProps> = function ({
  updatedAt,
  status,
  financeStatus,
  textClassName,
  isLoading = false,
}) {
  const { suffix, className } = useJobActivity(
    status,
    financeStatus,
    textClassName,
  );

  return (
    <div className="flex flex-row whitespace-nowrap">
      <SkeletonLoader
        isLoading={isLoading}
        width={100}
        height={16}
        afterLoading={
          <>
            <Text className={className} text={suffix} />
            <TableUpdatedAt
              textClassName={textClassName}
              updatedAt={updatedAt}
            />
          </>
        }
      />
    </div>
  );
};

export default JobActivity;
