import type { FinanceStatus, Job } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import JobCombinedStatus from '../JobCombinedStatus';
import JobPrice from '../JobPrice';

export interface JobPriceStatusProps {
  price: number;
  status?: Job['status'];
  financeStatus?: FinanceStatus;
  archived?: boolean;
}

const JobPriceStatus: React.FunctionComponent<JobPriceStatusProps> = function ({
  price,
  status,
  financeStatus,
  archived = false,
}) {
  const showTags = useMemo(() => {
    if (!status && !financeStatus) return false;
    if (status === 'draft') return false;
    return true;
  }, [status, financeStatus]);

  return (
    <div className="flex flex-col gap-y-1">
      <JobPrice price={price} />
      {showTags && (
        <JobCombinedStatus
          status={status}
          financeStatus={financeStatus}
          archived={archived}
        />
      )}
    </div>
  );
};

export default JobPriceStatus;
