import { Loader } from '@kanda-libs/ks-design-library';
import IwocaBanner from '../IwocaBanner';
import CancelledBanner from './CancelledBanner';
import PremiumCreditBanner from './PremiumCreditBanner';
import SubscriptionCard, {
  type SubscriptionCardProps,
} from './SubscriptionCard';
import useSubscriptions from './useSubscriptions';

const Subsctiptions: React.FunctionComponent = function () {
  const {
    subscriptions,
    isSubmitting,
    isLoading,
    showCancelledBanner,
    showIwocaBanner,
    showPCBanner,
  } = useSubscriptions();
  return (
    <div className="flex flex-col gap-y-6 mt-8 md:mt-16">
      {showCancelledBanner && <CancelledBanner />}
      {showIwocaBanner && <IwocaBanner />}
      {showPCBanner && <PremiumCreditBanner />}
      <div className="flex flex-col md:flex-row gap-x-8 gap-y-4">
        {subscriptions.map((card: SubscriptionCardProps) => (
          <SubscriptionCard
            key={card.buttonProps.id}
            {...card}
            isLoading={isLoading}
          />
        ))}
        <Loader isLoading={isSubmitting} />
      </div>
    </div>
  );
};

export default Subsctiptions;
