import { Text } from '@kanda-libs/ks-design-library';
import useJobFinanceApplicationProgressCardDescription from './useJobFinanceApplicationProgressCardDescription';

const JobFinanceApplicationProgressCardDescription: React.FunctionComponent =
  function ({}) {
    const { heading, subHeading, isLoading } =
      useJobFinanceApplicationProgressCardDescription();
    return (
      <div className="flex flex-col">
        <Text
          text={heading}
          isLoading={isLoading}
          className="text-14-22-em text-neutral-700 mb-2"
        />
        <Text
          text={subHeading}
          isLoading={isLoading}
          className="text-12-18 text-neutral-700"
        />
      </div>
    );
  };

export default JobFinanceApplicationProgressCardDescription;
