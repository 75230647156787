import {
  useForm,
  type UseFormReturn,
  type ValidationItems,
} from '@kanda-libs/ks-component-ts';
import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import { actions, type ContactInfo } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import * as EmailValidator from 'email-validator';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMyAccountCompanyInformationContext } from '../../context';
import { getContactFormBody } from './helpers';

export interface ContactFormValues {
  contact_info: ContactInfo;
}

export interface ContactFormHook {
  form: UseFormReturn<ContactFormValues>;
  onSubmit: (formValues: StringIndexedObject<ContactFormValues>) => void;
  emailValidation: ValidationItems;
  isLoading: boolean;
  isSubmitting: boolean;
}

export default function useContactForm(): ContactFormHook {
  const { company, isLoading, isSubmitting } =
    useMyAccountCompanyInformationContext();
  const dispatch = useAppDispatch();
  const onError = useApiError('Error updating VAT number at this time');
  const { showSuccess } = useToast();

  const defaultValues = useMemo(
    () => ({
      contact_info: company?.contact_info,
    }),
    [company],
  );

  const emailValidation = useMemo(
    () => ({
      validate: {
        value: {
          valid: (value?: string | number | boolean) => {
            if (typeof value !== 'string') return false;
            return EmailValidator.validate(value);
          },
        },
        message: {
          valid: 'Email address is invalid',
        },
      },
    }),
    [],
  );

  const form = useForm<ContactFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<ContactFormValues>) => {
      const fv = formValues as unknown as ContactFormValues;
      if (!company || !company.id) return;
      const { id } = company;
      const body = getContactFormBody(company, fv);
      if (!body) return;
      dispatch(
        actions.putCompany({
          params: { id },
          body,
          onError,
          onSuccess: () => {
            showSuccess('Contact details updated');
          },
        }),
      );
    },
    [company, dispatch, onError, showSuccess],
  );

  const initRef = useRef<boolean>(false);
  useEffect(() => {
    if (initRef.current || !company || isLoading) return;
    initRef.current = true;
    form.reset(defaultValues);
  }, [company, isLoading, defaultValues, form]);

  return {
    form,
    onSubmit,
    emailValidation,
    isLoading,
    isSubmitting,
  };
}
