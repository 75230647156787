import { ModalContainer, ModalLayoutCenter } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';
import type { MutableRefObject } from 'react';
import { MARK_FINISHED_MODAL_ID } from './constants';
import useJobMarkAsFinishedModal from './useJobMarkAsFinishedModal';

const JobMarkAsFinishedModal = () => {
  const { onFinish, isSubmitting } = useJobMarkAsFinishedModal();

  return (
    <ModalContainer id={MARK_FINISHED_MODAL_ID} opacity={40}>
      {({ id, handleClose, ref }) => (
        <>
          <ModalLayoutCenter
            id={id}
            onClose={handleClose}
            ref={ref as MutableRefObject<HTMLDivElement>}
            className="w-10/12 md:w-100"
          >
            <div className="flex flex-col">
              Are you sure you want to mark this job as finished?
              <div className="flex flex-row justify-between pt-4">
                <Button.Text
                  id="mark-as-finished-modal-cancel"
                  label="Cancel"
                  onClick={handleClose}
                  disabled={isSubmitting}
                  variant="outline"
                />
                <Button.Text
                  id="mark-as-finished-modal-confirm"
                  label="Confirm"
                  onClick={onFinish}
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </ModalLayoutCenter>
          <Loader isLoading={isSubmitting} />
        </>
      )}
    </ModalContainer>
  );
};

export default JobMarkAsFinishedModal;
