import { useFinanceOptionsContext } from 'components/FinanceOptions/context';
import { useMemo } from 'react';

export interface NoRatesHook {
  noWorkType: boolean;
}

export default function useNoRates(): NoRatesHook {
  const { workType } = useFinanceOptionsContext();

  const noWorkType = useMemo(() => {
    if (!workType) return true;
    return false;
  }, [workType]);

  return { noWorkType };
}
