import { Button, Loader } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import JobStatusBar from 'pages/Job/components/JobStatusBar';
import JobTitle from 'pages/Job/components/JobTitle';
import { useJobContext } from 'pages/Job/context';
import { Link } from 'react-router-dom';
import JobActionButtons from './ActionButtons';

const DesktopJobHeader: React.FunctionComponent = function () {
  const { isSubmitting } = useJobContext();
  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between gap-x-3">
        <div className="flex flex-row">
          <div className="flex flex-col mr-4">
            <Link to={URLS.jobs}>
              <Button.Icon id="jobs-back" icon="arrow-left" size="48-20" />
            </Link>
          </div>
          <JobTitle />
        </div>
        <JobActionButtons />
      </div>
      <div className="flex flex-row gap-x-2 ml-16 mt-2">
        <JobStatusBar />
      </div>
      <Loader isLoading={isSubmitting} />
    </div>
  );
};

export default DesktopJobHeader;
