import HigherDepositChosen from './HigherDepositChosen';
import useJobFinanceDepositChosen from './useJobFinanceDepositChosen';

export interface JobFinanceDepositChosenProps {
  onAcknowledgeClick: () => void;
  acknowledged: boolean;
}

const JobFinanceDepositChosen: React.FunctionComponent<JobFinanceDepositChosenProps> =
  function ({ onAcknowledgeClick, acknowledged }) {
    const { setDeposit, chosenDeposit, difference, higher } =
      useJobFinanceDepositChosen();
    if (!higher) return null;
    return (
      <HigherDepositChosen
        setDeposit={setDeposit}
        chosenDeposit={chosenDeposit}
        difference={difference}
        acknowledged={acknowledged}
        onAcknowledgeClick={onAcknowledgeClick}
      />
    );
  };

export default JobFinanceDepositChosen;
