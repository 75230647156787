import { actions, Cache } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { PC_ENABLE_CACHE_KEY } from './constants';

export default function useSetupPremiumCreditToggle() {
  const caches = useSelector(selectors.infoGetCache.getEntities);
  const hasFetched = useSelector(selectors.infoGetCache.getHasFetched);
  const error = useSelector(selectors.infoGetCache.getError);
  const me = useSelector(selectors.getAuth);
  const dispatch = useAppDispatch();

  const { push, location } = useHistory();
  const onApiError = useApiError(
    'Unknown error updating cache - please contact Kanda',
  );

  const cache = useMemo(() => {
    const id = me.user?.id;
    if (!id) return undefined;
    return caches?.[id] || ({} as Cache);
  }, [me, caches]);

  const isLoading = useMemo(() => {
    if (error) return false;
    return !hasFetched;
  }, [hasFetched, error]);

  const onEnable = useMemo(
    () => Boolean(matchPath(location.pathname, URLS.setupPremiumCreditEnable)),
    [location],
  );

  const handleEnableFlow = useCallback(() => {
    dispatch(
      actions.infoPutCache({
        body: {
          ...cache,
          [PC_ENABLE_CACHE_KEY]: 'true',
        } as unknown as Cache,
        onError: (err) => {
          onApiError(err);
          push(URLS.setupSubscription);
        },
        onSuccess: () => {
          push(URLS.setupSubscription);
        },
      }),
    );
  }, [cache, dispatch, onApiError, push]);

  const handleDisableFlow = useCallback(() => {
    dispatch(
      actions.infoPutCache({
        body: {
          ...cache,
          [PC_ENABLE_CACHE_KEY]: 'false',
        } as unknown as Cache,
        onError: (err) => {
          onApiError(err);
          push(URLS.setupSubscription);
        },
        onSuccess: () => {
          push(URLS.setupSubscription);
        },
      }),
    );
  }, [cache, dispatch, onApiError, push]);

  const initRef = useRef<boolean>(false);
  useEffect(() => {
    if (isLoading || !cache || initRef.current) return;
    initRef.current = true;
    if (onEnable) {
      handleEnableFlow();
      return;
    }
    handleDisableFlow();
  }, [isLoading, cache, onEnable, handleEnableFlow, handleDisableFlow]);

  // Checks error code - if 404 (no cache) this simply returns and lets
  // other logic work. Else, redirect to home if there's an actual issue
  useEffect(() => {
    if (!error) return;
    if (error.code === 404) return;
    push(URLS.home);
  }, [error, push]);
}
