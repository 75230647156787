import Content from './Content';
import MyAccountCompanyInformationProvider from './context';

const MyAccountCompanyInformationContent: React.FunctionComponent =
  function ({}) {
    return (
      <MyAccountCompanyInformationProvider>
        <Content />
      </MyAccountCompanyInformationProvider>
    );
  };

export default MyAccountCompanyInformationContent;
