import { Form } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import { Layout } from '../components';
import { useSubscriptionContext } from './context';
import Pages from './Pages';
import usePayMonthlyForm from './usePayMonthlyForm';

const PayMonthlyForm: React.FunctionComponent = function () {
  const { display, isLoading } = useSubscriptionContext();
  const { form, onSubmit, disabled, isSubmitting } = usePayMonthlyForm();

  return (
    <Form id="pay-monthly" form={form} onSubmit={onSubmit}>
      <Layout
        mobileFooter={
          display === 'form' ? (
            <Button.Text
              id="pay-monthly-submit"
              submit
              className="w-full"
              label="Next Step"
              icon="arrow-right"
              variant="gradient"
              disabled={disabled || isLoading}
            />
          ) : null
        }
      >
        <Helmet title="Subscription" />
        <div className="flex flex-col w-full max-w-120 md:max-w-full mx-auto">
          <>
            {display === 'subscriptions' ? <Pages.Subscriptions /> : null}
            {display === 'form' ? (
              <Pages.PayMonthly disabled={disabled} />
            ) : null}
          </>
        </div>
        <Loader isLoading={isSubmitting} />
      </Layout>
    </Form>
  );
};

export default PayMonthlyForm;
